<template>
    <div class="module_wrap box_border" v-loading="loading" element-loading-text="数据加载中" element-loading-spinner="el-icon-loading" element-loading-background="transparent">
        <div class="box_title">案件风险等级近七日变换趋势</div>
        <div style="height: 55%" v-show="!loading">
            <div id="risk_grade_bar"></div>
        </div>
    </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
    name: 'RiskGrade',
    props: {
        isCollapse: {
            type: Boolean,
            default: true
        },
        navForm: {
            type: Object,
            default: {}
        }
    },
    watch: {
        isCollapse() {
            setTimeout(() => {
                this.myChart && this.myChart.resize();
            }, 600);
        },
        navForm: {
            // immediate: true,
            deep: true,
            handler(val) {
                this.province = val.accidentProvince;
                this.getData();
            }
        }
    },
    data() {
        return {
            topDays: [],
            newNumber: 0,
            myChart: '',
            loading: true
        };
    },
    mounted() {
        this.role = localStorage.getItem('role');
        this.getData();
        window.addEventListener('resize', () => {
            if (this.myChart) {
                setTimeout(() => {
                    this.myChart.resize();
                }, 500);
            }
        });
    },
    methods: {
        getData() {
            this.loading = true;
            this.instance({
                method: 'post',
                url: '/large/data/screen/caseLevelDayNumVo',
                data: this.navForm,
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8'
                }
            }).then((res) => {
                this.loading = false;
                if (res.data.code == 1) {
                    let response = res.data.data;
                    if (response.length > 0) {
                        this.loadCharts(response);
                    }
                }
            });
        },
        //
        loadCharts(response) {
            var chartDom = document.getElementById('risk_grade_bar');
            if (!chartDom) {
                return;
            }
            this.myChart = echarts.init(chartDom, null, { renderer: 'svg' });
            let xAxisData = [],
                seriesData1 = [],
                seriesData2 = [];
            response.forEach((val, key) => {
                xAxisData.push(val.dayTime);
                seriesData1.push(val.caseNum);
                seriesData2.push(val.caseReduceNum);
            });
            var option = {
                color: ['#6440d4', 'rgba(26, 197, 145, 0.3)'],
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999'
                        }
                    },
                    textStyle: {
                        align: 'left'
                    }
                },
                grid: {
                    top: '20',
                    left: '10',
                    right: '10',
                    bottom: '5',
                    containLabel: true
                },
                legend: {
                    data: ['风险案件数', '风险案件总数'],
                    itemWidth: 12,
                    itemHeight: 12,
                    itemGap: 20,
                    textStyle: {
                        color: '#fff'
                        // fontSize:'0.12rem'
                    },
                    left: 'center'
                },
                xAxis: [
                    {
                        type: 'category',
                        data: xAxisData,
                        axisPointer: {
                            type: 'shadow'
                        },
                        axisLabel: {
                            showMaxLabel: true,
                            textStyle: {
                                //   fontSize:'0.12rem',
                                color: '#fff' //坐标值得具体的颜色
                            }
                        },
                        axisTick: {
                            alignWithLabel: true
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        axisLabel: {
                            showMaxLabel: true,
                            formatter: '{value} 件',
                            textStyle: {
                                //   fontSize:'0.12rem',
                                color: '#fff' //坐标值得具体的颜色
                            }
                        },
                        splitLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        }
                    },
                    {
                        type: 'value',
                        axisLabel: {
                            showMaxLabel: true,
                            formatter: '{value} 件',
                            textStyle: {
                                //   fontSize:'0.12rem',
                                color: '#fff' //坐标值得具体的颜色
                            }
                        },

                        splitLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        }
                    }
                ],
                series: [
                    {
                        name: '风险案件总数',
                        type: 'bar',
                        tooltip: {
                            valueFormatter: function (value) {
                                return value + ' 件';
                            }
                        },
                        data: seriesData1
                    },
                    {
                        name: '风险案件数',
                        type: 'line',
                        yAxisIndex: 1,
                        tooltip: {
                            valueFormatter: function (value) {
                                return value + ' 件';
                            }
                        },
                        smooth: true,
                        showSymbol: false,
                        areaStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                {
                                    offset: 0,
                                    color: '#1faf8c'
                                },
                                {
                                    offset: 1,
                                    color: '#165d59'
                                }
                            ])
                        },
                        data: seriesData2
                    }
                ]
            };
            this.myChart.setOption(option);
        }
    },
    updated() {
        this.myChart && this.myChart.resize();
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.module_wrap {
    width: 100%;
    height: 3.3rem;
    /* height: 33%; */
    padding: 0.15rem 0.3rem 0;
    margin-top: 2.5%;
}
#risk_grade_bar {
    width: 100%;
    height: 2.6rem;
    margin-top: 0.1rem;
}
</style>
