<template>
    <div class="module_wrap box_border" v-loading="loading" element-loading-text="数据加载中" element-loading-spinner="el-icon-loading" element-loading-background="transparent">
        <div class="box_title">
            <span>估损偏差率统计</span>
        </div>
        <div style="height: 100%" v-show="!loading">
            <div id="depletion_sum_pie"></div>
        </div>
    </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
    name: 'DepletionSum',
    props: {
        isCollapse: {
            type: Boolean,
            default: true
        },
        navForm: {
            type: Object,
            default: {}
        }
    },
    watch: {
        isCollapse() {
            setTimeout(() => {
                this.myChart && this.myChart.resize();
            }, 600);
        },
        navForm: {
            // immediate: true,
            deep: true,
            handler(val) {
                this.getData();
            }
        }
    },
    data() {
        return {
            topDays: [],
            newNumber: 0,
            option: '',
            myChart: '',
            loading: true
        };
    },
    mounted() {
        this.role = localStorage.getItem('role');
        window.addEventListener('resize', () => {
            if (this.myChart) {
                setTimeout(() => {
                    this.myChart.resize();
                }, 500);
            }
        });
    },
    methods: {
        getData() {
            this.loading = true;
            this.instance({
                method: 'post',
                url: '/large/data/screen/getMoneyLossEstimation',
                data: this.navForm,
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8'
                }
            }).then((res) => {
                this.loading = false;
                if (res.data.code == 1) {
                    let response = res.data.data;
                    this.loadCharts(response);
                }
            });
        },
        loadCharts(response) {
            var baseURL = this.$store.getters.baseURL;
            baseURL = baseURL.replace(/manager/, '');
            var chartDom = document.getElementById('depletion_sum_pie');
            if (!chartDom) {
                return;
            }
            this.myChart = echarts.init(chartDom, null, { renderer: 'svg' });
            let xAxisData = [],
                seriesData = [];
            response.forEach((val, key) => {
                xAxisData.push(val.name);
                seriesData.push(val.caseNumRatio);
            });
            this.option = {
                tooltip: {
                    trigger: 'axis',
                    formatter: '{b}: {c}万元'
                },
                color: '#cd6e72',
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    axisLabel: {
                        showMaxLabel: true,
                        textStyle: {
                            // fontSize: '0.12rem',
                            color: '#fff' //坐标值得具体的颜色
                        }
                        // rotate: 90,
                    },
                    splitLine: {
                        show: false
                    },
                    axisTick: {
                        // show: false,
                        // interval:10
                    },
                    axisLine: {
                        // show:false,
                        onZero: false
                    },
                    offset: 5,

                    data: xAxisData
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        showMaxLabel: true,
                        formatter: '{value} 万',
                        textStyle: {
                            // fontSize: '0.12rem',
                            color: '#fff' //坐标值得具体的颜色
                        }
                    },
                    splitLine: {
                        show: false
                    },
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    }
                    //min: seriesData[seriesData.length - 1] * 200
                },
                grid: {
                    left: '2%',
                    right: '4%',
                    top: '5%',
                    bottom: '10%',
                    containLabel: true
                },
                series: [
                    {
                        data: seriesData,
                        type: 'line',
                        smooth: true,
                        symbol: 'image://' + baseURL + 'images/money_icon.png', //money_icon
                        symbolSize: 20
                        // showSymbol:false,
                        // areaStyle: {}
                    }
                ]
            };
            this.myChart.setOption(this.option);
        }
    },
    updated() {
        this.myChart && this.myChart.resize();
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.module_wrap {
    width: 100%;
    height: 3.4rem;
    padding: 0.15rem 0.3rem 0;
    margin-top: 0.2rem;
    overflow: hidden;
}
#depletion_sum_pie {
    width: 100%;
    height: 2.9rem;
}
</style>
