<template>
    <div class="wrap">
        <div class="page_title">
            <b>{{ pageTitle }}</b>
        </div>
        <div class="search_box public_box">
            <el-form ref="searchForm" :inline="true" :model="searchForm">
                <el-form-item label="保单号：" prop="policyNo">
                    <el-input class="width_200" v-model="searchForm.policyNo" maxlength="20" placeholder="请输入保单号" clearable></el-input>
                </el-form-item>
                <el-form-item class="resetMarginBottom" label="流入时间：">
                    <el-row style="width: 350px">
                        <el-col :span="11">
                            <el-form-item prop="startTime">
                                <el-date-picker placeholder="请选择日期" clearable v-model="searchForm.startTime" type="date" value-format="yyyy-MM-dd" style="width: 100%"> </el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col class="line" style="text-align: center" :span="2">至</el-col>
                        <el-col :span="11">
                            <el-form-item prop="endTime">
                                <el-date-picker placeholder="请选择日期" clearable v-model="searchForm.endTime" type="date" value-format="yyyy-MM-dd" style="width: 100%"> </el-date-picker>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="分公司：" prop="searchCompanyId">
                    <el-select class="width_200" v-model="searchForm.searchCompanyId" multiple filterable placeholder="请选择分公司" clearable>
                        <el-option v-for="item in companyOptions" :key="item.id" :label="item.desc" :value="item.id"> </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="当前处理人：" prop="handlers">
                    <el-select class="width_250" v-model="searchForm.handlers" multiple filterable placeholder="请选择处理人" clearable>
                        <el-option v-for="item in employeeListOptions" :key="item.jobNo" :label="item.name" :value="item.jobNo"> </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="被保险人身份证号：" prop="deliveryId">
                    <el-input class="width_200" v-model="searchForm.deliveryId" maxlength="20" placeholder="请输入身份证号" clearable></el-input>
                </el-form-item>
                <el-form-item label="姓名：" prop="deliveryName">
                    <el-input class="width_200" v-model="searchForm.deliveryName" maxlength="20" placeholder="请输入姓名" clearable></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="searchTable" style="margin-left: 0.2rem">查询</el-button>
                    <el-button type="warning" @click="resetForm" style="margin-left: 0.2rem">重置</el-button>
                    <el-button type="primary" @click="download" style="margin-left: 0.2rem">下载</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="table_box public_box">
            <el-table ref="filterTable" :data="tableData" :height="tableHeight" v-loading="tableLoading">
                <el-table-column prop="policyNo" label="保单号" width="200"></el-table-column>
                <el-table-column prop="nameOfInsured" label="被保人" width="200">
                    <template slot-scope="scope">
                        <overflow-txt :txt="scope.row.nameOfInsured"></overflow-txt>
                    </template>
                </el-table-column>
                <el-table-column prop="accidentProvince" label="分公司"></el-table-column>
                <el-table-column prop="accidentCity" label="机构（城市）" width="120"></el-table-column>
                <el-table-column prop="insureTypeName" label="险种" width="200">
                    <template slot-scope="scope">
                        <overflow-txt :txt="scope.row.insureTypeName"></overflow-txt>
                    </template>
                </el-table-column>
                <el-table-column prop="productType" label="产品名称" width="200">
                    <template slot-scope="scope">
                        <overflow-txt :txt="scope.row.productType"></overflow-txt>
                    </template>
                </el-table-column>
                <el-table-column prop="reportTime" label="报案时间" width="140"></el-table-column>
                <el-table-column prop="accidentTime" label="出险时间" width="140"></el-table-column>
                <el-table-column prop="reportTime" label="流入时间" width="140"></el-table-column>
                <el-table-column prop="durationOfClosedClaims" label="案件总用时" width="140"></el-table-column>
                <el-table-column prop="humanInjury" label="是否人伤"></el-table-column>
                <el-table-column prop="accidentProperty" label="损失类型"></el-table-column>
                <el-table-column prop="currentStatusDesc" label="案件状态"></el-table-column>
                <el-table-column prop="predictPayAmount" label="估损金额"></el-table-column>
                <el-table-column prop="currentProcessor" label="当前处理人" width="140"></el-table-column>
            </el-table>
            <!--分页部分-->
            <el-pagination @current-change="handleCurrentChange" @size-change="handleSizeChange" :current-page="searchForm.page" layout="total, sizes, prev, pager, next, jumper" :page-sizes="[10, 20]" :page-size="searchForm.size" :total="searchForm.total"> </el-pagination>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            tableHeight: null,
            pageTitle: '',
            searchForm: {
                policyNo: '',
                startTime: '',
                endTime: '',
                searchCompanyId: [],
                handlers: [],
                status: [],
                deliveryId: '',
                deliveryName: '',
                page: 1,
                size: 10,
                total: 0
            },
            detailPageForm: {
                labelId: null,
                page: 1,
                size: 10,
                total: 0
            },
            tagDataForm: {
                id: '',
                delDetails: [],
                labelName: '',
                typeStr: '',
                labelDetails: [
                    {
                        id: null,
                        labelId: null,
                        filed: '',
                        labelItems: [{ value: '' }]
                    }
                ]
            },
            tagDataRules: {
                labelName: [{ required: true, message: '请输入标签名称', trigger: 'blur' }],
                type: [{ required: true, message: '请选择标签类型', trigger: 'blur' }]
            },
            tagTypeOption: [],
            sourceOption: [],
            itemOption: [],
            tableData: [],
            value: '',
            input: '',
            tableData: [],
            tmpTagDataForm: null,
            tableLoading: false,
            companyOptions: [],
            employeeListOptions: []
        };
    },
    mounted() {
        // 动态计算table高度
        window.onresize = () => {
            this.calcHeight();
        };
        this.calcHeight();
        this.pageTitle = this.$route.meta.title;
        this.getTypeDic();
        this.getSourceDic();
        this.getTableData();
        this.getItemDic();
        this.getCompany();
        this.getEmployeeList();
    },
    methods: {
        calcHeight() {
            // 顶部tabs
            let registerTabsHeight = document.querySelector('.register_tabs') ? document.querySelector('.register_tabs').clientHeight : 0;
            // 搜索筛选项
            let publicBoxHeight = document.querySelector('.public_box') ? document.querySelector('.public_box').clientHeight : 0;
            // 分页
            let elPaginationHeight = document.querySelector('.el-pagination') ? document.querySelector('.el-pagination').clientHeight : 0;
            let tableHeaderHeight = 0;
            let guHeight = 175;

            this.tableHeight = window.innerHeight - registerTabsHeight - publicBoxHeight - elPaginationHeight - tableHeaderHeight - guHeight;

            this.tableHeight = this.tableHeight < 200 ? 200 : this.tableHeight;
            this.$forceUpdate();
        },
        getCompany() {
            this.instance.post('/caseSearch/getCompany', {}).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.companyOptions = data.data;
                }
            });
        },
        getEmployeeList() {
            this.instance.post('/caseSearch/employeeList', {}).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.employeeListOptions = data.data;
                }
            });
        },
        getItemDic() {
            this.instance.get('/caseRiskLabel/itemDic', { params: {} }).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.itemOption = data.data;
                }
            });
        },
        getTypeDic() {
            this.instance.get('/caseRiskLabel/typeDic', { params: {} }).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.tagTypeOption = data.data;
                }
            });
        },
        getSourceDic() {
            this.instance.get('/caseRiskLabel/sourceDic', { params: {} }).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.sourceOption = data.data;
                }
            });
        },
        searchTable() {
            this.searchForm.page = 1;
            this.getTableData();
        },
        resetForm() {
            this.$refs.searchForm && this.$refs.searchForm.resetFields();
        },
        handleCurrentChange(val, switchStr) {
            this.searchForm.page = val;
            this.getTableData();
        },
        handleSizeChange(val, switchStr) {
            this.searchForm.size = val;
            this.searchForm.page = 1;
            this.getTableData();
        },
        download() {
            let tmpForm = JSON.parse(JSON.stringify(this.searchForm));
            // tmpForm.startTime = tmpForm.startTime ? tmpForm.startTime + ' 00:00:00' : '';
            // tmpForm.endTime = tmpForm.endTime ? tmpForm.endTime + ' 23:59:59' : '';
            this.instance({
                method: 'post',
                url: `/caseSearch/download`,
                data: tmpForm,
                responseType: 'blob'
            }).then((res) => {
                let url = window.URL.createObjectURL(new Blob([res.data]));
                let link = document.createElement('a');
                link.style.display = 'none';
                link.href = url;
                link.setAttribute('download', decodeURIComponent(res.headers.filename)); //指定下载后的文件名，防跳转
                document.body.appendChild(link);
                link.click();
            });
        },
        getTableData() {
            let tmpForm = JSON.parse(JSON.stringify(this.searchForm));
            // tmpForm.startTime = tmpForm.startTime ? tmpForm.startTime + ' 00:00:00' : '';
            // tmpForm.endTime = tmpForm.endTime ? tmpForm.endTime + ' 23:59:59' : '';
            this.tableLoading = true;
            this.instance.post('/caseSearch/search', tmpForm).then((res) => {
                this.tableLoading = false;
                let data = res.data;
                if (data.code == 1) {
                    this.tableData = data.data.list;
                    this.searchForm.total = data.data.total;
                } else {
                    this.$message.error(res.message);
                }
            });
        }
    }
};
</script>
<style lang="less" scoped>
.search_box {
    padding-bottom: 0;
    .stage_item {
        width: 300px;
        // border: 1px solid red;
        .stage_row {
            width: 200px;
            .el-col {
                /deep/.el-form-item {
                    margin-bottom: 0;
                }
                /deep/.el-input__inner {
                    padding: 0 9px;
                }
            }
        }
    }
    .stage_item:nth-child(5),
    .stage_item:nth-child(6) {
        width: 355px;
    }
    /deep/ .el-input__inner {
        line-height: normal;
    }
}
.tag_dialog {
    .width_310 {
        width: 310px;
    }
    .tag_content {
        min-height: 100px;
        margin: 10px 0;
        width: 100%;
        margin-top: -10px;
        // border: 1px solid red;
        .tag_parent {
            width: 100%;
            overflow-x: scroll;
            .tag_table,
            .tag_header {
                padding: 40px 10px 10px 40px;
                max-height: 322px;
                // overflow: scroll;
                // overflow-y: scroll;
                white-space: nowrap;
                .header_item {
                    display: inline-block;
                    min-width: 100px;
                    border: 1px solid #aaa;
                    border-right: 0;
                    border-bottom: 0;
                    position: relative;
                    /deep/ .el-input__inner {
                        border: none;
                        border-radius: 0;
                        border-bottom: 1px solid #aaa;
                    }
                    .header_txt {
                        position: relative;
                        border-bottom: 1px solid #aaa;
                        .decoration {
                            position: absolute;
                            top: -38px;
                            right: -8px;
                            color: #333;
                            z-index: 2;
                        }
                        .decoration::before {
                            content: '';
                            display: inline-block;
                            width: 40px;
                            height: 20px;
                            border: 1px solid #aaa;
                            border-bottom: 0;
                            position: absolute;
                            left: -13px;
                            top: 16px;
                            z-index: -1;
                        }
                    }
                    .header_btn {
                        left: 20px;
                        top: -37px;
                    }
                    .body_btn {
                        left: -38px;
                        top: 2px;
                    }
                    .header_txt {
                        height: 30px;
                        line-height: 30px;
                        border-bottom: 1px solid #aaa;
                    }
                    .tag_body {
                        .body_item {
                            height: 30px;
                            line-height: 30px;
                            position: relative;
                            text-align: center;
                            .remove_btn {
                                color: #f56c6c;
                                left: -38px;
                                top: 2px;
                            }
                        }
                        .body_item:last-child {
                            border-bottom: 0;
                        }
                    }
                }
                .header_item:last-child {
                    border-right: 1px solid #aaa;
                    .decoration {
                        display: none;
                    }
                }
            }
            .tag_table {
                padding-top: 0;
                margin-top: -1px;
            }
            .tag_header {
                padding-bottom: 0;
            }
        }
    }
    .btn_icon {
        font-size: 24px;
        color: #409eff;
        cursor: pointer;
        position: absolute;
    }
}
</style>