<template>
    <div class="module_wrap box_border" v-loading="loading" element-loading-text="数据加载中" element-loading-spinner="el-icon-loading" element-loading-background="transparent">
        <div class="box_title">
            <b>案件风险占比分布</b>
            <span>（D级为最高风险）</span>
        </div>
        <div style="height: 55%" v-show="!loading">
            <div id="risk_point_pie"></div>
        </div>
    </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
    name: 'RiskPoint',
    props: {
        isCollapse: {
            type: Boolean,
            default: true
        },
        navForm: {
            type: Object,
            default: {}
        }
    },
    watch: {
        isCollapse() {
            setTimeout(() => {
                this.myChart && this.myChart.resize();
            }, 600);
        },
        navForm: {
            // immediate: true,
            deep: true,
            handler(val) {
                this.province = val.accidentProvince;
                this.getData();
            }
        }
    },
    data() {
        return {
            topDays: [],
            newNumber: 0,
            myChart: '',
            loading: true
        };
    },
    mounted() {
        this.role = localStorage.getItem('role');
        this.getData();
        window.addEventListener('resize', () => {
            if (this.myChart) {
                setTimeout(() => {
                    this.myChart.resize();
                }, 500);
            }
        });
    },
    methods: {
        getData() {
            this.loading = true;
            this.instance({
                method: 'post',
                url: '/large/data/screen/caseLevelNum',
                data: this.navForm,
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8'
                }
            }).then((res) => {
                this.loading = false;
                if (res.data.code == 1) {
                    let response = res.data.data;
                    if (response.length > 0) {
                        this.loadCharts(response);
                    }
                }
            });
        },
        loadCharts(response) {
            var chartDom = document.getElementById('risk_point_pie');
            if (!chartDom) {
                return;
            }
            this.myChart = echarts.init(chartDom, null, { renderer: 'svg' });
            let seriesData = [];
            response.forEach((val, key) => {
                let obj = {
                    value: val.caseLevelNum,
                    name: val.caseLevel
                };
                seriesData.push(obj);
            });
            var option = {
                color: ['#4f81be', '#8064a1', '#9bbb58', '#c0504e'],
                tooltip: {
                    trigger: 'item',
                    formatter: '{b}: {c}件 ({d}%)',
                    confine: true
                },
                legend: {
                    bottom: '5%',
                    itemWidth: 12,
                    itemHeight: 12,
                    textStyle: {
                        color: '#fff'
                        // fontSize:'0.12rem'
                    },
                    left: 'center'
                },
                series: [
                    {
                        type: 'pie',
                        radius: ['35%', '60%'],
                        center: ['50%', '40%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center'
                        },
                        labelLine: {
                            show: false
                        },
                        data: seriesData
                    }
                ]
            };
            this.myChart.setOption(option, true);
        }
    },
    updated() {
        this.myChart && this.myChart.resize();
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.module_wrap {
    width: 100%;
    height: 3.5rem;
    /* height: 33%; */
    padding: 0.15rem 0.3rem 0;
    margin-top: 2.5%;
    overflow: hidden;
    margin-bottom: 50px;
}
#risk_point_pie {
    width: 100%;
    height: 2.9rem;
}
.box_title span {
    /* font-size: 0.12rem; */
    font-size: 12px;
    color: #ccc;
}
</style>
