<template>
    <div class="module_wrap box_border" v-loading="loading" element-loading-text="数据加载中" element-loading-spinner="el-icon-loading" element-loading-background="transparent">
        <div class="box_title">7天报案结案量统计</div>
        <div style="height: 100%" v-show="!loading">
            <div id="processing_time_bar1"></div>
        </div>
    </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
    name: 'ProcessingTime',
    props: {
        isCollapse: {
            type: Boolean,
            default: true
        },
        navForm: {
            type: Object,
            default: {}
        }
    },
    watch: {
        isCollapse() {
            setTimeout(() => {
                this.myChart && this.myChart.resize();
            }, 1000);
        },
        navForm: {
            deep: true,
            handler(val) {
                this.getData();
            }
        }
    },
    data() {
        return {
            topDays: [],
            newNumber: 0,
            myChart: '',
            loading: true
        };
    },
    mounted() {
        this.role = localStorage.getItem('role');
        window.addEventListener('resize', () => {
            if (this.myChart) {
                setTimeout(() => {
                    this.myChart.resize();
                }, 500);
            }
        });
    },
    methods: {
        getData() {
            this.loading = true;
            this.instance({
                method: 'post',
                url: '/large/data/screen/getLast7DayCaseNum',
                data: this.navForm,
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8'
                }
            }).then((res) => {
                this.loading = false;
                if (res.data.code == 1) {
                    let response = res.data.data;
                    if (response.length > 0) {
                        this.loadCharts(response);
                    }
                }
            });
        },
        loadCharts(response) {
            var chartDom = document.getElementById('processing_time_bar1');
            if (!chartDom) {
                return;
            }
            this.myChart = echarts.init(chartDom, null, { renderer: 'svg' });
            let xAxisData = [],
                seriesData = [],
                seriesData1 = [];
            response.forEach((val, key) => {
                xAxisData.push(val.name);
                seriesData.push(val.caseNum);
                seriesData1.push(val.totalCaseNum);
            });
            var option = {
                color: ['#92D050', '#09d5d6'],
                tooltip: {
                    trigger: 'axis',
                    confine: true,
                    textStyle: {
                        align: 'left'
                    }
                },
                grid: {
                    left: '20px',
                    right: '5px',
                    top: '30px',
                    bottom: '0%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        data: xAxisData,
                        axisPointer: {
                            type: 'shadow'
                        },
                        axisLabel: {
                            showMaxLabel: true,
                            rotate: 30,
                            textStyle: {
                                color: '#fff' //坐标值得具体的颜色
                            }
                        },
                        axisTick: {
                            alignWithLabel: true
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        axisLabel: {
                            showMaxLabel: true,
                            formatter: '{value}',
                            textStyle: {
                                // fontSize: '0.12rem',
                                color: '#fff' //坐标值得具体的颜色
                            }
                        },
                        splitLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        }
                    }
                ],
                legend: {
                    textStyle: {
                        color: '#fff'
                    }
                },
                color: ['#409EFF', '#67C23A'],
                series: [
                    {
                        name: '报案量',
                        type: 'bar',
                        tooltip: {
                            valueFormatter: function (value) {
                                return value + '件';
                            }
                        },
                        data: seriesData
                    },
                    {
                        name: '结案量',
                        type: 'bar',
                        tooltip: {
                            valueFormatter: function (value) {
                                return value + '件';
                            }
                        },
                        data: seriesData1
                    }
                ]
            };
            this.myChart.setOption(option);
        }
    },
    updated() {
        this.myChart && this.myChart.resize();
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.module_wrap {
    width: 100%;
    /* height: 3.4rem; */
    height: 3.4rem;
    padding: 0.15rem 0.2rem 0;
    margin-top: 0.2rem;
    overflow: hidden;
}
#processing_time_bar1 {
    width: 100%;
    height: 90%;
}
</style>
