<template>
    <div class="relationship_graph">
        <div class="page_title">
            <b>{{ pageTitle }}</b>
        </div>
        <!-- 搜索 -->
        <div class="search_box public_box">
            <el-form ref="searchForm" :inline="true" :model="searchForm">
                <el-form-item label="" prop="caseNo">
                    <el-input class="width_450" v-model="searchForm.keyword" maxlength="50" placeholder="输入案件号，姓名，身份证号，车牌号，车架号，医院名称进行搜索" clearable></el-input>
                </el-form-item>
                <el-form-item label="报案时间" prop="caseNo">
                    <el-date-picker class="date_style" v-model="searchForm.startRepTime" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"> </el-date-picker>
                    -
                    <el-date-picker class="date_style" v-model="searchForm.endRepTime" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"> </el-date-picker>
                </el-form-item>
                <el-form-item label="损伤类型" prop="caseNo">
                    <el-select class="width_150" v-model="searchForm.lossType" placeholder="请选择" clearable>
                        <el-option v-for="item in lossTypeOptions" :key="item.code" :label="item.label" :value="item.code"> </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="案件状态" prop="caseStatus">
                    <el-select class="width_130" v-model="searchForm.caseStatus" placeholder="请选择" multiple filterable clearable>
                        <el-option v-for="(item, index) in statusOptions" :key="index" :label="item.status" :value="item.status"> </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="实际赔付金额区间" prop="">
                    <el-input
                        class="width_140"
                        v-model="searchForm.geAmount"
                        maxlength="50"
                        placeholder="最小值"
                        clearable
                        oninput="value=value.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '').replace(/^0+(\d)/, '$1')"
                    ></el-input>
                    -
                    <el-input
                        class="width_140"
                        v-model="searchForm.leAmount"
                        maxlength="50"
                        placeholder="最大值"
                        clearable
                        oninput="value=value.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '').replace(/^0+(\d)/, '$1')"
                    ></el-input>
                </el-form-item>
                <el-form-item label="案发地址" prop="">
                    <el-select class="width_120" v-model="searchForm.caseProvince" placeholder="省" clearable @change="provinceChange1">
                        <el-option v-for="item in provinceOptions" :key="item.areaCode" :label="item.areaName" :value="item.areaName + '-' + item.areaCode"> </el-option>
                    </el-select>
                    -
                    <el-select class="width_120" v-model="searchForm.caseCity" placeholder="市" :disabled="cityDisabled" clearable filterable @change="provinceChange2">
                        <el-option v-for="item in cityOptions" :key="item.areaCode" :label="item.areaName" :value="item.areaName + '-' + item.areaCode"> </el-option>
                    </el-select>
                    -
                    <el-select class="width_120" v-model="searchForm.caseCounty" placeholder="区" :disabled="districtDisabled" clearable filterable>
                        <el-option v-for="item in districtOptions" :key="item.areaCode" :label="item.areaName" :value="item.areaName + '-' + item.areaCode"> </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="(e) => getGraphData(false, e)" style="margin-left: 0.2rem">查询</el-button>
                    <el-button type="primary" :disabled="!myChartData" @click="download" style="margin-left: 0.2rem">下载</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="content_box public_box" style="height: calc(100vh - 230px)" v-loading="networkLoading">
            <!-- 画布 -->
            <div ref="chartContent" id="chart_content" class="chart_content"></div>
            <!-- 节点环形菜单 -->
            <div id="cricleMenu" class="cricleMenu">
                <div class="sector sector1" title="查看详情" @click="menuClick(1)">
                    <div class="menuIcon iconfont icon-details"></div>
                </div>
                <div class="sector sector2" title="隐藏该节点" @click="menuClick(2)">
                    <div class="menuIcon iconfont icon-yanjing_yincang_o"></div>
                </div>
                <div class="sector sector3" title="展开隐藏关系" @click="menuClick(3)">
                    <div class="menuIcon iconfont icon-ico"></div>
                </div>
                <div class="line1"></div>
                <div class="line2"></div>
                <div class="line3"></div>
                <div class="menu_center" :style="'background:' + menuCenterColor" @click="hideCricle">
                    <img :src="selectedNodeImg" v-show="selectedNodeImg" />
                </div>
            </div>
            <!-- 环形样式 hover -->
            <div id="cricleStyle" class="cricleStyle">
                <div class="menu_center">
                    <div class="custom_center" :style="'background:' + hoverCenterColor + '; borderColor:' + hoverCenterBorderColor" @click="setCricleMenu"></div>
                </div>
            </div>
            <!-- 背景 -->
            <div class="cavasBgc"></div>
            <!-- 悬浮窗 -->
            <div class="suspend" v-if="myChartData">
                <div :class="{ suspend_sidebar: 1, suspend_left: 1 }" :style="leftExpandFlag ? '' : 'width:20px !important;border: 1px solid #888;'">
                    <ul class="titleUl">
                        <li :class="{ activeTitle: leftExpandFlag }" @click="leftExpandFlag = !leftExpandFlag"><i :class="leftExpandFlag ? 'el-icon-arrow-left' : 'el-icon-arrow-right'"></i>{{ leftExpandFlag ? '快捷分析' : '' }}</li>
                    </ul>
                    <ul class="leftSidebarUl" v-if="leftExpandFlag" style="height: calc(100vh - 230px)">
                        <li :class="{ activeIndex: index == leftActiveIndex }" v-for="(item, index) in leftSideBar" :key="index" @click="sidebarClick(index, 'left')">{{ item.label }}</li>
                        <i class="resetBtn el-icon-refresh-right" @click="sidebarReset(0, '', true)"></i>
                    </ul>
                </div>
                <div :class="{ suspend_sidebar: 1, suspend_right: 1 }" :style="rightExpandFlag ? '' : 'width:20px !important;border: 1px solid #888;padding: 10px 0px;'">
                    <ul class="titleUl">
                        <li :class="{ activeTitle: rightExpandFlag }" @click="rightExpandFlag = !rightExpandFlag">{{ rightExpandFlag ? '快捷筛选' : '' }} <i :class="rightExpandFlag ? 'el-icon-arrow-right' : 'el-icon-arrow-left'"></i></li>
                    </ul>
                    <ul class="rightSidebarUl" v-if="rightExpandFlag" style="height: calc(100vh - 230px)">
                        <li :ref="'activeSideBar' + index" :class="{ activeIndex: index == getActiveCurrentVal(index) }" v-for="(item, index) in rightSideBar" :key="index" @click="sidebarClick(index, 'right')">
                            <p>
                                <span :class="'iconfont ' + item.icon"></span>
                            </p>
                            <p>{{ item.label }}</p>
                            <div class="cover"></div>
                        </li>
                        <i class="resetBtn el-icon-refresh-right" @click="sidebarReset(1)"></i>
                    </ul>
                </div>
            </div>
            <!-- 详情侧边栏 -->
            <div :class="{ info_sidebar: 1, show_sidebar: showSidebarFlag }">
                <div class="sidebar_title" :style="'background:' + infoTitleBgc">{{ infoTitleTxt }}</div>
                <i class="closeBtn el-icon-close" @click="showSidebarFlag = false"></i>
                <div class="infoContent" v-if="showSidebarFlag">
                    <el-row :class="{ activeRow: index % 2 == 0 }" v-for="(item, index) in currentNodeInfo.showTxtArr" :key="index">
                        <el-col class="sidebar_left" :span="8" :offset="1">{{ item.label }}：</el-col>
                        <el-col :span="12" :offset="1" v-if="!item.hidden">{{ item.value }}</el-col>
                        <el-col :span="12" :offset="1" v-else-if="item.label != '案件号'"><overflow-txt :txt="item.value"></overflow-txt></el-col>
                        <el-col :span="12" :offset="1" v-else>
                            <div class="tooltipText" @click="hrefDetail(item.value)">
                                <overflow-txt :txt="item.value"></overflow-txt>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row v-if="currentNodeInfo.type != 'Case' && currentNodeInfo.type != 'IdCardAddress'">
                        <el-col class="sidebar_left" :span="8" :offset="1">关联案件号：</el-col>
                        <el-col :class="{ case_content: 1, short_content: clientHeight < 800 }" :span="12" :offset="1">
                            <div class="tooltipText" v-for="(item, index) in relatedCases" :key="index" @click="hrefDetail(item)">
                                <overflow-txt :txt="item"></overflow-txt>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
        <!-- 悬浮 -->
        <transition name="el-fade-in-linear"> </transition>
    </div>
</template>

<script>
import nodeData from './nodeData.json';
import 'vis-network/dist/dist/vis-network.min.css';
import vis from 'vis-network/dist/vis-network.min';

export default {
    props: {
        isCollapse: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            showSidebarFlag: false,
            drawer: false,
            options: [
                {
                    value: '选项1',
                    label: '黄金糕'
                },
                {
                    value: '选项2',
                    label: '双皮奶'
                },
                {
                    value: '选项3',
                    label: '蚵仔煎'
                },
                {
                    value: '选项4',
                    label: '龙须面'
                },
                {
                    value: '选项5',
                    label: '北京烤鸭'
                }
            ],
            networkLoading: false,
            focusFlag: false,
            hoverShow: false,
            pageTitle: '',
            graphData: {},
            graphNodeObj: {},
            graphShow: false,
            locationOrigin: '',
            searchForm: {
                // keyword: '602020080703202304241000078',
                // keyword: '602020080703202212271000119',
                // keyword: 'test082701',
                // keyword: 'test082801',
                // keyword: '6882020080703202208241000001',
                keyword: '',
                startRepTime: '',
                endRepTime: '',
                lossType: '',
                geAmount: '',
                leAmount: '',
                caseProvince: '',
                caseCity: '',
                caseCounty: '',
                caseStatus: ''
            },
            lossTypeOptions: [
                { code: 1, label: '车物损' },
                { code: 2, label: '人伤' },
                { code: 3, label: '全部' }
            ],
            provinceOptions: [],
            cityOptions: [],
            districtOptions: [],
            cityDisabled: true,
            districtDisabled: true,
            selectNodeId: null,
            hoverToolTips: {
                caseNo: '',
                reportTime: '',
                accidentProperty: '',
                currentStatusDesc: '',
                totalPayAmount: ''
            },
            tooltipsData: {},
            leftExpandFlag: true,
            rightExpandFlag: true,
            leftActiveIndex: null,
            rightActiveIndex: 1,
            leftSideBar: [{ label: '骑手作为三者出险' }, { label: '同一车辆多次出险' }, { label: '骑手和三者疑似认识' }, { label: '出险大于一次的骑手' }, { label: '出险最多包商' }, { label: '出险大于一次的修理厂' }],
            rightSideBar: [
                { icon: 'icon-record-full', label: '案件', type: 'Case' },
                { icon: 'icon-renyuan', label: '人员', type: 'Person' }, // 节点文字颜色黑色
                { icon: 'icon-shigu', label: '三者车', type: 'Vehicle' },
                { icon: 'icon-dizhi', label: '地址', type: 'IdCardAddress' },
                { icon: 'icon-wxbzhanghu', label: '收款账户', type: 'Account' },
                { icon: 'icon-xiulichang', label: '修理厂', type: 'Repair' }, // 节点文字颜色黑色
                { icon: 'icon-hospital-building', label: '医院', type: 'Hospital' },
                { icon: 'icon-fenbaoshang', label: '包商', type: 'Contractor' } // 节点文字颜色黑色
            ],
            showMenu: false,
            menuPosition: null,
            chartStoreData: null,
            nodesMapData: {},
            relationsMapData: {},
            currentActiveNodeId: null,
            currentHoverNodeId: null,
            myChartData: null,
            currentNodeInfo: null,
            classifyNodesData: {},
            cricleShow: false,
            nodeTypeData: {
                Case: { color: '#CA90C0', borderColor: '#B261A4', type: '案件' },
                Person: { color: '#58C7E3', borderColor: '#23B3D7', type: '人' }, //字体  黑色
                Vehicle: { color: '#DA7094', borderColor: '#C73F6E', type: '三者车' },
                IdCardAddress: { color: '#569480', borderColor: '#5bc9a6', type: '地址' },
                Account: { color: '#F79767', borderColor: '#F36823', type: '收款账户' },
                Phone: { color: '#F26667', borderColor: '#E92829', type: '电话' },
                Appraisal: { color: '#ECB5CA', borderColor: '#DB7197', type: '鉴定机构' },
                Repair: { color: '#FFC453', borderColor: '#D7A012', type: '修理厂' }, //字体  黑色
                Hospital: { color: '#4C8EDA', borderColor: '#2871C1', type: '医院' },
                Contractor: { color: '#8DCC93', borderColor: '#5EB567', type: '包商' }
            },
            infoTitleBgc: '',
            infoTitleTxt: '',
            statusOptions: [],
            isExtendNode: false,
            sourceGraphData: null,
            animationFlag: true,
            menuCenterTxt: '',
            menuCenterColor: '',
            hoverCenterColor: '',
            hoverCenterBorderColor: '',
            activeCurrent0: null,
            activeCurrent1: null,
            activeCurrent2: null,
            activeCurrent3: 3,
            activeCurrent4: 4,
            activeCurrent5: 5,
            activeCurrent6: 6,
            activeCurrent7: 7,
            activeCurrent8: 8,
            relatedCases: [],
            hoverStyleOptions: {},
            nodeDragFlag: false,
            analyseHighlightNodes: [],
            analyseHighlightEdges: [],
            drawWatchTime: null,
            analyseScreen: {
                0: [0, 1], // 案件 骑手
                1: [0, 2], // 案件 车辆
                2: [0, 1, 3], // 案件 骑手(三者) 地址
                3: [0, 1], // 案件 骑手
                4: [0, 7], // 案件 包商
                5: [0, 5] // 案件 修理厂
            },
            selectedNodeId: null,
            selectedNodeImg: null,
            edgesOptions: {
                arrows: 'from',
                // 连接线默认颜色
                color: {
                    color: '#a5abb6',
                    highlight: '#ef5350',
                    hover: '#a5abb6',
                    inherit: 'from',
                    opacity: 1
                },
                font: {
                    color: '#000',
                    size: 12,
                    align: 'middle'
                },
                scaling: {
                    min: 1,
                    max: 1
                },
                arrowStrikethrough: true,
                endPointOffset: {
                    from: 0
                },
                selectionWidth: 1,
                hoverWidth: function (width) {
                    return width;
                },
                width: 1,
                shadow: false
            },
            // 更新连接线高亮颜色
            edgeColor: {
                color: '#ef5350',
                highlight: '#ef5350',
                hover: '#ef5350',
                inherit: 'from',
                opacity: 1
            },

            isStopped: false,
            number: 0,
            intervalId: null, // 用于自增的定时器ID
            checkStoppedId: null, // 用于检查是否停止的定时器ID
            lastUpdateTime: 0, // 上次更新时间戳
            timeoutThreshold: 2000, // 如果数字在2秒内没有更新，则认为停止
            searchData: null,
            queryAtlasData: null
        };
    },
    computed: {
        clientHeight() {
            return window.innerHeight;
        }
    },
    mounted() {
        window.addEventListener('resize', () => {
            this.resizeNetwork();
        });

        this.pageTitle = this.$route.meta.title;
        this.locationOrigin = location.origin.indexOf('localhost') != -1 ? 'http://jiaanantest.platform.sigreal.cn' : location.origin;
        this.getGraphData();
        // 查询省
        this.findProvince();
        this.getCaseStatus();
    },
    beforeDestroy() {
        // 清理资源
        window.removeEventListener('resize', this.handleResize);
        if (this.network) {
            this.network.destroy();
        }
    },
    methods: {
        checkIfStopped() {
            const currentTime = Date.now();
            if (currentTime - this.lastUpdateTime > this.timeoutThreshold) {
                // 如果当前时间与上次更新时间之差大于阈值，则认为停止
                clearInterval(this.intervalId); // 停止自增
                clearInterval(this.checkStoppedId); // 停止检查
                this.isStopped = true; // 标记为已停止
                console.log('动画停止了');
            }
        },
        startIncrement() {
            this.intervalId = setInterval(this.incrementNumber, 100); // 每100ms自增一次
            this.isStopped = false; // 重置停止状态
            this.lastUpdateTime = Date.now(); // 初始化最后更新时间
        },
        stopIncrement() {
            // 如果有需要可以手动停止自增（这里示例中没有使用）
            clearInterval(this.intervalId);
            clearInterval(this.checkStoppedId);
            this.isStopped = true;
        },
        // end
        resetDataStatus(clearAll) {
            this.nodesMapData = {};
            this.relationsMapData = {};
            this.classifyNodesData = {};
            this.hoverStyleOptions = {};
            this.analyseHighlightNodes = [];
            this.analyseHighlightEdges = [];
            this.myChartData = null;
            this.network = null;
            this.showSidebarFlag = false;
            this.animationFlag = false;
            // 画布加载
            this.networkLoading = true;
            // 节点焦点
            this.focusFlag = false;
            // 默认选中节点
            this.selectNodeId = null;

            // 画布右侧 - 快捷功能
            if (!clearAll) {
                this.leftActiveIndex = null;
                this.rightActiveIndex = null;
                this.sidebarReset(0);
                this.sidebarReset(1, true);
            }

            // 风险高亮数据
            this.analyseHighlightNodes = [];
            this.analyseHighlightEdges = [];
            this.hideCricle();
        },
        resizeNetwork() {
            this.network && this.network.redraw();
        },
        hrefDetail(caseNo) {
            let queryCaseFlag = false;
            let queryCaseNo;
            this.instance
                .get(`/case/getMeituanCaseNo?caseNo=${caseNo}`, {
                    params: {}
                })
                .then((res) => {
                    let data = res.data;
                    if (data.code == 1 && data.data) {
                        queryCaseFlag = true;
                        queryCaseNo = data.data;
                    } else {
                        this.$message.error('该案件查询失败！');
                    }

                    let url = 'https://www.jchunuo.com/fe/insurance-claim-service-platform/index.html#/detail/caseDetail/';
                    if (queryCaseFlag) {
                        if (queryCaseNo.indexOf('old') != -1) {
                            url = 'https://awp.meituan.com/ffe/insurance-claim-platform/index/index.html?code=MGAr4r&state=dx_auth_interceptor#/case?caseId=';
                        } else {
                            url = 'https://www.jchunuo.com/fe/insurance-claim-service-platform/index.html#/detail/caseDetail/';
                        }
                        window.open(url + queryCaseNo);
                    }
                });
        },
        handleResize() {
            if (this.network) {
                this.network.fit({ animation: true }); // 使得图表适应容器大小
            }
        },
        //获取案件状态
        getCaseStatus() {
            this.restaurants = [];
            this.instance
                .get('/case/getCaseStatus', {
                    params: {}
                })
                .then((res) => {
                    if (res.data.code == 1) {
                        this.statusOptions = res.data.data;
                        this.baseStatusOptions = res.data.data;
                    }
                });
        },
        getActiveCurrentVal(index) {
            return this['activeCurrent' + index];
        },
        findProvince() {
            this.instance.post('/area/findProvince', {}).then((res) => {
                let data = res.data;
                if (data.code == 1 && data.data) {
                    this.provinceOptions = data.data;
                }
            });
        },
        findCity() {
            let code = this.searchForm.caseProvince.split('-')[1];
            this.instance.post('/area/findCity/' + code, {}).then((res) => {
                let data = res.data;
                if (data.code == 1 && data.data) {
                    this.cityDisabled = false;
                    this.districtDisabled = false;
                    this.cityOptions = data.data;
                }
            });
        },
        findDistrict() {
            let code = this.searchForm.caseCity.split('-')[1];
            this.instance.post('/area/findDistrict/' + code, {}).then((res) => {
                let data = res.data;
                if (data.code == 1 && data.data) {
                    this.districtDisabled = false;
                    this.districtOptions = data.data;
                }
            });
        },
        provinceChange1(val) {
            this.searchForm.caseCity = '';
            this.searchForm.caseCounty = '';
            this.cityDisabled = true;
            this.districtDisabled = true;
            if (val) {
                this.findCity();
            }
        },
        provinceChange2(val) {
            this.searchForm.caseCounty = '';
            this.districtDisabled = true;
            if (val) {
                this.findDistrict();
            }
        },
        sidebarClick(itemIndex, activeType) {
            if (activeType == 'left') {
                // 高亮风险节点
                this.analyseNodesData(itemIndex);
                this.hideCricle();
            } else {
                this.hideCricle();
                if (this['activeCurrent' + itemIndex] == itemIndex) {
                    this['activeCurrent' + itemIndex] = null;
                } else {
                    this['activeCurrent' + itemIndex] = itemIndex;
                }
                this.updateNetwork(this.rightSideBar[itemIndex], this['activeCurrent' + itemIndex] == itemIndex);
            }
        },
        // 更新画布节点显示/隐藏
        updateNetwork(activeMenu, hideNodeFlag) {
            let handleIds = this.classifyNodesData[activeMenu.type + 'Ids'];
            this.network &&
                handleIds.forEach((itemId) => {
                    this.network.clustering.updateClusteredNode(itemId, { hidden: hideNodeFlag });
                });
        },
        // 扩展节点
        extendNodes() {
            let currentNode = this.nodesMapData[this.currentActiveNodeId];
            this.instance.post('/neo4j/searchDataLink', { node: currentNode }).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.isExtendNode = true;
                    let dataLinkNodes = data.data.nodes;
                    let dataLinkRelations = data.data.relations;
                    let dataLinkHandleNodes = [];
                    let dataLinkHandleRelations = [];
                    let showNodes = [];

                    // 合并数据
                    dataLinkNodes.forEach((item) => {
                        this.sourceGraphData.nodes.push(item);
                        // 过滤新节点是否已存在
                        if (item.depth == 1) {
                            if (!this.nodesMapData[item.elementId]) {
                                dataLinkHandleNodes.push(item);
                            } else {
                                //     console.log('节点已存在');
                                //     console.log(item);
                                showNodes.push(item);
                            }
                        }
                    });
                    dataLinkRelations.forEach((item) => {
                        let tmpId = item.startNodeId + '---' + item.type + '---' + item.endNodeId;
                        item.id = tmpId;
                        this.sourceGraphData.relations.push(item);
                        if (!this.relationsMapData[tmpId]) {
                            dataLinkHandleRelations.push(item);
                        }
                    });

                    // 非隐藏节点和连接线
                    let tmpData = this.handleNodesData(dataLinkHandleNodes, dataLinkHandleRelations, true);
                    // 画布添加新节点
                    if (dataLinkHandleNodes.length > 0) {
                        // 添加到画布
                        tmpData.nodes.forEach((item) => {
                            this.myChartData.nodes.add(item);
                        });
                    } else {
                        // 隐藏节点-被隐藏的节点，扩展要打开
                        // 现有节点筛选接口返回的节点,进行展示
                        showNodes.forEach((item) => {
                            this.network.clustering.updateClusteredNode(item.elementId, { hidden: false });
                        });
                    }

                    // 画布添加连接线
                    if (dataLinkHandleRelations.length > 0) {
                        // 添加新连接线
                        tmpData.edges.forEach((item) => {
                            this.myChartData.edges.add(item);
                        });
                    }

                    // 去重源数据
                    let uniqueNodeArray = this.sourceGraphData.nodes.filter((item, index, self) => {
                        return self.findIndex((t) => t.elementId === item.elementId) === index;
                    });
                    this.sourceGraphData.nodes = uniqueNodeArray;

                    let uniqueRelationsArray = this.sourceGraphData.relations.filter((item, index, self) => {
                        return self.findIndex((t) => t.id === item.id) === index;
                    });
                    this.sourceGraphData.relations = uniqueRelationsArray;

                    // 节点数据分类
                    this.nodesClassify();
                }
            });
        },
        searchNodeCases() {
            let analyseQueryData = {
                logic: 1,
                nodes: this.queryAtlasData.nodes,
                relations: this.queryAtlasData.relations,
                node: this.nodesMapData[this.currentActiveNodeId]
            };

            this.instance.post('/neo4j/searchNodeCases', analyseQueryData).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.relatedCases = data.data;
                }
            });
        },
        // 分析节点数据
        analyseNodesData(itemIndex) {
            this.networkLoading = true;
            this.instance.post('/neo4j/analyseData', this.handleSearchData('analyse', itemIndex)).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.queryAtlasData = data.data;
                    // 分析成功高亮菜单
                    this.leftActiveIndex = itemIndex;
                    // 清空画布
                    this.network && this.clearNetwork();
                    this.resetDataStatus(true);
                    // 绘制
                    this.makeVis(data.data.nodes, data.data.relations);

                    // 存储当前分析节点数据
                    this.analyseHighlightNodes = data.data.highlightNodes;
                    this.analyseHighlightEdges = data.data.highlightRelations;

                    this.setHighlightNodesAndEdges(data.data.highlightNodes, data.data.highlightRelations);

                    // 设置点击之前隐藏数据
                    this.rightSideBar.forEach((item, index) => {
                        if (index == this.getActiveCurrentVal(index)) {
                            this.updateNetwork(this.rightSideBar[index], this['activeCurrent' + index] == index);
                        }
                    });
                    // 快捷筛选 左右侧边栏联动
                    let hideArr = this.analyseScreen[this.leftActiveIndex];
                    // 设置选中功能
                    hideArr &&
                        hideArr.forEach((item) => {
                            if (String(this['activeCurrent' + item]) != 'null') {
                                this.sidebarClick(item, 'right');
                            }
                        });

                    this.networkLoading = false;
                } else {
                    this.networkLoading = false;
                }
            });
        },
        sidebarReset(sidebarIndex, updateFlag, queryFlag) {
            if (sidebarIndex == 0) {
                this.leftActiveIndex = null;
                this.clearNodesAndEdges();
                this.focusNodes();

                queryFlag && this.getGraphData(true);
            } else {
                this.hideCricle();
                this.rightSideBar.forEach((item, index) => {
                    if (index < 3) {
                        this['activeCurrent' + index] = null;
                    } else {
                        this['activeCurrent' + index] = index;
                    }
                    !updateFlag && this.updateNetwork(this.rightSideBar[index], this['activeCurrent' + index] == index);
                });
            }
        },
        clearNodesAndEdges() {
            this.network && this.network.selectNodes([]);

            // 清空节点高亮
            let currentNode;
            for (const key in this.nodesMapData) {
                currentNode = this.nodesMapData[key];
                this.myChartData.nodes.update({ id: currentNode.elementId, color: { background: this.nodeTypeData[currentNode.type].color, border: this.nodeTypeData[currentNode.type].borderColor }, borderWidth: 3 });
            }

            // 清空连接线高亮
            let currentEdge;
            let currentEdgeId;
            let updateEdgesOptions = JSON.parse(JSON.stringify(this.edgesOptions));
            // updateEdgesOptions.width = 5;
            // updateEdgesOptions.hoverWidth = 0.5;
            updateEdgesOptions.color = {
                color: '#A4AAB6',
                highlight: '#ef5350',
                inherit: 'from',
                opacity: 1
            };

            for (const key in this.relationsMapData) {
                currentEdge = this.relationsMapData[key];
                updateEdgesOptions.id = key;
                this.myChartData.edges.update(updateEdgesOptions);
            }
        },
        clearNetwork() {
            let newData = {
                nodes: new vis.DataSet([]),
                edges: new vis.DataSet([])
            };
            this.network.setData(newData);
        },
        focusNodes(focusFlag) {
            if (this.selectNodeId) {
                focusFlag &&
                    this.network.focus(this.selectNodeId, {
                        scale: 1
                    });
                this.network.selectNodes([this.selectNodeId]);
            }

            // 快捷筛选默认值
            if (String(this.leftActiveIndex) == 'null') {
                this.clearRightSide();
                let hideArr = [3, 4, 5, 6, 7];
                hideArr.forEach((item) => {
                    this.sidebarClick(item, 'right');
                });
            }
        },
        // 清空快捷选项  置为未选
        clearRightSide() {
            let screenStyleIndex = [0, 1, 2, 3, 4, 5, 6, 7];
            screenStyleIndex.forEach((item) => {
                if (String(this['activeCurrent' + item]) != 'null') {
                    this.sidebarClick(item, 'right');
                    this['activeCurrent' + item] = null;
                }
            });
        },
        handleNodesData(nodes, arelations, addFlag, queryFlag) {
            // 线 {id: 1,from: 1,to: 2},
            // 节点
            // {
            //     elementId: '4:0eecac30-1101-41eb-8dd7-2c032d743e50:0',
            //     type: 'Case',
            //     labels: ['Case'],
            //     properties: {
            //         currentStatusDesc: '赔付成功',
            //         totalPayAmount: 300,
            //         insureTypeName: '骑士意外险',
            //         caseNo: '602020080703202304011000155',
            //         accidentProperty: '三者物损',
            //         reportTime: '2023-04-01 15:37:41'
            //     },
            //     depth: 1,
            //     id: '4:0eecac30-1101-41eb-8dd7-2c032d743e50:0',
            //     text: '602020080703202304011000155'
            // }
            let tmpEdges = new vis.DataSet([]);
            let tmpNodes = new vis.DataSet([]);

            arelations.forEach((item, index) => {
                item.id = item.startNodeId + '---' + item.type + '---' + item.endNodeId;
                item.from = item.endNodeId;
                item.to = item.startNodeId;
                item.label = item.type;
                if (!this.relationsMapData[item.id]) {
                    this.relationsMapData[item.id] = item;
                    tmpEdges.add(item);
                }
                // else{
                //     console.log('已存在')
                //     console.log(item.id)
                // }
            });

            // 查询状态，展示重复线条
            if (queryFlag) {
                // 找出重复连接线
                let queryEdgesArr = this.findWithDuplicateIds(arelations);
                queryEdgesArr.forEach((item, index) => {
                    if (index > 0) {
                        item.id = item.startNodeId + '---' + item.type + '---' + item.endNodeId + '---' + index;
                        item.from = item.endNodeId;
                        item.to = item.startNodeId;
                        item.label = item.type;
                        this.relationsMapData[item.id] = item;
                        tmpEdges.add(item);
                    }
                });
            }

            nodes.forEach((item) => {
                let properties = item.properties;

                switch (item.type) {
                    case 'Case':
                        // 案件
                        item.text = properties.caseNo ? properties.caseNo : '';
                        this.tooltipsData[item.elementId] = {
                            caseNo: properties.caseNo,
                            reportTime: properties.reportTime,
                            insureTypeName: properties.insureTypeName,
                            accidentProperty: properties.accidentProperty,
                            currentStatusDesc: properties.currentStatusDesc,
                            totalPayAmount: properties.totalPayAmount
                        };
                        break;
                    case 'Person':
                        // 人员信息
                        item.text = item.properties.name ? item.properties.name : item.properties.identityNo;
                        break;
                    case 'Vehicle':
                        // 三者车
                        item.text = item.properties.vin ? item.properties.vin : '';
                        break;
                    case 'IdCardAddress':
                        // 地址

                        let tmpProv = item.properties.prov ? item.properties.prov : '';
                        let tmpCity = item.properties.city ? item.properties.city : '';
                        let tmpDistrict = item.properties.district ? item.properties.district : '';
                        let tmpConcatAddress = item.properties.concat_address ? item.properties.concat_address : '';
                        item.text = tmpProv + tmpCity + tmpDistrict + tmpConcatAddress;
                        break;
                    case 'Account':
                        // 收款账户
                        item.text = item.properties.accountName + ' ' + item.properties.accountNo;
                        break;
                    case 'Phone':
                        // 电话
                        item.text = item.properties.phone ? item.properties.phone : '';
                        break;
                    case 'Appraisal':
                        // 鉴定机构
                        item.text = item.properties.organization ? item.properties.organization : '';
                        break;
                    case 'Repair':
                        // 修理厂
                        item.text = item.properties.repairName ? item.properties.repairName : '';
                        break;

                    default:
                        break;
                }
                item.label = item.text;
                item.id = item.elementId;

                if (item.label == this.searchForm.keyword) {
                    this.selectNodeId = item.id;
                }

                // item.shape = 'dot'; // dot 圆 image 图片 custom 自定义(配合ctxRenderer)
                item.shape = 'dot';
                item.shape = 'circularImage';
                item.image = `${this.locationOrigin}/images/graph/${item.type}.png`;

                item.color = {
                    background: this.nodeTypeData[item.type].color,
                    // background: 'transparent',
                    border: this.nodeTypeData[item.type].borderColor,
                    hover: {
                        background: this.nodeTypeData[item.type].color,
                        border: this.nodeTypeData[item.type].borderColor
                    },
                    highlight: {
                        border: '#ffd775',
                        background: this.nodeTypeData[item.type].color
                    }
                };

                if (!this.nodesMapData[item.elementId]) {
                    this.nodesMapData[item.elementId] = item;
                    tmpNodes.add(item);
                }
            });

            return {
                nodes: tmpNodes,
                edges: tmpEdges
            };
        },
        download() {
            this.instance({
                method: 'post',
                url: '/neo4j/downloadCase',
                data: {
                    caseNos: this.classifyNodesData.caseNos
                },
                responseType: 'blob'
            }).then((res) => {
                let url = window.URL.createObjectURL(new Blob([res.data]));
                let link = document.createElement('a');
                link.style.display = 'none';
                link.href = url;
                link.setAttribute('download', '案件列表.xls'); //指定下载后的文件名，防跳转
                document.body.appendChild(link);
                link.click();
            });
        },
        getGraphData(clearAll) {
            // 清空画布
            this.network && this.clearNetwork();
            // 重置默认状态
            this.resetDataStatus(clearAll);

            let tmpSearch = JSON.parse(JSON.stringify(this.handleSearchData('search')));
            this.searchData = tmpSearch;
            this.instance.post('/neo4j/searchData', tmpSearch).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.sourceGraphData = data.data;
                    this.queryAtlasData = data.data;
                    this.makeVis(data.data.nodes, data.data.relations);
                    this.networkLoading = false;
                } else {
                    // this.networkLoading = false;
                    this.$message.error('查询失败！');
                }
            });
        },
        handleSearchData(dataType, itemIndex) {
            let data = {};
            let tmpSearchForm = JSON.parse(JSON.stringify(this.searchForm));
            if (dataType == 'analyse') {
                data = {
                    logic: itemIndex + 1
                };
                tmpSearchForm = this.searchData || {};
                // this.queryAtlasData
            }
            // 时间
            if (tmpSearchForm.startRepTime.indexOf(' 00:00:00') == -1) {
                tmpSearchForm.startRepTime = tmpSearchForm.startRepTime ? tmpSearchForm.startRepTime + ' 00:00:00' : '';
            }
            if (tmpSearchForm.endRepTime.indexOf(' 23:59:59') == -1) {
                tmpSearchForm.endRepTime = tmpSearchForm.endRepTime ? tmpSearchForm.endRepTime + ' 23:59:59' : '';
            }

            // 地址
            tmpSearchForm.caseProvince = tmpSearchForm.caseProvince.length > 0 && tmpSearchForm.caseProvince.split('-').length > 0 ? tmpSearchForm.caseProvince.split('-')[0] : '';
            tmpSearchForm.caseCity = tmpSearchForm.caseCity.length > 0 && tmpSearchForm.caseCity.split('-').length > 0 ? tmpSearchForm.caseCity.split('-')[0] : '';
            tmpSearchForm.caseCounty = tmpSearchForm.caseCounty.length > 0 && tmpSearchForm.caseCounty.split('-').length > 0 ? tmpSearchForm.caseCounty.split('-')[0] : '';

            return { ...data, ...tmpSearchForm };
        },
        makeVis(nodes, arelations) {
            let that = this;

            // 处理节点数据符合画布展示内容
            this.myChartData = this.handleNodesData(nodes, arelations, false, true);

            let container = document.getElementById('chart_content');

            const options = {
                autoResize: false,
                nodes: {
                    font: {
                        color: '#000',
                        size: 20
                    },
                    scaling: {
                        min: 0,
                        max: 0,
                        label: {
                            enabled: true,
                            min: 8,
                            max: 8,
                            maxVisible: 0
                        }
                    },
                    size: 30,
                    borderWidth: 3
                },
                edges: that.edgesOptions,
                groups: {
                    ws: {
                        shape: 'dot',
                        color: 'white'
                    }
                },
                interaction: {
                    hover: true,
                    zoomView: true,
                    // hoverConnectedEdges: true,
                    dragView: true
                },
                physics: {
                    barnesHut: {
                        // 引力常数   节点之间的引力强度。负值表示排斥力，正值表示吸引力
                        gravitationalConstant: -80000,
                        // 弹簧常数   节点链接之间的弹性力强度。该值越大，节点之间的连接线就会变得更紧密和更有张力
                        springConstant: 0.1,
                        // 弹簧长度   节点链接的自然长度。当链接的实际长度小于这个值时，会产生收缩力；当实际长度大于这个值时，会产生拉伸力。
                        springLength: 200,
                        // 中心引力   整个图的中心位置对节点的吸引力或排斥力的影响程度。较大的值会将节点聚焦到图的中心，较小的值会让节点更倾向于分散到整个布局
                        centralGravity: 0.5
                    },
                    stabilization: {
                        enabled: true,
                        iterations: 1, // 减少迭代次数
                        updateInterval: 1,
                        onlyDynamicEdges: false,
                        fit: true
                    }
                },
                layout: {
                    randomSeed: 2
                }
            };

            this.network = new vis.Network(container, this.myChartData, options);

            // 节点分类
            this.nodesClassify();

            // todo
            // 强制结束动画
            // that.network.stopPropagation();
            // that.network.stopSimulation();

            // 适应画布
            // that.network.stabilize();
            // stabilized

            setTimeout(() => {
                this.focusNodes();
                // this.checkStoppedId = setInterval(this.checkIfStopped, 100); // 每100ms检查一次
            }, 3000);

            // 动画结束
            this.network.on('stabilized', () => {
                // hover/click 环形样式
                if (!this.focusFlag) {
                    this.focusFlag = true;
                }
            });

            // 监听节点选择事件
            this.network.on('selectNode', (properties) => {
                this.selectedNodeId = properties.nodes[0];
            });

            // 画布绘制结束触发，动画结束前绘制多次，会多次结束，多次触发
            this.network.on('afterDrawing', () => {
                this.number++;
                this.animationFlag = true;
                if (this.selectedNodeId) {
                    this.currentActiveNodeId = this.selectedNodeId;
                    var positions = this.network.getPositions([this.selectedNodeId]);
                    var position = positions[this.selectedNodeId];
                    var scale = this.network.getScale();
                    let domPosi = this.network.canvasToDOM({ x: position.x, y: position.y });
                    // 更新选中节点的位置，使其跟随布局
                    this.network.moveNode(this.selectedNodeId, position.x, position.y);
                    this.setCriclePosition(Math.floor(domPosi.x - 54), Math.floor(domPosi.y - 55), scale);
                }
            });

            // that.network.on('zoom', (params) => {
            //     let max = 7;
            //     let min = 0.1;
            //     const zoom = params.scale;
            //     if (zoom > max) {
            //         this.network.moveTo({ scale: max });
            //     }
            //     if (zoom < min) {
            //         this.network.moveTo({ scale: min });
            //     }
            // });

            // 拖拽开始隐藏hover样式
            this.network.on('dragStart', (params) => {
                this.animationFlag = false;
                this.nodeDragFlag = true;
                this.hideHoverStyle();
            });

            // 拖拽后展示新位置
            this.network.on('dragEnd', (params) => {
                if (params.nodes && params.nodes.length > 0) {
                    this.nodeDragFlag = false;
                    this.network.clustering.updateClusteredNode(params.nodes[0], { physics: false });
                    this.network.stopSimulation();
                }
            });

            this.network.on('dragStart', (res) => {
                this.hideCricle();
            });
            this.network.on('controlNodeDragging', (res) => {
                this.hideCricle();
            });
            this.network.on('zoom', (res) => {
                this.hideCricle();
            });

            // 全局事件监听
            this.network.on('click', (e) => {
                if (e.nodes.length > 0) {
                    // 获取当前点击节点id
                    this.currentActiveNodeId = e.nodes[0];
                    this.selectedNodeId = this.currentActiveNodeId;

                    // 节点展示环形菜单
                    let p = this.network.getPosition(e.nodes[0]);
                    let scale = this.network.getScale();
                    let domPosi = this.network.canvasToDOM({ x: p.x, y: p.y });
                    // 设置环形位置
                    if (this.cricleShow) {
                        this.hideCricle();
                    } else {
                        this.setCriclePosition(Math.floor(domPosi.x - 54), Math.floor(domPosi.y - 55), scale, true);
                    }
                } else {
                    this.showSidebarFlag = false;
                    this.hideCricle();
                }
            });

            this.network.on('hoverNode', (e) => {
                if (e.pointer.DOM && that.animationFlag && !that.nodeDragFlag) {
                    // hover 以后再说
                    // this.setHoverStyle(e.node);
                } else {
                    this.hideHoverStyle();
                }
            });

            this.network.on('blurNode', (e) => {
                this.hideHoverStyle();
            });
        },
        checkAnimationStatus() {
            let status = true;
            let animationNum = 0;
            let checkTime = setInterval(() => {
                if (!this.animationFlag) {
                    status = false;
                }
            }, 1000);
            return status;
        },
        // 检查高亮节点
        // checkHighlightNodesAndEdges() {
        //     if (String(this.leftActiveIndex) != null) {
        //         this.setHighlightNodesAndEdges(this.analyseHighlightNodes, this.analyseHighlightEdges);
        //     }
        // },
        findWithDuplicateIds(arr) {
            // 使用Map来跟踪每个name及其出现次数
            const tmpIdCounts = new Map();

            // 遍历数组，统计每个name的出现次数
            arr.forEach((obj) => {
                const tmpId = obj.id;
                if (tmpIdCounts.has(tmpId)) {
                    tmpIdCounts.set(tmpId, tmpIdCounts.get(tmpId) + 1);
                } else {
                    tmpIdCounts.set(tmpId, 1);
                }
            });

            // 过滤出那些name出现次数大于1的对象
            return arr.filter((obj) => tmpIdCounts.get(obj.id) > 1);
        },
        // 画布节点高亮
        setHighlightNodesAndEdges(highlightNodesData, highlightEdgesData) {
            this.clearNodesAndEdges();
            let selectNodes = [];
            let selectEdges = [];
            let highlightNodes = highlightNodesData;
            highlightNodes &&
                highlightNodes.forEach((item) => {
                    selectNodes.push(item.elementId);
                    this.myChartData.nodes.update({ id: item.elementId, color: { background: this.nodeTypeData[item.type].color, border: '#FFD488' }, borderWidth: 5 });
                });

            let highlightEdges = highlightEdgesData;
            let tmpId;
            let updateHighEdgesOptions = {
                color: this.edgeColor
            };

            let highlightLines = [];
            highlightEdgesData &&
                highlightEdges.forEach((item) => {
                    tmpId = item.startNodeId + '---' + item.type + '---' + item.endNodeId;
                    item.tmpId = tmpId;
                    item.id = tmpId;
                    selectEdges.push(tmpId);
                    updateHighEdgesOptions.id = item.id;
                    this.myChartData.edges.update(updateHighEdgesOptions);
                });

            // 找出重复的高亮连接线
            let analyseEdgesArr = this.findWithDuplicateIds(highlightEdges);

            analyseEdgesArr.forEach((item, index) => {
                if (index > 0) {
                    item.id = item.startNodeId + '---' + item.type + '---' + item.endNodeId + '---' + index;
                    updateHighEdgesOptions.id = item.id;
                    this.myChartData.edges.update(updateHighEdgesOptions);
                }
            });
        },
        // 设置节点hover样式
        setHoverStyle(currentId) {
            let p = this.network.getPosition(currentId);
            // 获取当前点击节点id
            this.currentHoverNodeId = currentId;
            this.currentActiveNodeId = currentId;

            // 节点展示环形菜单
            let scale = this.network.getScale();

            let domPosi = this.network.canvasToDOM({ x: p.x, y: p.y });
            // 设置hover环形位置
            this.setHoverPosition(Math.floor(domPosi.x - 49), Math.floor(domPosi.y - 49), scale);
        },
        getNodeSize(nodeId) {
            var node = this.network.body.nodes[nodeId];
            if (node) {
                var bbox = this.network.getBoundingBox(node.id);
                return {
                    width: bbox.width,
                    height: bbox.height
                };
            }
            return null;
        },
        setCricleMenu() {
            if (this.currentHoverNodeId) {
                // 节点展示环形菜单
                let p = this.network.getPosition(this.currentHoverNodeId);
                let scale = this.network.getScale();
                let domPosi = this.network.canvasToDOM({ x: p.x, y: p.y });
                // 设置环形位置
                if (this.cricleShow) {
                    this.hideCricle();
                } else if (this.animationFlag) {
                    this.setCriclePosition(Math.floor(domPosi.x - 19), Math.floor(domPosi.y - 20), scale, true);
                }
            } else {
                this.hideCricle();
            }
        },
        drawTextWithWrap(ctx, text, color, x, y, maxWidth, lineHeight) {
            let words = text.split(' ');

            let line = '';
            ctx.fillStyle = color;
            words.forEach((word) => {
                let textArr = this.splitStringByLength(word, 15);
                textArr.forEach((item) => {
                    let testLine = line + item + ' ';
                    let metrics = ctx.measureText(testLine);
                    let testWidth = metrics.width;

                    if (testWidth > maxWidth && line !== '') {
                        ctx.fillText(line, x, y);
                        line = item + ' ';
                        y += lineHeight;
                    } else {
                        // 否则，将新单词添加到当前行
                        line = testLine;
                    }
                });
            });

            // 绘制最后一行
            ctx.fillStyle = color;
            ctx.fillText(line, x, y);
        },
        splitStringByLength(str, length) {
            let result = [];
            for (let i = 0; i < str.length; i += length) {
                let end = i + length;
                // 如果end超出了字符串的长度，则只取到字符串的末尾
                end = end > str.length ? str.length : end;
                result.push(str.substring(i, end));
            }
            return result;
        },
        menuClick(menuType) {
            switch (menuType) {
                case 1:
                    // 展示详情
                    this.relatedCases = [];
                    this.currentNodeInfo = this.setNodesSidebarData(this.nodesMapData[this.currentActiveNodeId]);
                    this.showSidebarFlag = true;
                    this.infoTitleTxt = this.nodeTypeData[this.currentNodeInfo.type].type;
                    this.infoTitleBgc = this.nodeTypeData[this.currentNodeInfo.type].color;
                    this.searchNodeCases();
                    break;
                case 2:
                    // 隐藏节点
                    this.network && this.network.clustering.updateClusteredNode(this.currentActiveNodeId, { hidden: true });
                    break;

                default:
                    // 扩展
                    this.extendNodes();
                    break;
            }
            this.hideHoverStyle();
            this.hideCricle();
        },
        setNodesSidebarData(currentInfoData) {
            let showTxtArr = [];
            switch (currentInfoData.type) {
                case 'Case':
                    // 案件
                    showTxtArr = [
                        {
                            label: '案件号',
                            hidden: true,
                            value: currentInfoData.properties.caseNo || ''
                        },
                        {
                            label: '报案时间',
                            value: currentInfoData.properties.reportTime || ''
                        },
                        {
                            label: '险种类型',
                            value: currentInfoData.properties.insureTypeName || ''
                        },
                        {
                            label: '事故属性',
                            value: currentInfoData.properties.accidentProperty || ''
                        },
                        {
                            label: '当前状态',
                            value: currentInfoData.properties.currentStatusDesc || ''
                        },
                        {
                            label: '赔偿金额',
                            value: currentInfoData.properties.totalPayAmount ? currentInfoData.properties.totalPayAmount + '元' : ''
                        }
                    ];

                    break;
                case 'Person':
                    // 人

                    showTxtArr = [
                        {
                            label: 'id',
                            value: currentInfoData.properties.matchId || ''
                        },
                        {
                            label: '姓名',
                            value: currentInfoData.properties.name || ''
                        },
                        {
                            label: '身份证号',
                            value: currentInfoData.properties.identityNo || ''
                        }
                    ];

                    break;
                case 'Vehicle':
                    // 车

                    showTxtArr = [
                        {
                            label: '车牌号',
                            value: currentInfoData.properties.plateNo || ''
                        },
                        {
                            label: '车架号',
                            value: currentInfoData.properties.vin || ''
                        }
                    ];

                    break;
                case 'IdCardAddress':
                    // 地址
                    let tmpProv = currentInfoData.properties.prov ? currentInfoData.properties.prov : '';
                    let tmpCity = currentInfoData.properties.city ? currentInfoData.properties.city : '';
                    let tmpDistrict = currentInfoData.properties.district ? currentInfoData.properties.district : '';
                    let tmpConcatAddress = currentInfoData.properties.concat_address ? currentInfoData.properties.concat_address : '';
                    showTxtArr = [
                        {
                            label: '详细地址',
                            value: tmpProv + tmpCity + tmpDistrict + tmpConcatAddress
                        },
                        {
                            label: '省',
                            value: tmpProv
                        },
                        {
                            label: '市',
                            value: tmpCity
                        },
                        {
                            label: '区（县）',
                            value: tmpDistrict
                        },
                        {
                            label: '乡',
                            value: tmpConcatAddress
                        }
                    ];

                    break;
                case 'Account':
                    // 收款账户

                    showTxtArr = [
                        {
                            label: '账户',
                            value: currentInfoData.properties.accountNo || ''
                        },
                        {
                            label: '账户名称',
                            value: currentInfoData.properties.accountName || ''
                        },
                        {
                            label: '开户行',
                            value: currentInfoData.properties.headBankName || ''
                        }
                    ];

                    break;
                case 'Phone':
                    // 电话

                    showTxtArr = [
                        {
                            label: '电话号码',
                            value: currentInfoData.properties.phone || ''
                        }
                    ];

                    break;
                case 'Appraisal':
                    // 鉴定机构

                    showTxtArr = [
                        {
                            label: '鉴定机构名称',
                            value: currentInfoData.properties.organization || ''
                        }
                    ];

                    break;
                case 'Repair':
                    // 修理厂

                    showTxtArr = [
                        {
                            label: '修理厂名称',
                            value: currentInfoData.properties.repairName || ''
                        }
                    ];

                    break;
                case 'Hospital':
                    // 医院

                    showTxtArr = [
                        {
                            label: '医院名称',
                            value: currentInfoData.properties.hospitalName || ''
                        }
                    ];

                    break;
                case 'Contractor':
                    // 医院

                    showTxtArr = [
                        {
                            label: '包商名',
                            value: currentInfoData.properties.contractorName || ''
                        }
                    ];

                    break;

                default:
                    break;
            }

            currentInfoData.showTxtArr = showTxtArr;
            return currentInfoData;
        },
        setCriclePosition(x, y, scale, setImg) {
            let cricleMenu = document.getElementById('cricleMenu');
            cricleMenu.style = `left: ${x}px; top: ${y}px;transform:scale(${scale});display:block;`;
            this.menuCenterColor = this.nodeTypeData[this.nodesMapData[this.currentActiveNodeId].type].color;
            this.menuCenterTxt = this.nodeTypeData[this.nodesMapData[this.currentActiveNodeId].type].type;
            // 环形菜单中间图片
            if (setImg) {
                this.selectedNodeImg = null;
                this.selectedNodeImg = this.nodesMapData[this.selectedNodeId].image;
                // setTimeout(() => {
                //     console.log('设置预览图');
                //     this.selectedNodeImg = this.nodesMapData[this.selectedNodeId].image;
                //     console.log(this.selectedNodeImg);
                //     this.$forceUpdate();
                // }, 1000);
            }
        },
        hideCricle() {
            this.selectedNodeId = null;
            let cricleMenu = document.getElementById('cricleMenu');
            if (cricleMenu) {
                cricleMenu.style = `display:none`;
            }
        },
        setHoverPosition(x, y, scale) {
            this.hoverStyleOptions = { x: x, y: y, scale: scale };
            let cricleStyle = document.getElementById('cricleStyle');
            cricleStyle.style = `left: ${x}px; top: ${y}px;transform:scale(${scale});display:block;`;
            this.hoverCenterColor = this.nodeTypeData[this.nodesMapData[this.currentHoverNodeId].type].color;
            this.hoverCenterBorderColor = this.nodeTypeData[this.nodesMapData[this.currentHoverNodeId].type].borderColor;
        },
        hideHoverStyle() {
            let cricleMenu = document.getElementById('cricleStyle');
            cricleMenu.style = `display:none;`;
        },
        hideMenu() {
            this.showMenu = false;
        },
        // 节点分类
        nodesClassify() {
            let contractorIds = [];
            let hospitalIds = [];
            let repairIds = [];
            let appraisalIds = [];
            let phoneIds = [];
            let idCardAddressIds = [];
            let accountIds = [];
            let vehicleIds = [];
            let personIds = [];
            let caseIds = [];
            let caseNos = [];
            // 全类型是9个
            this.myChartData.nodes.forEach((item) => {
                switch (item.type) {
                    case 'Case':
                        // 案件
                        caseIds.push(item.id);
                        caseNos.push(item.properties.caseNo || '');
                        break;
                    case 'Person':
                        // 人
                        personIds.push(item.id);
                        break;
                    case 'Vehicle':
                        // 三者车
                        vehicleIds.push(item.id);
                        break;
                    case 'IdCardAddress':
                        // 地址
                        idCardAddressIds.push(item.id);
                        break;
                    case 'Account':
                        // 收款账户
                        accountIds.push(item.id);
                        break;
                    case 'Phone':
                        // 电话
                        phoneIds.push(item.id);
                        break;
                    case 'Appraisal':
                        // 鉴定机构
                        appraisalIds.push(item.id);
                        break;
                    case 'Repair':
                        // 修理厂
                        repairIds.push(item.id);
                        break;
                    case 'Hospital':
                        // 医院
                        hospitalIds.push(item.id);
                        break;
                    case 'Contractor':
                        // 包商
                        contractorIds.push(item.id);
                        break;

                    default:
                        break;
                }
            });

            this.classifyNodesData = {
                CaseIds: caseIds,
                PersonIds: personIds,
                VehicleIds: vehicleIds,
                IdCardAddressIds: idCardAddressIds,
                AccountIds: accountIds,
                PhoneIds: phoneIds,
                AppraisalIds: appraisalIds,
                RepairIds: repairIds,
                HospitalIds: hospitalIds,
                ContractorIds: contractorIds,
                caseNos: caseNos
            };
        }
    },
    beforeDestroy() {
        // 组件销毁前停止所有定时器
        clearInterval(this.intervalId);
        clearInterval(this.checkStoppedId);
    },
    watch: {
        isCollapse: {
            handler(val) {
                this.hideCricle();
                setTimeout(() => {
                    this.resizeNetwork();
                }, 600);
            },
            immediate: true
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/css/relationshipGraph/iconfont.css';
.iconfont {
    font-family: 'iconfont' !important;
    font-size: 22px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #409eff;
}
.date_style {
    width: 130px !important;
}
.relationship_graph {
    .search_box {
        padding-bottom: 0;
    }
    .content_box {
        overflow: hidden;
        position: relative;
        padding: 0px !important;
        background: none;
        // 画布
        .chart_content {
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 5;
        }
        .cricleMenu,
        .cricleStyle {
            position: absolute;
            left: 0px;
            top: 0px;
            width: 110px;
            height: 110px;
            border-radius: 50%;
            overflow: hidden;
            z-index: 10;
            cursor: pointer;
            display: none;
            // transform: rotate(15deg) !important;

            .sector {
                position: absolute;
                width: 100%;
                height: 100%;
                clip-path: polygon(50% 50%, 100% 0, 100% 243%);
                transition: background-color 0.3s;

                .menuIcon {
                    position: absolute;
                    z-index: 12;
                    width: 20px;
                    height: 20px;
                    color: #fff;
                    font-size: 21px;
                }
            }

            /* 第一部分 */
            .sector1 {
                background: #d2d5da;
                transform: rotate(0deg);
                .menuIcon {
                    position: absolute;
                    top: 53px;
                    left: 85px;
                }
            }

            .sector1:hover {
                background: #b9b9b9;
            }

            /* 第二部分 */
            .sector2 {
                background: #d2d5da;
                transform: rotate(120deg);
                .menuIcon {
                    top: 54px;
                    left: 89px;
                    transform: rotate(55deg);
                }
            }

            .sector2:hover {
                background: #b9b9b9;
            }

            /* 第三部分 */
            .sector3 {
                background: #d2d5da;
                transform: rotate(240deg);
                .menuIcon {
                    top: 51px;
                    left: 85px;
                }
            }

            .sector3:hover {
                background: #b9b9b9;
            }

            .line1,
            .line2,
            .line3 {
                height: 2px;
                width: 30px;
                background: #fff;
                position: absolute;
            }
            .line1 {
                left: 1px;
                top: 42px;
                transform: rotate(16deg);
            }
            .line2 {
                left: 71px;
                top: 25px;
                transform: rotate(136deg);
            }
            .line3 {
                left: 48px;
                top: 93px;
                transform: rotate(79deg);
            }

            .menu_center {
                width: 60%;
                height: 60%;
                width: 60px;
                height: 60px;
                border-radius: 50%;
                // background: #fff;
                // background: transparent;
                // border: 1px solid red;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                overflow: hidden;
            }
        }
        .cricleStyle {
            z-index: -1;
            .menu_center {
                width: 80px;
                height: 80px;
                background: transparent !important;
                border: 10px solid #cfecff;
                .custom_center {
                    width: 62px;
                    height: 62px;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    border-radius: 50%;
                    border: 4px solid transparent;
                }
            }
        }

        // 画布背景
        .cavasBgc {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background-color: #fff;
            z-index: -3;
        }
        // 悬浮窗
        .suspend {
            .suspend_sidebar {
                // height: 400px;
                overflow-y: auto;
                border-radius: 4px;
                padding: 10px 6px;
                position: absolute;
                top: 0;
                // background: rgb(234, 234, 234);
                background: #fff;
                z-index: 10;
                box-shadow: 0px 2px 4px 0px rgba(223, 223, 223, 0.5);
                .leftSidebarUl,
                .rightSidebarUl {
                    text-align: center;
                    margin-top: 5px;
                    li {
                        cursor: pointer;
                        padding: 5px 0;
                        margin-bottom: 3px;
                        text-align: center;
                        border-radius: 5px;
                    }
                    li:hover {
                        // background: #fff;
                        background: #888;
                        color: #fff;
                        span {
                            color: #fff;
                        }
                    }

                    .resetBtn {
                        font-size: 20px;
                        color: #333;
                        cursor: pointer;
                        margin-top: 10px;
                    }
                    .resetBtn:hover {
                        color: #409eff;
                    }
                }
                .titleUl {
                    li {
                        cursor: pointer;
                        text-align: center;
                    }
                    .activeTitle {
                        margin-bottom: 0;
                        padding-bottom: 5px;
                        cursor: pointer;
                        border-bottom: 2px solid #fff;
                    }
                }
                .leftSidebarUl {
                    padding-top: 8px;
                    li {
                        margin-bottom: 10px;
                    }
                    .activeIndex {
                        // background: #fff;
                        background: #888;
                        color: #fff;
                    }
                }
                .rightSidebarUl {
                    li {
                        width: 52px;
                        position: relative;
                        left: 50%;
                        transform: translateX(-50%);
                        overflow: hidden;
                        .iconfont {
                            font-size: 16px;
                        }
                    }
                    li:hover {
                        // background: #fff;
                        background: #888;
                        color: #fff;
                    }
                    .activeIndex {
                        position: relative;
                        .cover {
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            top: 0;
                            left: 0;
                            z-index: 5;
                            background: rgb(167 167 167 / 70%);
                        }
                        .cover::before {
                            content: '';
                            display: inline-block;
                            width: 159%;
                            height: 2px;
                            background: #e37878;
                            position: absolute;
                            left: -19px;
                            transform: rotate(37deg);
                            top: 30px;
                        }
                    }
                    .activeIndex::before {
                        width: 100%;
                        height: 10px;
                        background: #fff;
                        position: absolute;
                        left: 0;
                        top: 0;
                    }
                }
            }
            .superHeight {
                height: 400px;
            }
            .suspend_left {
                left: 0;
                width: 168px;
                padding: 10px 3px;
            }
            .suspend_right {
                right: 0;
            }
        }
        // 详情侧边栏
        .info_sidebar {
            width: 0px;
            height: 100%;
            overflow: hidden;
            background: #fff;
            position: absolute;
            top: 0;
            right: 0;
            z-index: 11;
            box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
            transition: width 0.3s ease-in-out;
            .sidebar_title {
                display: inline-block;
                min-width: 60px;
                margin: 10px 0 0px 10px;
                text-align: center;
                color: #fff;
                line-height: 20px;
                border-radius: 10px;
                padding: 2px 11px;
                font-size: 15px;
            }
            .closeBtn {
                position: absolute;
                top: 10px;
                right: 5px;
                font-size: 18px;
            }
            .el-row {
                margin-bottom: 10px;
                font-size: 15px;
                .sidebar_left {
                    text-align: right;
                    font-weight: bold;
                }
                .case_content {
                    height: 270px;
                    // border: 1px solid red;
                    overflow-y: auto;
                }
                .short_content {
                    height: 227px !important;
                }
            }
            .activeRow {
                background: #f5f5f5;
                padding: 5px 0px;
            }
            .infoContent {
                width: 300px;
                padding: 25px 10px;
                padding-top: 15px;
            }
        }
        .show_sidebar {
            width: 300px;
        }
    }
}
</style>
