<template>
    <div class="module_wrap box_border" v-loading="loading" element-loading-text="数据加载中" element-loading-spinner="el-icon-loading" element-loading-background="transparent">
        <div class="box_title">公估公司接案量统计</div>

        <div style="height: 100%" v-show="!loading">
            <div id="rate_pie"></div>
            <div class="tables_box" v-if="tableData.length > 0">
                <el-table :data="[tableData[0]]" max-height="200">
                    <el-table-column v-for="(item, index) in tableData" :label="item.assessor" :key="index" :width="province == '全国' ? '' : '100'">
                        <template slot-scope="scope">
                            <span v-if="0">{{ scope.row }}</span>
                            <p>{{ item.assessorRatio ? item.assessorRatio : '0' }} %</p>
                            <p>{{ item.assessorCaseNum ? item.assessorCaseNum : '0' }} 件</p>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
    name: 'CaseRate',
    props: {
        isCollapse: {
            type: Boolean,
            default: true
        },
        navForm: {
            type: Object,
            default: {}
        }
    },
    watch: {
        isCollapse() {
            setTimeout(() => {
                this.myChart && this.myChart.resize();
            }, 600);
        },
        navForm: {
            // immediate: true,
            deep: true,
            handler(val) {
                this.province = val.accidentProvince;
                this.getData();
            }
        }
    },
    data() {
        return {
            topDays: [],
            newNumber: 0,
            myChart: '',
            tableData: [],
            loading: true,
            province: ''
        };
    },
    mounted() {
        this.role = localStorage.getItem('role');
        this.getData();
        window.addEventListener('resize', () => {
            if (this.myChart) {
                setTimeout(() => {
                    this.myChart.resize();
                }, 500);
            }
        });
    },
    methods: {
        getData() {
            this.loading = true;
            this.instance({
                method: 'post',
                url: '/large/data/screen/meituanAssessorCaseNum',
                data: this.navForm,
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8'
                }
            }).then((res) => {
                this.loading = false;
                if (res.data.code == 1) {
                    let response = res.data.data;
                    if (response.length > 0) {
                        this.loadCharts(response);
                    }
                }
            });
        },
        loadCharts(response) {
            var chartDom = document.getElementById('rate_pie');
            if (!chartDom) {
                return;
            }
            this.myChart = echarts.init(chartDom, null, { renderer: 'svg' });
            let xAxisData = [],
                seriesData = [];
            response.forEach((val, key) => {
                xAxisData.push(val.assessor);
                seriesData.push(val.assessorRatio);
            });
            this.tableData = response;
            var option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'none'
                    },
                    formatter: function (params) {
                        let dataIndex = params[0].dataIndex;
                        return params[0].name + '<br/>接案率：' + params[0].value + '%<br/>接案案件量：' + response[dataIndex].assessorCaseNum + '件';
                    }
                },
                xAxis: {
                    data: xAxisData,
                    axisTick: { show: false },
                    axisLine: { show: false },
                    axisLabel: {
                        color: '#fff',
                        // fontSize: '0.12rem',
                        rotate: 30
                    }
                },
                yAxis: {
                    splitLine: { show: false },
                    axisTick: { show: false },
                    axisLine: { show: false },
                    axisLabel: { show: false }
                },
                grid: {
                    top: '20',
                    bottom: '0',
                    left: '10',
                    right: '10',
                    containLabel: true
                },
                color: ['#e54035'],
                series: [
                    {
                        name: 'hill',
                        type: 'pictorialBar',
                        barCategoryGap: '-40%',
                        label: {
                            // show: true,
                            position: 'top',
                            // fontSize: '0.12rem',
                            color: '#fff',
                            formatter: '{c}%'
                        },
                        // symbol: 'path://M0,10 L10,10 L5,0 L0,10 z',
                        symbol: 'path://M0,10 L10,10 C5.5,10 5.5,5 5,0 C4.5,5 4.5,10 0,10 z',
                        itemStyle: {
                            // opacity: 0.7
                        },
                        barWidth: '80%',
                        emphasis: {
                            itemStyle: {
                                opacity: 1
                            }
                        },
                        data: seriesData,
                        z: 10
                    }
                ]
            };
            this.myChart.setOption(option);
        }
    },
    updated() {
        this.myChart && this.myChart.resize();
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.module_wrap {
    width: 100%;
    /* height: 3.5rem; */
    /* height: 45.5%; */
    height: 5.5rem;
    padding: 0.15rem 0.3rem 0;
    // margin-top: 40px;
    .tables_box {
        width: 100%;
        height: 100px;
        padding: 0.3rem;
        padding-bottom: 0;
        padding-top: 0;
        margin-top: 0;
        div {
            background: none;
        }
        /deep/ tr,
        /deep/.el-table__cell {
            background: none;
            color: #fff;
            // border-color: #006ec4;
        }
        /deep/.el-table__row::hover {
            background: none;
        }
        /deep/ tr:hover > td {
            background: none;
        }
        /deep/ td.el-table__cell {
            border: 0px;
        }
        /deep/ .el-table--border {
            border: none;
            border: 0px;
        }
    }
}
#rate_pie {
    width: 100%;
    /* height: 2.8rem; */
    // height: 90%;
    height: 45%;
    margin-top: 0.1rem;
}
</style>
