<template>
    <div :class="echartsType == '1' ? 'module_wrap box_border' : 'module_wrap module_wrap2 box_border'" v-loading="loading" element-loading-text="数据加载中" element-loading-spinner="el-icon-loading" element-loading-background="transparent">
        <div class="box_title">公估风险案件{{ echartsType == '1' ? '' : '占比' }}统计</div>

        <div style="height: 100%" v-show="!loading">
            <div id="case_number_ratio_pie" v-if="echartsType == '1'"></div>
            <div id="case_number_ratio_pie2" v-else></div>
        </div>
    </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
    name: 'CaseNumberRatio',
    props: {
        isCollapse: {
            type: Boolean,
            default: true
        },
        navForm: {
            type: Object,
            default: {}
        },
        echartsType: {
            type: String,
            default: ''
        }
    },
    watch: {
        isCollapse() {
            setTimeout(() => {
                this.myChart && this.myChart.resize();
            }, 600);
        },
        navForm: {
            // immediate: true,
            deep: true,
            handler(val) {
                this.getData();
            }
        }
    },
    data() {
        return {
            topDays: [],
            newNumber: 0,
            myChart: '',
            loading: true
        };
    },
    mounted() {
        this.role = localStorage.getItem('role');
        window.addEventListener('resize', () => {
            if (this.myChart) {
                setTimeout(() => {
                    this.myChart.resize();
                }, 500);
            }
        });
    },
    methods: {
        getData() {
            this.loading = true;
            this.instance({
                method: 'post',
                url: '/large/data/screen/getRiskLossEstimation',
                data: this.navForm,
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8'
                }
            }).then((res) => {
                this.loading = false;
                if (res.data.code == 1) {
                    let response = res.data.data;

                    if (this.echartsType == '1') {
                        this.loadCharts(response);
                    } else {
                        this.loadCharts2(response);
                    }
                }
            });
        },
        loadCharts(response) {
            var chartDom = document.getElementById('case_number_ratio_pie');
            if (!chartDom) {
                return;
            }
            this.myChart = echarts.init(chartDom, null, { renderer: 'svg' });
            let xAxisData = [],
                seriesData = [];
            if (!response.caseRiskMoney) {
                return;
            }
            response.caseRiskMoney.forEach((val, key) => {
                xAxisData.push(val.name);
                seriesData.push(val.caseNum);
            });
            var option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'none'
                    }
                    // formatter: function (params) {
                    //     let dataIndex = params[0].dataIndex;
                    //     return params[0].name + '<br/>风险案件数量占比：' + params[0].value + '%<br/>风险案件赔款金额：' + response[dataIndex].amountCaseFiled + '元<br/>风险案件数：' + response[dataIndex].caseNum + '件';
                    // }
                },
                xAxis: {
                    data: xAxisData,
                    axisTick: { show: false },
                    // axisLine: { show: false },
                    axisLabel: {
                        color: '#fff',
                        // fontSize: '0.12rem',
                        rotate: 50
                    }
                },
                yAxis: {
                    splitLine: { show: false },
                    axisTick: { show: false },
                    // axisLine: { show: false },
                    axisLabel: { show: false }
                },
                grid: {
                    top: '10',
                    bottom: '10',
                    left: '20',
                    right: '10',
                    containLabel: true
                },
                color: ['#b1d4d8'],
                series: [
                    {
                        name: '案件数量',
                        type: 'pictorialBar',
                        barCategoryGap: '-40%',
                        // symbol: 'path://M0,10 L10,10 L5,0 L0,10 z',
                        symbol: 'path://M0,10 L10,10 C5.5,10 5.5,5 5,0 C4.5,5 4.5,10 0,10 z',
                        label: {
                            // show: true,
                            position: 'top',
                            // fontSize: '0.12rem',
                            color: '#fff',
                            formatter: '{c} 件'
                        },
                        barWidth: '80%',
                        emphasis: {
                            itemStyle: {
                                opacity: 1
                            }
                        },
                        data: seriesData,
                        z: 10
                    }
                ]
            };
            this.myChart.setOption(option);
        },
        loadCharts2(response) {
            var chartDom = document.getElementById('case_number_ratio_pie2');
            if (!chartDom) {
                return;
            }
            this.myChart = echarts.init(chartDom, null, { renderer: 'svg' });
            let xAxisData = [],
                seriesData = [];
            if (!response.caseRiskNum) {
                return;
            }
            response.caseRiskNum.forEach((val, key) => {
                xAxisData.push(val.name);
                seriesData.push(val.caseNumRatio);
            });
            var option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'none'
                    }
                    // formatter: function (params) {
                    //     let dataIndex = params[0].dataIndex;
                    //     return params[0].name + '<br/>风险案件数量占比：' + params[0].value + '%<br/>风险案件赔款金额：' + response[dataIndex].amountCaseFiled + '元<br/>风险案件数：' + response[dataIndex].caseNum + '件';
                    // }
                },
                xAxis: {
                    data: xAxisData,
                    axisTick: { show: false },
                    // axisLine: { show: false },
                    axisLabel: {
                        color: '#fff',
                        // fontSize: '0.12rem',
                        rotate: 50
                    }
                },
                yAxis: {
                    splitLine: { show: false },
                    axisTick: { show: false },
                    // axisLine: { show: false },
                    axisLabel: { show: false }
                },
                grid: {
                    top: '10',
                    bottom: '10',
                    left: '20',
                    right: '10',
                    containLabel: true
                },
                color: ['#b1d4d8'],
                series: [
                    {
                        name: '风险案件占比',
                        type: 'pictorialBar',
                        barCategoryGap: '-40%',
                        // symbol: 'path://M0,10 L10,10 L5,0 L0,10 z',
                        symbol: 'path://M0,10 L10,10 C5.5,10 5.5,5 5,0 C4.5,5 4.5,10 0,10 z',
                        label: {
                            // show: true,
                            position: 'top',
                            // fontSize: '0.12rem',
                            color: '#fff',
                            formatter: '{c}%'
                        },
                        barWidth: '80%',
                        emphasis: {
                            itemStyle: {
                                opacity: 1
                            }
                        },
                        data: seriesData,
                        z: 10
                    }
                ]
            };
            this.myChart.setOption(option);
        }
    },
    updated() {
        this.myChart && this.myChart.resize();
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.module_wrap {
    width: 100%;
    height: 3.7rem;
    padding: 0.15rem 0.3rem 0;
    /* margin-top: 0.1rem; */
}
.module_wrap2 {
    margin-top: 0.2rem;
}
#case_number_ratio_pie,
#case_number_ratio_pie2 {
    width: 100%;
    height: 90%;
    margin-top: 0.1rem;
}
#case_number_ratio_pie2 {
    /* margin-top: 0.8rem; */
}
</style>
