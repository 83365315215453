<template>
    <div class="wrap">
        <!-- <my-header></my-header> -->
        <div class="file_box">
            <div class="page_title" style="padding: 25px; border-bottom: 1px dashed #d9d9d9">
                <b>包商保费上传</b>
            </div>
            <!-- <el-upload drag accept=".xls,.xlsx" :action="baseURL + '/backstage/importInsureExpenses'" :show-file-list="false" :headers="unloaderHeader" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" multiple>
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
            </el-upload> -->
            <el-upload class="uploads-box" ref="upload" drag accept=".xls,.xlsx" :action="baseURL + '/backstage/importInsureExpenses'" :show-file-list="false" :headers="unloaderHeader" :limit="1" :on-remove="handleRemove" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            </el-upload>
        </div>
    </div>
</template>
<script>
import MyHeader from '@/components/MyHeader.vue';
import { getToken } from '../../utils/auth.js';
export default {
    components: {
        MyHeader
    },
    data() {
        return {
            baseURL: '',
            unloaderHeader: { token: '' } //上传的请求头
        };
    },
    mounted() {
        this.baseURL = this.$store.getters.baseURL;
    },
    methods: {
        //删除文件
        handleRemove(file, fileList) {
            this.caseExcelData = [];
        },
        //文件上传成功时的钩子
        handleAvatarSuccess(res, file) {
            this.fullscreenLoading.close();
            this.fileName = file.name;
            this.uploadFile = res.data;
            if (res.code == 1) {
                this.$message.success('文件导入成功');
                this.caseExcelData = res.data;
            } else {
                this.$message.error(res.message);
            }
            this.$refs['upload'].clearFiles();
        },
        //上传文件之前的钩子
        beforeAvatarUpload(file) {
            // if (file.size / 1024 > 5000) {
            //     this.$message.error('抱歉，上传文件不得大于5M！')
            //     return false
            // }
            this.unloaderHeader.Authorization = 'Bearer ' + getToken();
            this.fullscreenLoading = this.$loading({
                lock: true,
                text: '上传中，请稍后',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
        }
    }
};
</script>
<style scoped>
@import '../../assets/css/table_public.css';
.file_box {
    min-height: 500px;
}
.uploads-box {
    text-align: center;
    margin-top: 10%;
}
</style>