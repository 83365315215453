<template>
    <div class="wrap">
        <div class="task_page">
            <div class="search_box public_box">
                <el-form :inline="true" :model="searchForm" class="demo-form-inline">
                    <el-form-item label="地区：">
                        <el-select class="search-select" v-model="searchForm.regionName" placeholder="请选择">
                            <el-option v-for="item in provinceOption" :key="item" :label="item" :value="item"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="结案时间：" class="dateBox" style="margin-left: 20px">
                        <el-date-picker v-model="datePickerVal" type="monthrange" align="right" unlink-panels range-separator="至" start-placeholder="开始月份" end-placeholder="结束月份" value-format="yyyy-MM-dd" :picker-options="pickerOptions"> </el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="regionsInterface()" style="margin-left: 0.2rem">查询</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <el-tabs class="content-tabs" v-model="tabActive" type="border-card" @tab-click="tabsClick">
                <el-tab-pane label="整体情况" name="first">
                    <div class="regions_content regions_lay">
                        <el-row class="lay-row">
                            <el-col :span="8"
                                >案件共计 <span>{{ caseTotalData.itemValue2 ? caseTotalData.itemValue2 : '--' }}</span> 笔</el-col
                            >
                            <el-col :span="8"
                                >赔付总金额 <span>{{ caseTotalData.itemValue3 ? caseTotalData.itemValue3 : '--' }}</span> 元</el-col
                            >
                            <el-col :span="8"
                                >整体计算案均金额 <span>{{ caseTotalData.itemValue4 ? caseTotalData.itemValue4 : '--' }}</span> 元</el-col
                            >
                        </el-row>
                    </div>
                    <div class="regions_content clearfix">
                        <h3>案件总量情况</h3>
                        <div class="regions-box regions-content_left fl" v-loading="caseTotalRemined.loading">
                            <div id="chart1" class="chart_box" v-show="caseTotalRemined.showChart"></div>
                            <div class="chart_box" v-show="!caseTotalRemined.showChart"><p class="noneData">暂无数据</p></div>
                        </div>
                        <div class="regions-box regions-content_right fr clearfix">
                            <el-tabs v-model="caseTotalRemined.activeName" @tab-click="handleClick(caseTotalRemined)">
                                <el-tab-pane label="异常点" name="first"></el-tab-pane>
                                <el-tab-pane label="分析" name="second"></el-tab-pane>
                                <el-tab-pane label="建议" name="third"></el-tab-pane>
                            </el-tabs>
                            <div class="abnormal fl">
                                <div class="abnormal-txt">
                                    <template v-if="caseTotalRemined.contentList.length > 0">
                                        <template v-for="(item, index) in caseTotalRemined.contentList">
                                            <p v-if="!item.isNo" :key="index" style="margin-top: 5px; line-height: 20px" v-html="item"></p>
                                            <template v-else>
                                                <el-tooltip :key="item.caseNo" v-if="item.caseNo.length > 60" effect="dark" placement="top-start">
                                                    <div slot="content" style="max-width: 400px">{{ item.caseNo }}</div>
                                                    <div style="margin-top: 5px">{{ item.caseNo.slice(0, 60) + '...' }}</div>
                                                </el-tooltip>
                                                <div v-else :key="item" style="margin-top: 5px">{{ item.caseNo }}</div>
                                            </template>
                                        </template>
                                    </template>
                                    <p v-else style="text-align: center">暂无数据</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="regions_content clearfix">
                        <h3>分月案均金额</h3>
                        <div class="regions-box regions-content_left fl" v-loading="monthlyAnalysisRemined.loading">
                            <div id="chart2" class="chart_box" v-show="monthlyAnalysisRemined.showChart"></div>
                            <div class="chart_box" v-show="!monthlyAnalysisRemined.showChart"><p class="noneData">暂无数据</p></div>
                        </div>
                        <div class="regions-box regions-content_right fr clearfix">
                            <el-tabs v-model="monthlyAnalysisRemined.activeName" @tab-click="handleClick(monthlyAnalysisRemined)">
                                <el-tab-pane label="异常点" name="first"></el-tab-pane>
                                <el-tab-pane label="分析" name="second"></el-tab-pane>
                                <el-tab-pane label="建议" name="third"></el-tab-pane>
                            </el-tabs>
                            <div class="abnormal fl">
                                <div class="abnormal-txt">
                                    <template v-if="monthlyAnalysisRemined.contentList.length > 0">
                                        <template v-for="(item, index) in monthlyAnalysisRemined.contentList">
                                            <p v-if="!item.isNo" :key="index" style="margin-top: 5px; line-height: 20px" v-html="item"></p>
                                            <template v-else>
                                                <el-tooltip :key="item.caseNo" v-if="item.caseNo.length > 60" effect="dark" placement="top-start">
                                                    <div slot="content" style="max-width: 400px">{{ item.caseNo }}</div>
                                                    <div style="margin-top: 5px">{{ item.caseNo.slice(0, 60) + '...' }}</div>
                                                </el-tooltip>
                                                <div v-else :key="item" style="margin-top: 5px">{{ item.caseNo }}</div>
                                            </template>
                                        </template>
                                    </template>
                                    <p v-else style="text-align: center">暂无数据</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="regions_content clearfix">
                        <h3>零结案、注销案件月度情况</h3>
                        <div class="regions-box regions-content_left fl" v-loading="monthlyCancel.loading">
                            <div id="chart3" class="chart_box" v-show="monthlyCancel.showChart"></div>
                            <div class="chart_box" v-show="!monthlyCancel.showChart"><p class="noneData">暂无数据</p></div>
                        </div>
                        <div class="regions-box regions-content_right fr clearfix">
                            <el-tabs v-model="monthlyCancel.activeName" @tab-click="handleClick(monthlyCancel)">
                                <el-tab-pane label="异常点" name="first"></el-tab-pane>
                                <el-tab-pane label="分析" name="second"></el-tab-pane>
                                <el-tab-pane label="建议" name="third"></el-tab-pane>
                            </el-tabs>
                            <div class="abnormal fl">
                                <div class="abnormal-txt">
                                    <template v-if="monthlyCancel.contentList.length > 0">
                                        <template v-for="(item, index) in monthlyCancel.contentList">
                                            <p v-if="!item.isNo" :key="index" style="margin-top: 5px; line-height: 20px" v-html="item"></p>
                                            <template v-else>
                                                <el-tooltip :key="item.caseNo" v-if="item.caseNo.length > 60" effect="dark" placement="top-start">
                                                    <div slot="content" style="max-width: 400px">{{ item.caseNo }}</div>
                                                    <div style="margin-top: 5px">{{ item.caseNo.slice(0, 60) + '...' }}</div>
                                                </el-tooltip>
                                                <div v-else :key="item" style="margin-top: 5px">{{ item.caseNo }}</div>
                                            </template>
                                        </template>
                                    </template>
                                    <p v-else style="text-align: center">暂无数据</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="regions_content clearfix">
                        <h3>月度案件数量（案件金额高于5000、低于5000）</h3>
                        <div class="regions-box regions-content_left fl" v-loading="monthlyAnalysisRemind.loading">
                            <div id="chart4" class="chart_box" v-show="monthlyAnalysisRemind.showChart"></div>
                            <div class="chart_box" v-show="!monthlyAnalysisRemind.showChart"><p class="noneData">暂无数据</p></div>
                        </div>
                        <div class="regions-box regions-content_right fr clearfix">
                            <el-tabs v-model="monthlyAnalysisRemind.activeName" @tab-click="handleClick(monthlyAnalysisRemind)">
                                <el-tab-pane label="异常点" name="first"></el-tab-pane>
                                <el-tab-pane label="分析" name="second"></el-tab-pane>
                                <el-tab-pane label="建议" name="third"></el-tab-pane>
                            </el-tabs>
                            <div class="abnormal fl">
                                <div class="abnormal-txt">
                                    <template v-if="monthlyAnalysisRemind.contentList.length > 0">
                                        <template v-for="(item, index) in monthlyAnalysisRemind.contentList">
                                            <p v-if="!item.isNo" :key="index" style="margin-top: 5px; line-height: 20px" v-html="item"></p>
                                            <template v-else>
                                                <el-tooltip :key="item.caseNo" v-if="item.caseNo.length > 60" effect="dark" placement="top-start">
                                                    <div slot="content" style="max-width: 400px">{{ item.caseNo }}</div>
                                                    <div style="margin-top: 5px">{{ item.caseNo.slice(0, 60) + '...' }}</div>
                                                </el-tooltip>
                                                <div v-else :key="item" style="margin-top: 5px">{{ item.caseNo }}</div>
                                            </template>
                                        </template>
                                    </template>
                                    <p v-else style="text-align: center">暂无数据</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="区域" name="second">
                    <div class="regions_content clearfix">
                        <h3 style="padding-left: 16px; margin-top: 10px">各市案件量、总赔款</h3>
                        <div class="regions-box regions-content_left fl" v-loading="cityAnalysisRemind.loading">
                            <div id="chart5" class="chart_box" style="height: 650px; min-height: 500px" v-show="cityAnalysisRemind.showChart"></div>
                            <div class="chart_box" v-show="!cityAnalysisRemind.showChart"><p class="noneData">暂无数据</p></div>
                        </div>
                        <div class="regions-box regions-content_right fr clearfix">
                            <el-tabs v-model="cityAnalysisRemind.activeName" @tab-click="handleClick(cityAnalysisRemind)">
                                <el-tab-pane label="异常点" name="first"></el-tab-pane>
                                <el-tab-pane label="分析" name="second"></el-tab-pane>
                                <el-tab-pane label="建议" name="third"></el-tab-pane>
                            </el-tabs>
                            <div class="abnormal fl">
                                <div class="abnormal-txt">
                                    <template v-if="cityAnalysisRemind.contentList.length > 0">
                                        <template v-for="(item, index) in cityAnalysisRemind.contentList">
                                            <p v-if="!item.isNo" :key="index" style="margin-top: 5px; line-height: 20px" v-html="item"></p>
                                            <template v-else>
                                                <el-tooltip :key="item.caseNo" v-if="item.caseNo.length > 60" effect="dark" placement="top-start">
                                                    <div slot="content" style="max-width: 400px">{{ item.caseNo }}</div>
                                                    <div style="margin-top: 5px">{{ item.caseNo.slice(0, 60) + '...' }}</div>
                                                </el-tooltip>
                                                <div v-else :key="item" style="margin-top: 5px">{{ item.caseNo }}</div>
                                            </template>
                                        </template>
                                    </template>
                                    <p v-else style="text-align: center">暂无数据</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="regions_content clearfix">
                        <h3>案均赔款量</h3>
                        <div class="regions-box regions-content_left fl" v-loading="cityAnalysisAvgRemind.loading">
                            <div id="chart6" class="chart_box" style="height: 650px; min-height: 500px" v-show="cityAnalysisAvgRemind.showChart"></div>
                            <div class="chart_box" v-show="!cityAnalysisAvgRemind.showChart"><p class="noneData">暂无数据</p></div>
                        </div>
                        <div class="regions-box regions-content_right fr clearfix">
                            <el-tabs v-model="cityAnalysisAvgRemind.activeName" @tab-click="handleClick(cityAnalysisAvgRemind)">
                                <el-tab-pane label="异常点" name="first"></el-tab-pane>
                                <el-tab-pane label="分析" name="second"></el-tab-pane>
                                <el-tab-pane label="建议" name="third"></el-tab-pane>
                            </el-tabs>
                            <div class="abnormal fl">
                                <div class="abnormal-txt">
                                    <template v-if="cityAnalysisAvgRemind.contentList.length > 0">
                                        <template v-for="(item, index) in cityAnalysisAvgRemind.contentList">
                                            <p v-if="!item.isNo" :key="index" style="margin-top: 5px; line-height: 20px" v-html="item"></p>
                                            <template v-else>
                                                <el-tooltip :key="item.caseNo" v-if="item.caseNo.length > 60" effect="dark" placement="top-start">
                                                    <div slot="content" style="max-width: 400px">{{ item.caseNo }}</div>
                                                    <div style="margin-top: 5px">{{ item.caseNo.slice(0, 60) + '...' }}</div>
                                                </el-tooltip>
                                                <div v-else :key="item" style="margin-top: 5px">{{ item.caseNo }}</div>
                                            </template>
                                        </template>
                                    </template>
                                    <p v-else style="text-align: center">暂无数据</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="regions_content clearfix">
                        <h3>城市案件量Top3</h3>
                        <div class="regions-box regions-content_left fl" v-loading="cityTopReportRemind1.loading">
                            <div id="chart7" class="chart_box" v-show="cityTopReportRemind1.showChart"></div>
                            <div class="chart_box" v-show="!cityTopReportRemind1.showChart"><p class="noneData">暂无数据</p></div>
                        </div>
                        <div class="regions-box regions-content_right fr clearfix">
                            <el-tabs v-model="cityTopReportRemind1.activeName" @tab-click="handleClick(cityTopReportRemind1)">
                                <el-tab-pane label="异常点" name="first"></el-tab-pane>
                                <el-tab-pane label="分析" name="second"></el-tab-pane>
                                <el-tab-pane label="建议" name="third"></el-tab-pane>
                            </el-tabs>
                            <div class="abnormal fl">
                                <div class="abnormal-txt">
                                    <template v-if="cityTopReportRemind1.contentList.length > 0">
                                        <template v-for="(item, index) in cityTopReportRemind1.contentList">
                                            <p v-if="!item.isNo" :key="index" style="margin-top: 5px; line-height: 20px" v-html="item"></p>
                                            <template v-else>
                                                <el-tooltip :key="item.caseNo" v-if="item.caseNo.length > 60" effect="dark" placement="top-start">
                                                    <div slot="content" style="max-width: 400px">{{ item.caseNo }}</div>
                                                    <div style="margin-top: 5px">{{ item.caseNo.slice(0, 60) + '...' }}</div>
                                                </el-tooltip>
                                                <div v-else :key="item" style="margin-top: 5px">{{ item.caseNo }}</div>
                                            </template>
                                        </template>
                                    </template>
                                    <p v-else style="text-align: center">暂无数据</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="regions_content clearfix">
                        <h3>城市总赔款Top3</h3>
                        <div class="regions-box regions-content_left fl" v-loading="cityTopReportRemind2.loading">
                            <div id="chart8" class="chart_box" v-show="cityTopReportRemind2.showChart"></div>
                            <div class="chart_box" v-show="!cityTopReportRemind2.showChart"><p class="noneData">暂无数据</p></div>
                        </div>
                        <div class="regions-box regions-content_right fr clearfix">
                            <el-tabs v-model="cityTopReportRemind2.activeName" @tab-click="handleClick(cityTopReportRemind2)">
                                <el-tab-pane label="异常点" name="first"></el-tab-pane>
                                <el-tab-pane label="分析" name="second"></el-tab-pane>
                                <el-tab-pane label="建议" name="third"></el-tab-pane>
                            </el-tabs>
                            <div class="abnormal fl">
                                <div class="abnormal-txt">
                                    <template v-if="cityTopReportRemind2.contentList.length > 0">
                                        <template v-for="(item, index) in cityTopReportRemind2.contentList">
                                            <p v-if="!item.isNo" :key="index" style="margin-top: 5px; line-height: 20px" v-html="item"></p>
                                            <template v-else>
                                                <el-tooltip :key="item.caseNo" v-if="item.caseNo.length > 60" effect="dark" placement="top-start">
                                                    <div slot="content" style="max-width: 400px">{{ item.caseNo }}</div>
                                                    <div style="margin-top: 5px">{{ item.caseNo.slice(0, 60) + '...' }}</div>
                                                </el-tooltip>
                                                <div v-else :key="item" style="margin-top: 5px">{{ item.caseNo }}</div>
                                            </template>
                                        </template>
                                    </template>
                                    <p v-else style="text-align: center">暂无数据</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="regions_content clearfix">
                        <h3>城市案均赔款Top3</h3>
                        <div class="regions-box regions-content_left fl" v-loading="cityTopReportRemind3.loading">
                            <div id="chart9" class="chart_box" v-show="cityTopReportRemind3.showChart"></div>
                            <div class="chart_box" v-show="!cityTopReportRemind3.showChart"><p class="noneData">暂无数据</p></div>
                        </div>
                        <div class="regions-box regions-content_right fr clearfix">
                            <el-tabs v-model="cityTopReportRemind3.activeName" @tab-click="handleClick(cityTopReportRemind3)">
                                <el-tab-pane label="异常点" name="first"></el-tab-pane>
                                <el-tab-pane label="分析" name="second"></el-tab-pane>
                                <el-tab-pane label="建议" name="third"></el-tab-pane>
                            </el-tabs>
                            <div class="abnormal fl">
                                <div class="abnormal-txt">
                                    <template v-if="cityTopReportRemind3.contentList.length > 0">
                                        <template v-for="(item, index) in cityTopReportRemind3.contentList">
                                            <p v-if="!item.isNo" :key="index" style="margin-top: 5px; line-height: 20px" v-html="item"></p>
                                            <template v-else>
                                                <el-tooltip :key="item.caseNo" v-if="item.caseNo.length > 60" effect="dark" placement="top-start">
                                                    <div slot="content" style="max-width: 400px">{{ item.caseNo }}</div>
                                                    <div style="margin-top: 5px">{{ item.caseNo.slice(0, 60) + '...' }}</div>
                                                </el-tooltip>
                                                <div v-else :key="item" style="margin-top: 5px">{{ item.caseNo }}</div>
                                            </template>
                                        </template>
                                    </template>
                                    <p v-else style="text-align: center">暂无数据</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="长尾效应" name="third">
                    <div class="regions_content clearfix">
                        <div class="regions-box regions-content_left fl" v-loading="chart16Loading">
                            <div id="chart16" class="chart_box" v-show="chart16Remind.showChart"></div>
                            <div class="chart_box" v-show="!chart16Remind.showChart"><p class="noneData">暂无数据</p></div>
                        </div>
                        <div class="regions-box regions-content_right fr clearfix">
                            <h3 style="margin-top: 10px; padding-left: 0">长尾效应-计算长尾时间</h3>
                            <div class="content-excel fl">
                                <el-row>
                                    <el-col :span="13">计算长尾时间</el-col>
                                    <el-col :span="7" :offset="2">
                                        <el-select v-model="excelDate" placeholder="请选择" size="mini">
                                            <el-option v-for="item in excelOptions" :key="item" :label="item" :value="item"> </el-option>
                                        </el-select>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="13">案件量</el-col>

                                    <el-col :span="7" :offset="2">{{ tmpContentExcel[excelDate] ? tmpContentExcel[excelDate].caseNum : 0 }}</el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="13">赔付总金额</el-col>
                                    <el-col :span="7" :offset="2">{{ tmpContentExcel[excelDate] ? tmpContentExcel[excelDate].totalPayAmount2 : 0 }} 万</el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="13">案均赔付金额</el-col>
                                    <el-col :span="7" :offset="2">{{ tmpContentExcel[excelDate] ? tmpContentExcel[excelDate].avgPayAmount : 0 }} 元</el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="13">出险时间与首次赔付时间平均值</el-col>
                                    <el-col :span="7" :offset="2">{{ tmpContentExcel[excelDate] ? tmpContentExcel[excelDate].accDiffFirstPayTime : 0 }} 天</el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="13">出险时间与末次赔付时间平均值</el-col>
                                    <el-col :span="7" :offset="2">{{ tmpContentExcel[excelDate] ? tmpContentExcel[excelDate].accDiffLastPayTime : 0 }} 天</el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="13">首次赔付时间与末次赔付时间平均值</el-col>
                                    <el-col :span="7" :offset="2">{{ tmpContentExcel[excelDate] ? tmpContentExcel[excelDate].firstDiffLastPayTime : 0 }} 天</el-col>
                                </el-row>
                            </div>
                        </div>
                    </div>
                    <div class="regions_content clearfix">
                        <h3>大额赔偿案件</h3>
                        <div class="regions-box regions-content_left fl" v-loading="bigAmountCaseRemind.loading">
                            <div id="chart15" class="chart_box" v-show="bigAmountCaseRemind.showChart"></div>
                            <div class="chart_box" v-show="!bigAmountCaseRemind.showChart"><p class="noneData">暂无数据</p></div>
                        </div>
                        <div class="regions-box regions-content_right fr clearfix">
                            <el-tabs v-model="bigAmountCaseRemind.activeName" @tab-click="handleClick(bigAmountCaseRemind)">
                                <el-tab-pane label="异常点" name="first"></el-tab-pane>
                                <el-tab-pane label="分析" name="second"></el-tab-pane>
                                <el-tab-pane label="建议" name="third"></el-tab-pane>
                            </el-tabs>
                            <div class="abnormal fl">
                                <div class="abnormal-txt">
                                    <template v-if="bigAmountCaseRemind.contentList.length > 0">
                                        <template v-for="(item, index) in bigAmountCaseRemind.contentList">
                                            <p v-if="!item.isNo" :key="index" style="margin-top: 5px; line-height: 20px" v-html="item"></p>
                                            <template v-else>
                                                <el-tooltip :key="item.caseNo" v-if="item.caseNo.length > 60" effect="dark" placement="top-start">
                                                    <div slot="content" style="max-width: 400px">{{ item.caseNo }}</div>
                                                    <div style="margin-top: 5px">{{ item.caseNo.slice(0, 60) + '...' }}</div>
                                                </el-tooltip>
                                                <div v-else :key="item" style="margin-top: 5px">{{ item.caseNo }}</div>
                                            </template>
                                        </template>
                                    </template>
                                    <p v-else style="text-align: center">暂无数据</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="重点骑手" name="fourth">
                    <div class="regions_content clearfix">
                        <div class="regions-box regions-content_left fl" v-loading="keyRidersRemind.loading">
                            <el-table :data="keyRidersData" stripe style="width: 100%">
                                <el-table-column type="index" width="50"> </el-table-column>
                                <el-table-column prop="itemName" label="骑手ID" width="180"></el-table-column>
                                <el-table-column prop="itemValue3" label="合计赔付金额"> </el-table-column>
                                <el-table-column prop="itemValue1" label="报案数"> </el-table-column>
                                <el-table-column prop="itemValue2" label="赔付案件数"> </el-table-column>
                            </el-table>
                        </div>
                        <div class="regions-box regions-content_right fr clearfix">
                            <el-tabs v-model="keyRidersRemind.activeName" @tab-click="handleClick(keyRidersRemind)">
                                <el-tab-pane label="异常点" name="first"></el-tab-pane>
                                <el-tab-pane label="分析" name="second"></el-tab-pane>
                                <el-tab-pane label="建议" name="third"></el-tab-pane>
                            </el-tabs>
                            <div class="abnormal fl">
                                <div class="abnormal-txt">
                                    <template v-if="keyRidersRemind.contentList.length > 0">
                                        <template v-for="(item, index) in keyRidersRemind.contentList">
                                            <p v-if="!item.isNo" :key="index" style="margin-top: 5px; line-height: 20px" v-html="item"></p>
                                            <template v-else>
                                                <el-tooltip :key="item.caseNo" v-if="item.caseNo.length > 60" effect="dark" placement="top-start">
                                                    <div slot="content" style="max-width: 400px">{{ item.caseNo }}</div>
                                                    <div style="margin-top: 5px">{{ item.caseNo.slice(0, 60) + '...' }}</div>
                                                </el-tooltip>
                                                <div v-else :key="item" style="margin-top: 5px">{{ item.caseNo }}</div>
                                            </template>
                                        </template>
                                    </template>
                                    <p v-else style="text-align: center">暂无数据</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="重点包商" name="fifth">
                    <div class="regions-box regions-content_left fl">
                        <el-table :data="keyContractorsData" stripe style="width: 100%" v-loading="keyContractorsRemind.loading">
                            <el-table-column type="index" width="50"> </el-table-column>
                            <el-table-column prop="itemName" label="包商名称" width="190"></el-table-column>
                            <el-table-column prop="itemValue3" label="合计赔付金额"></el-table-column>
                            <el-table-column prop="itemValue1" label="报案数"></el-table-column>
                            <el-table-column prop="itemValue2" label="赔付案件数"></el-table-column>
                            <el-table-column prop="itemValue4" label="件均赔款"></el-table-column>
                        </el-table>
                    </div>
                    <div class="regions-box regions-content_right fr clearfix">
                        <el-tabs v-model="keyContractorsRemind.activeName" @tab-click="handleClick(keyContractorsRemind)">
                            <el-tab-pane label="异常点" name="first"></el-tab-pane>
                            <el-tab-pane label="分析" name="second"></el-tab-pane>
                            <el-tab-pane label="建议" name="third"></el-tab-pane>
                        </el-tabs>
                        <div class="abnormal fl">
                            <div class="abnormal-txt" style="max-height: 500px">
                                <template v-if="keyContractorsRemind.contentList.length > 0">
                                    <p v-for="(item, index) in keyContractorsRemind.contentList" :key="index" style="margin-top: 5px; line-height: 20px" v-html="item"></p>
                                </template>
                                <p v-else style="text-align: center">暂无数据</p>
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>
<script>
import * as echarts from 'echarts';
export default {
    data() {
        return {
            searchForm: {
                endCaseCloseTime: '',
                insuranceType: 1,
                regionName: '河北省',
                startCaseCloseTime: ''
            },
            caseTotalData: {},
            keyRidersData: [],
            keyContractorsData: [],
            provinceOption: [],
            caseTotalRemined: {
                activeName: 'first',
                contentList: [],
                errorList: [],
                abnormal: [],
                analysis: [],
                advocate: [],
                activeTxt: '',
                showChart: false,
                loading: true
            },
            monthlyAnalysisRemined: {
                activeName: 'first',
                contentList: [],
                errorList: [],
                abnormal: [],
                analysis: [],
                advocate: [],
                activeTxt: '',
                showChart: false
            },
            monthlyCancel: {
                activeName: 'first',
                contentList: [],
                errorList: [],
                abnormal: [],
                analysis: [],
                advocate: [],
                activeTxt: '',
                showChart: false
            },
            monthlyAnalysisRemind: {
                activeName: 'first',
                contentList: [],
                errorList: [],
                abnormal: [],
                analysis: [],
                advocate: [],
                activeTxt: '',
                showChart: false
            },
            cityAnalysisRemind: {
                activeName: 'first',
                contentList: [],
                errorList: [],
                abnormal: [],
                analysis: [],
                advocate: [],
                activeTxt: '',
                showChart: false
            },
            cityAnalysisAvgRemind: {
                activeName: 'first',
                contentList: [],
                errorList: [],
                abnormal: [],
                analysis: [],
                advocate: [],
                activeTxt: '',
                showChart: false
            },
            cityTopReportRemind1: {
                activeName: 'first',
                contentList: [],
                errorList: [],
                abnormal: [],
                analysis: [],
                advocate: [],
                activeTxt: '',
                showChart: false
            },
            cityTopReportRemind2: {
                activeName: 'first',
                contentList: [],
                errorList: [],
                abnormal: [],
                analysis: [],
                advocate: [],
                activeTxt: '',
                showChart: false
            },
            cityTopReportRemind3: {
                activeName: 'first',
                contentList: [],
                errorList: [],
                abnormal: [],
                analysis: [],
                advocate: [],
                activeTxt: '',
                showChart: false
            },
            bigAmountCaseRemind: {
                activeName: 'first',
                contentList: [],
                errorList: [],
                abnormal: [],
                analysis: [],
                advocate: [],
                activeTxt: '',
                showChart: false
            },
            keyRidersRemind: {
                activeName: 'first',
                contentList: [],
                errorList: [],
                abnormal: [],
                analysis: [],
                advocate: [],
                activeTxt: '',
                showChart: false
            },
            keyContractorsRemind: {
                activeName: 'first',
                contentList: [],
                errorList: [],
                abnormal: [],
                analysis: [],
                advocate: [],
                activeTxt: '',
                showChart: false
            },
            chart16Remind: {
                activeName: 'first',
                contentList: [],
                errorList: [],
                abnormal: [],
                analysis: [],
                advocate: [],
                activeTxt: '',
                showChart: false
            },
            datePickerVal: '',
            tabActive: 'first',
            contentExcel: {},
            tmpContentExcel: {},
            excelOptions: [],
            excelDate: '',
            chart16Loading: true,
            pickerOptions: {
                shortcuts: [
                    {
                        text: '本月',
                        onClick(picker) {
                            picker.$emit('pick', [new Date(), new Date()]);
                        }
                    },
                    {
                        text: '今年至今',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date(new Date().getFullYear(), 0);
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: '最近六个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setMonth(start.getMonth() - 6);
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: '最近一年',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setMonth(start.getMonth() - 12);
                            picker.$emit('pick', [start, end]);
                        }
                    }
                ]
            },
            chart1: null,
            chart2: null,
            chart3: null,
            chart4: null,
            chart5: null,
            chart6: null,
            chart7: null,
            chart8: null,
            chart9: null,
            chart10: null,
            chart15: null,
            chart15: null
        };
    },
    mounted() {
        // 默认最近一年
        let end = new Date();
        let start = new Date();
        start.setMonth(start.getMonth() - 12);
        start = this.getNowFormatDate(start);
        end = this.getNowFormatDate(end);
        this.datePickerVal = [start, end];
        // this.datePickerVal = ['2022-01-01', '2023-01-01']

        this.getProvince();
        this.regionsInterface();

        let _this = this;
        window.addEventListener('resize', function () {
            _this.chart1 && _this.chart1.resize();
            _this.chart2 && _this.chart2.resize();
            _this.chart3 && _this.chart3.resize();
            _this.chart4 && _this.chart4.resize();
            _this.chart5 && _this.chart5.resize();
            _this.chart6 && _this.chart6.resize();
            _this.chart7 && _this.chart7.resize();
            _this.chart8 && _this.chart8.resize();
            _this.chart9 && _this.chart9.resize();
            _this.chart16 && _this.chart16.resize();
            _this.chart10 && _this.chart10.resize();
            _this.chart15 && _this.chart15.resize();
        });
    },
    methods: {
        getProvince() {
            this.instance.post('/regionalAnalysis/searchProvince', this.searchForm).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.provinceOption = data.data;
                }
            });
        },
        regionsInterface() {
            if (this.datePickerVal.length > 0) {
                this.searchForm.startCaseCloseTime = this.datePickerVal[0];
                let tmpEnd = this.datePickerVal[1];
                let tmpLastDate = this.getLastDate(this.datePickerVal[1]);
                let tmpEndDate = tmpEnd.slice(0, 8);
                this.searchForm.endCaseCloseTime = tmpEndDate + tmpLastDate;
            }
            // 加载loading效果
            // 整体情况
            this.caseTotalRemined.loading = true;
            this.monthlyAnalysisRemined.loading = true;
            this.monthlyCancel.loading = true;
            this.monthlyAnalysisRemind.loading = true;
            // 区域
            this.cityAnalysisRemind.loading = true;
            this.cityAnalysisAvgRemind.loading = true;
            this.cityTopReportRemind1.loading = true;
            this.cityTopReportRemind2.loading = true;
            this.cityTopReportRemind3.loading = true;
            // 长尾效应
            // 各市案件量、总赔款 和整体情况共用一个接口
            this.bigAmountCaseRemind.loading = true;
            // 重点骑手
            this.keyRidersRemind.loading = true;
            // 重点包商
            this.keyContractorsRemind.loading = true;

            // 请求图表接口
            this.getCaseTotalCount();
            this.caseTotalMonthlyAnalysis();
            this.monthlyCancelAnalysis();
            this.monthlyAnalysis();
            this.cityAnalysis();
            this.cityTopReport();
            this.cityTopTotal();
            this.cityTopAvg();
            this.cityAnalysisAvg();
            // 长尾效应
            this.longTailCase();
            this.bigAmountCase();

            // 重点/骑手
            this.keyRiders();
            // 重点包商
            this.keyContractors();
        },
        // 案件总量情况
        getCaseTotalCount() {
            this.instance.post('/regionalAnalysis/caseTotalCount', this.searchForm).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.caseTotalData = data.data.base;
                    if (data.data.errorList && data.data.errorList.length > 0) {
                        this.caseTotalCountError(data.data.errorList);
                    } else {
                        this.caseTotalRemined.analysis = [];
                        this.caseTotalRemined.advocate = [];
                    }

                    if (data.data.itemList.length > 0) {
                        this.caseTotalChart(data.data.itemList);
                        this.caseTotalRemined.errorList = data.data.errorList;
                        this.handleContent(this.caseTotalRemined);
                        this.caseTotalRemined.showChart = true;
                    } else {
                        this.caseTotalRemined.showChart = false;
                    }
                }
                this.caseTotalRemined.loading = false;
            });
        },
        // 案均总量情况 - 异常点分析
        caseTotalCountError(errorList) {
            this.searchForm.errorList = errorList;
            this.instance.post('/regionalAnalysis/caseTotalCountError', this.searchForm).then((res) => {
                let data = res.data;
                if (data.code == 1 && data.data) {
                    this.caseTotalRemined.analysisVos = data.data.analysisVos;
                    this.handleAnalysisVos(this.caseTotalRemined);
                }
            });
        },
        // 案件总量情况 - 柱状图
        caseTotalChart(dataList) {
            let legendData = ['报案量', '结案量'];
            let xAxisData = [];
            let reportCase = [];
            let closeCase = [];
            dataList.forEach((item) => {
                xAxisData.push(item.itemName);
                reportCase.push(item.itemValue1);
                closeCase.push(item.itemValue2);
            });

            let option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999'
                        }
                    }
                },
                legend: {
                    data: legendData
                },
                xAxis: [
                    {
                        type: 'category',
                        data: xAxisData,
                        axisPointer: {
                            type: 'shadow'
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        axisLabel: {
                            formatter: '{value}'
                        }
                    }
                ],
                series: [
                    {
                        name: '报案量',
                        type: 'bar',
                        label: {
                            show: true,
                            position: 'top'
                        },
                        tooltip: {
                            valueFormatter: function (value) {
                                return value;
                            }
                        },
                        data: reportCase
                    },
                    {
                        name: '结案量',
                        type: 'bar',
                        label: {
                            show: true,
                            position: 'top'
                        },
                        tooltip: {
                            valueFormatter: function (value) {
                                return value;
                            }
                        },
                        data: closeCase
                    }
                ]
            };
            let tmpDom = document.getElementById('chart1');
            if (tmpDom) {
                this.chart1 = echarts.init(tmpDom);
                this.chart1.setOption(option);
                setTimeout(() => {
                    this.chart1.resize();
                }, 30);
            }
        },
        // 分月案均金额
        caseTotalMonthlyAnalysis() {
            this.instance.post('/regionalAnalysis/caseTotalMonthlyAnalysis', this.searchForm).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    if (data.data.errorList && data.data.errorList.length > 0) {
                        this.caseTotalMonthlyAnalysisError(data.data.errorList);
                    } else {
                        this.monthlyAnalysisRemined.analysis = [];
                        this.monthlyAnalysisRemined.advocate = [];
                    }
                    if (data.data.itemList.length > 0) {
                        this.monthlyAnalysisChart(data.data.itemList);
                        this.monthlyAnalysisRemined.errorList = data.data.errorList;
                        this.handleContent(this.monthlyAnalysisRemined);
                        this.monthlyAnalysisRemined.showChart = true;
                    } else {
                        this.monthlyAnalysisRemined.showChart = false;
                    }
                }
                this.monthlyAnalysisRemined.loading = false;
            });
        },
        // 分月案均金额 - 异常点分析
        caseTotalMonthlyAnalysisError(errorList) {
            this.searchForm.errorList = errorList;
            this.instance.post('/regionalAnalysis/caseTotalMonthlyAnalysisError', this.searchForm).then((res) => {
                let data = res.data;
                if (data.code == 1 && data.data) {
                    this.monthlyAnalysisRemined.analysisVos = data.data.analysisVos;
                    this.handleAnalysisVos(this.monthlyAnalysisRemined);
                }
            });
        },
        // 分月案均金额 - 折柱图
        monthlyAnalysisChart(dataList) {
            let legendData = ['案件金额(元)', '案均金额(元)'];
            let xAxisData = [];
            let data1 = [];
            let data2 = [];
            let data3 = [];
            let data4 = [];
            dataList.forEach((item) => {
                xAxisData.push(item.itemName);
                data1.push(item.itemValue3);
                data2.push(item.itemValue1);
                data3.push(item.itemValue2);
                data4.push(item.itemValue4);
            });

            let option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999'
                        }
                    }
                },
                legend: {
                    data: legendData
                },
                xAxis: [
                    {
                        type: 'category',
                        data: xAxisData,
                        axisPointer: {
                            type: 'shadow'
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '案件金额'
                    },
                    {
                        type: 'value',
                        name: '案均金额',
                        splitLine: { show: false }
                    }
                ],
                series: [
                    {
                        name: '案件金额(元)',
                        type: 'bar',
                        label: {
                            show: true,
                            position: 'top'
                        },
                        tooltip: {
                            valueFormatter: function (value) {
                                return value;
                            }
                        },
                        data: data1
                    },
                    {
                        name: '案均金额(元)',
                        type: 'line',
                        yAxisIndex: 1,
                        tooltip: {
                            valueFormatter: function (value) {
                                return value;
                            }
                        },
                        data: data4
                    }
                ]
            };

            let tmpDom = document.getElementById('chart2');
            if (tmpDom) {
                this.chart2 = echarts.init(tmpDom);
                this.chart2.setOption(option);
                setTimeout(() => {
                    this.chart2.resize();
                }, 30);
            }
        },
        // 零结案、注销案件月度情况
        monthlyCancelAnalysis() {
            this.instance.post('/regionalAnalysis/monthlyCancelAnalysis', this.searchForm).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    if (data.data.itemList.length > 0) {
                        this.monthlyCancelAnalysisChart(data.data.itemList);
                        this.monthlyCancel.errorList = data.data.errorList;
                        this.handleContent(this.monthlyCancel);
                        this.monthlyCancel.showChart = true;
                    } else {
                        this.monthlyCancel.showChart = false;
                    }
                }
                this.monthlyCancel.loading = false;
            });
        },
        // 零结案、注销案件月度情况 - 柱状图
        monthlyCancelAnalysisChart(dataList) {
            let legendData = ['零结案', '注销案件'];
            let xAxisData = [];
            let data1 = [];
            let data2 = [];
            dataList.forEach((item) => {
                xAxisData.push(item.itemName);
                data1.push(item.itemValue1);
                data2.push(item.itemValue2);
            });

            let option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999'
                        }
                    }
                },
                legend: {
                    data: legendData
                },
                xAxis: [
                    {
                        type: 'category',
                        data: xAxisData,
                        axisPointer: {
                            type: 'shadow'
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        axisLabel: {
                            formatter: '{value}'
                        }
                    }
                ],
                series: [
                    {
                        name: '零结案',
                        type: 'bar',
                        label: {
                            show: true,
                            position: 'top'
                        },
                        tooltip: {
                            valueFormatter: function (value) {
                                return value;
                            }
                        },
                        data: data1
                    },
                    {
                        name: '注销案件',
                        type: 'bar',
                        label: {
                            show: true,
                            position: 'top'
                        },
                        tooltip: {
                            valueFormatter: function (value) {
                                return value;
                            }
                        },
                        data: data2
                    }
                ]
            };
            let tmpDom = document.getElementById('chart3');
            if (tmpDom) {
                this.chart3 = echarts.init(tmpDom);
                this.chart3.setOption(option);
                setTimeout(() => {
                    this.chart3.resize();
                }, 30);
            }
        },
        // 月度案件数量（案件金额高于5000、低于5000）
        monthlyAnalysis() {
            this.instance.post('/regionalAnalysis/monthlyAnalysis', this.searchForm).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    if (data.data.errorList && data.data.errorList.length > 0) {
                        this.monthlyAnalysisError(data.data.errorList);
                    } else {
                        this.monthlyAnalysisRemind.analysis = [];
                        this.monthlyAnalysisRemind.advocate = [];
                    }
                    if (data.data.itemList.length > 0) {
                        this.monthlyAnalysis5000Chart(data.data.itemList);
                        this.monthlyAnalysisRemind.errorList = data.data.errorList;
                        this.handleContent(this.monthlyAnalysisRemind);
                        this.monthlyAnalysisRemind.showChart = true;
                    } else {
                        this.monthlyAnalysisRemind.showChart = false;
                    }
                }
                this.monthlyAnalysisRemind.loading = false;
            });
        },
        // 月度案件数量（案件金额高于5000、低于5000） - 异常点分析
        monthlyAnalysisError(errorList) {
            this.searchForm.errorList = errorList;
            this.instance.post('/regionalAnalysis/monthlyAnalysisError', this.searchForm).then((res) => {
                let data = res.data;
                if (data.code == 1 && data.data) {
                    this.monthlyAnalysisRemind.analysisVos = data.data.analysisVos;
                    this.handleAnalysisVos(this.monthlyAnalysisRemind);
                }
            });
        },
        monthlyAnalysis5000Chart(dataList) {
            let legendData = ['低于5000', '高于5000'];
            let xAxisData = [];
            let data1 = [];
            let data2 = [];
            dataList.forEach((item) => {
                xAxisData.push(item.itemName);
                data1.push(item.itemValue1);
                data2.push(item.itemValue2);
            });

            let option = {
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    data: legendData
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: false,
                        data: xAxisData
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '低于5000'
                    },
                    {
                        type: 'value',
                        name: '高于5000',
                        splitLine: { show: false },
                        minInterval: 1,
                        min: 1,
                        max: () => {
                            if (data2.length > 0) {
                                return Math.max(...data2);
                            } else {
                                return 1;
                            }
                        }
                    }
                ],
                series: [
                    {
                        name: '低于5000',
                        type: 'line',
                        stack: 'Total',
                        areaStyle: {},
                        emphasis: {
                            focus: 'series'
                        },
                        data: data1
                    },
                    {
                        name: '高于5000',
                        type: 'line',
                        stack: 'Total',
                        areaStyle: {},
                        emphasis: {
                            focus: 'series'
                        },
                        data: data2
                    }
                ]
            };
            let tmpDom = document.getElementById('chart4');
            if (tmpDom) {
                this.chart4 = echarts.init(tmpDom);
                this.chart4.setOption(option);
                setTimeout(() => {
                    this.chart4.resize();
                }, 30);
            }
        },
        // 各市案件量、总赔款 - 区域
        cityAnalysis() {
            this.instance.post('/regionalAnalysis/cityAnalysis', this.searchForm).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    if (data.data.errorList && data.data.errorList.length > 0) {
                        this.cityAnalysisError(data.data.errorList);
                    } else {
                        this.cityAnalysisRemind.analysis = [];
                        this.cityAnalysisRemind.advocate = [];
                    }
                    if (data.data.itemList.length > 0) {
                        this.cityAnalysisChart(data.data.itemList);
                        this.cityAnalysisRemind.errorList = data.data.errorList;
                        this.handleContent(this.cityAnalysisRemind);
                        this.cityAnalysisRemind.showChart = true;
                    } else {
                        this.cityAnalysisRemind.showChart = false;
                    }
                }
                this.cityAnalysisRemind.loading = false;
            });
        },
        // 各市案件量、总赔款 - 异常点分析 - 区域
        cityAnalysisError(errorList) {
            this.searchForm.errorList = errorList;
            this.instance.post('/regionalAnalysis/cityAnalysisError', this.searchForm).then((res) => {
                let data = res.data;
                if (data.code == 1 && data.data) {
                    this.cityAnalysisRemind.analysisVos = data.data.analysisVos;
                    this.handleAnalysisVos(this.cityAnalysisRemind);
                }
            });
        },
        // 长尾效应
        // 各市案件量、总赔款
        longTailCase() {
            this.instance.post('/regionalAnalysis/longTailCase', this.searchForm).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.excelOptions = [];
                    if (data.data.length > 0) {
                        this.excelDate = data.data[0].itemName;
                        this.longTailCaseChart(data.data);
                        data.data.forEach((item) => {
                            this.excelOptions.push(item.itemName);
                            this.tmpContentExcel[item.itemName] = item;
                        });
                        this.chart16Remind.showChart = true;
                    } else {
                        this.chart16Remind.showChart = false;
                    }
                }
                this.chart16Loading = false;
            });
        },
        // 各市案件量、总赔款 - 柱状图
        cityAnalysisChart(dataList) {
            let legendData = ['案件量', '总赔款量(万元)'];
            let xAxisData = [];
            let data1 = [];
            let data2 = [];

            dataList.sort((a, b) => {
                return a.itemValue1 - b.itemValue1;
            });
            dataList.forEach((item) => {
                xAxisData.push(item.itemName);
                data1.push(item.itemValue1);
                data2.push((item.itemValue3 / 10000).toFixed(2));
            });

            let option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                // color: ['rgb(84,112,198)', '#67C23A', 'red'],
                legend: {
                    data: legendData
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'value',
                    boundaryGap: [0, 0.01]
                },
                yAxis: {
                    type: 'category',
                    data: xAxisData
                },
                series: [
                    {
                        name: '案件量',
                        type: 'bar',
                        itemStyle: {
                            normal: {
                                label: {
                                    show: true, //开启显示
                                    position: 'right'
                                }
                            }
                        },
                        data: data1
                    },
                    {
                        type: 'bar',
                        name: '总赔款量(万元)',
                        showSymbol: false,
                        data: data2
                    }
                ]
            };
            let tmpDom = document.getElementById('chart5');
            if (tmpDom) {
                this.chart5 = echarts.init(tmpDom);
                this.chart5.setOption(option);
                setTimeout(() => {
                    this.chart5.resize();
                }, 30);
            }
        },
        // 长尾 - 各市案件量、总赔款 - 柱状图
        longTailCaseChart(dataList) {
            let legendData = ['案件量', '双零案件量'];
            let xAxisData = [];
            let data1 = [];
            let data2 = [];

            dataList.forEach((item) => {
                xAxisData.push(item.itemName);
                data1.push(item.caseNum);
                data2.push(item.zeroCaseNum);
            });

            let option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999'
                        }
                    }
                },
                legend: {
                    data: legendData
                },
                xAxis: [
                    {
                        type: 'category',
                        data: xAxisData,
                        axisPointer: {
                            type: 'shadow'
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        axisLabel: {
                            formatter: '{value}'
                        }
                    }
                ],
                series: [
                    {
                        name: '案件量',
                        type: 'bar',
                        label: {
                            show: true,
                            position: 'top'
                        },
                        tooltip: {
                            valueFormatter: function (value) {
                                return value;
                            }
                        },
                        data: data1
                    },
                    {
                        name: '双零案件量',
                        type: 'bar',
                        label: {
                            show: true,
                            position: 'top'
                        },
                        tooltip: {
                            valueFormatter: function (value) {
                                return value;
                            }
                        },
                        data: data2
                    }
                ]
            };
            let tmpDom = document.getElementById('chart16');
            if (tmpDom) {
                this.chart16 = echarts.init(tmpDom);
                this.chart16.setOption(option);
                setTimeout(() => {
                    this.chart16.resize();
                }, 30);
            }
        },
        // 案均赔款量
        cityTopReport() {
            this.instance.post('/regionalAnalysis/cityTopReport', this.searchForm).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    if (data.data.length > 0) {
                        this.cityTopReportChart(data.data);
                        this.cityTopReportRemind1.errorList = data.data.errorList;
                        this.handleContent(this.cityTopReportRemind1);
                        this.cityTopReportRemind1.showChart = true;
                    } else {
                        this.cityTopReportRemind1.showChart = false;
                    }
                }
                this.cityTopReportRemind1.loading = false;
            });
        },
        // 各市案均赔款量 chart6
        cityAnalysisAvg() {
            this.instance.post('/regionalAnalysis/cityAnalysisAvg', this.searchForm).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    if (data.data.errorList && data.data.errorList.length > 0) {
                        this.cityAnalysisAvgError(data.data.errorList);
                    } else {
                        this.cityAnalysisAvgRemind.analysis = [];
                        this.cityAnalysisAvgRemind.advocate = [];
                    }
                    if (data.data.itemList.length > 0) {
                        this.cityAnalysisAvgChart(data.data.itemList, data.data.base);
                        this.cityAnalysisAvgRemind.errorList = data.data.errorList;
                        this.handleContent(this.cityAnalysisAvgRemind);
                        this.cityAnalysisAvgRemind.showChart = true;
                    } else {
                        this.cityAnalysisAvgRemind.showChart = false;
                    }
                }
                this.cityAnalysisAvgRemind.loading = false;
            });
        },
        // 各市案均赔款量 - 异常点分析 chart6
        cityAnalysisAvgError(errorList) {
            this.searchForm.errorList = errorList;
            this.instance.post('/regionalAnalysis/cityAnalysisAvgError', this.searchForm).then((res) => {
                let data = res.data;
                if (data.code == 1 && data.data) {
                    this.cityAnalysisAvgRemind.analysisVos = data.data.analysisVos;
                    this.handleAnalysisVos(this.cityAnalysisAvgRemind);
                }
            });
        },
        // 各市案均赔款量 - 柱状图
        cityAnalysisAvgChart(dataList, dataBase) {
            let legendData = [
                '案均赔款(元)',
                {
                    name: '全省均线',
                    lineStyle: {
                        type: 'dotted',
                        width: 3,
                        color: '#67C23A'
                    }
                },
                {
                    name: '全国均线',
                    lineStyle: {
                        type: 'dotted',
                        width: 3,
                        color: 'red'
                    }
                }
            ];
            let xAxisData = [];
            let data1 = [];
            // 全省
            let data2 = [];
            // 全国
            let data3 = [];
            let markLineNum1 = dataBase.itemValue4;
            let markLineNum2 = dataBase.otherValue;

            dataList.sort((a, b) => {
                return a.itemValue4 - b.itemValue4;
            });
            dataList.forEach((item, index) => {
                xAxisData.push(item.itemName);
                data1.push(item.itemValue4);
                data2.push(0);
                data3.push(0);
            });

            let option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                color: ['rgb(84,112,198)', '#67C23A', 'red'],
                legend: {
                    data: legendData
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'value',
                    boundaryGap: [0, 0.01]
                },
                yAxis: {
                    type: 'category',
                    data: xAxisData
                },
                series: [
                    {
                        name: '案均赔款(元)',
                        type: 'bar',
                        itemStyle: {
                            normal: {
                                label: {
                                    show: true, //开启显示
                                    position: 'right'
                                }
                            }
                        },
                        markLine: {
                            // 辅助线相关配置markLine
                            // type: 'bar',
                            // silent: true, //基线显示 隐藏
                            symbol: 'none', // 不显示箭头和圆点
                            // lineStyle: {
                            //     normal: {
                            //         type: 'dashed', //dashed为虚线，solid为实线
                            //         color: '#20CC94' // 这儿设置安全基线颜色
                            //     }
                            // },
                            data: [
                                {
                                    type: 'average',
                                    name: '全省均线',
                                    xAxis: markLineNum1, // 全省
                                    lineStyle: {
                                        normal: {
                                            color: '#67C23A' //平均值线的颜色颜色
                                        }
                                        // opacity: isShowAvgline //是否显示平均值（修改其透明度）
                                    },
                                    label: {
                                        textStyle: {
                                            color: '#fff' //平均值文字颜色
                                        }
                                    }
                                },
                                {
                                    type: 'average',
                                    name: '全国均线',
                                    xAxis: markLineNum2, // 全国
                                    lineStyle: {
                                        normal: {
                                            color: 'red' //平均值线的颜色颜色
                                        }
                                        // opacity: isShowAvgline //是否显示平均值（修改其透明度）
                                    },
                                    label: {
                                        textStyle: {
                                            color: '#fff' //平均值文字颜色
                                        }
                                    }
                                }
                            ]
                        },
                        data: data1
                    },
                    {
                        type: 'line',
                        name: '全省均线',
                        showSymbol: false,
                        data: []
                    },
                    {
                        type: 'line',
                        name: '全国均线',
                        showSymbol: false,
                        data: []
                    }
                ]
            };

            let tmpDom = document.getElementById('chart6');
            if (tmpDom) {
                this.chart6 = echarts.init(tmpDom);
                this.chart6.setOption(option);
                setTimeout(() => {
                    this.chart6.resize();
                }, 30);
            }
        },
        // 城市案件量Top3 - 折线图
        cityTopReportChart(dataList) {
            let legendData = [];
            let xAxisData = [];
            let dataObj = {
                data1: [],
                data2: [],
                data3: []
            };

            dataList.forEach((item, index) => {
                legendData.push(item.cityName);
                if (item.itemList && item.itemList.length > 0) {
                    xAxisData = [];
                    item.itemList.forEach((itemList) => {
                        xAxisData.push(itemList.itemName);
                        dataObj['data' + (index + 1)].push(itemList.itemValue1);
                    });
                }
            });

            let option = {
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    data: legendData
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: xAxisData
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        name: legendData[0],
                        type: 'line',
                        stack: 'Total',
                        data: dataObj.data1
                    },
                    {
                        name: legendData[1],
                        type: 'line',
                        stack: 'Total',
                        data: dataObj.data2
                    },
                    {
                        name: legendData[2],
                        type: 'line',
                        stack: 'Total',
                        data: dataObj.data3
                    }
                ]
            };
            let tmpDom = document.getElementById('chart7');
            if (tmpDom) {
                this.chart7 = echarts.init(tmpDom);
                this.chart7.setOption(option);
                setTimeout(() => {
                    this.chart7.resize();
                }, 30);
            }
        },
        // 城市总赔款Top3
        cityTopTotal() {
            this.instance.post('/regionalAnalysis/cityTopTotal', this.searchForm).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    if (data.data.length > 0) {
                        this.cityTopTotalChart(data.data);
                        this.cityTopReportRemind2.errorList = data.data.errorList;
                        this.handleContent(this.cityTopReportRemind2);
                        this.cityTopReportRemind2.showChart = true;
                    } else {
                        this.cityTopReportRemind2.showChart = false;
                    }
                }
                this.cityTopReportRemind2.loading = false;
            });
        },
        // 城市总赔款Top3 - 折线图
        cityTopTotalChart(dataList) {
            let legendData = [];
            let xAxisData = [];
            let dataObj = {
                data1: [],
                data2: [],
                data3: []
            };
            dataList.forEach((item, index) => {
                legendData.push(item.cityName);
                if (item.itemList && item.itemList.length > 0) {
                    xAxisData = [];
                    item.itemList.forEach((itemList) => {
                        xAxisData.push(itemList.itemName);
                        dataObj['data' + (index + 1)].push(itemList.itemValue1);
                    });
                }
            });

            let option = {
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    data: legendData
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: xAxisData
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        name: legendData[0],
                        type: 'line',
                        stack: 'Total',
                        data: dataObj.data1
                    },
                    {
                        name: legendData[1],
                        type: 'line',
                        stack: 'Total',
                        data: dataObj.data2
                    },
                    {
                        name: legendData[2],
                        type: 'line',
                        stack: 'Total',
                        data: dataObj.data3
                    }
                ]
            };
            let tmpDom = document.getElementById('chart8');
            if (tmpDom) {
                this.chart8 = echarts.init(tmpDom);
                this.chart8.setOption(option);
                setTimeout(() => {
                    this.chart8.resize();
                }, 30);
            }
        },
        // 城市案均赔款Top3
        cityTopAvg() {
            this.instance.post('/regionalAnalysis/cityTopAvg', this.searchForm).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    if (data.data.length > 0) {
                        this.cityTopAvgChart(data.data);
                        this.cityTopReportRemind3.errorList = data.data.errorList;
                        this.handleContent(this.cityTopReportRemind3);
                        this.cityTopReportRemind3.showChart = true;
                    } else {
                        this.cityTopReportRemind3.showChart = false;
                    }
                }
                this.cityTopReportRemind3.loading = false;
            });
        },
        // 城市案均赔款Top3 - 折线图
        cityTopAvgChart(dataList) {
            let legendData = [];
            let xAxisData = [];
            let dataObj = {
                data1: [],
                data2: [],
                data3: []
            };
            dataList.forEach((item, index) => {
                legendData.push(item.cityName);
                if (item.itemList && item.itemList.length > 0) {
                    xAxisData = [];
                    item.itemList.forEach((itemList) => {
                        xAxisData.push(itemList.itemName);
                        dataObj['data' + (index + 1)].push(itemList.itemValue1);
                    });
                }
            });

            let option = {
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    data: legendData
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: xAxisData
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        name: legendData[0],
                        type: 'line',
                        stack: 'Total',
                        data: dataObj.data1
                    },
                    {
                        name: legendData[1],
                        type: 'line',
                        stack: 'Total',
                        data: dataObj.data2
                    },
                    {
                        name: legendData[2],
                        type: 'line',
                        stack: 'Total',
                        data: dataObj.data3
                    }
                ]
            };
            let tmpDom = document.getElementById('chart9');
            if (tmpDom) {
                this.chart9 = echarts.init(tmpDom);
                this.chart9.setOption(option);
                setTimeout(() => {
                    this.chart9.resize();
                }, 30);
            }
        },
        // 各市案件量、总赔款
        totalCityAnalysis() {},
        // 大额赔偿案件
        bigAmountCase() {
            this.instance.post('/regionalAnalysis/bigAmountCase', this.searchForm).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    if (data.data.itemList.length > 0) {
                        this.bigAmountCaseChart(data.data.itemList);
                        this.bigAmountCaseRemind.errorList = data.data.errorList;
                        this.handleContent(this.bigAmountCaseRemind);
                        this.bigAmountCaseRemind.showChart = true;
                    } else {
                        this.bigAmountCaseRemind.showChart = false;
                    }
                }
                this.bigAmountCaseRemind.loading = false;
            });
        },
        // 大额赔偿案件 - 折柱图
        bigAmountCaseChart(dataList) {
            let legendData = ['案件量(起)', '案件金额(元)'];
            let xAxisData = [];
            let data1 = [];
            let data2 = [];
            dataList.forEach((item) => {
                xAxisData.push(item.itemName);
                data1.push(item.itemValue1);
                data2.push(item.itemValue3);
            });

            let option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999'
                        }
                    }
                },
                legend: {
                    data: legendData
                },
                xAxis: [
                    {
                        type: 'category',
                        data: xAxisData,
                        axisPointer: {
                            type: 'shadow'
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '案件量(起)',
                        min: 0,
                        max: 250,
                        interval: 50
                    },
                    {
                        type: 'value',
                        name: '案件金额(元)',
                        splitLine: { show: false },
                        min: 0,
                        max: () => {
                            if (data2.length > 0) {
                                return Math.max(...data2);
                            } else {
                                return 1;
                            }
                        }
                    }
                ],
                series: [
                    {
                        name: '案件量(起)',
                        type: 'bar',
                        data: data1
                    },
                    {
                        name: '案件金额(元)',
                        type: 'line',
                        label: {
                            show: true,
                            position: 'top'
                        },
                        yAxisIndex: 1,
                        data: data2
                    }
                ]
            };
            let tmpDom = document.getElementById('chart15');
            if (tmpDom) {
                this.chart15 = echarts.init(tmpDom);
                this.chart15.setOption(option);
                setTimeout(() => {
                    this.chart15.resize();
                }, 30);
            }
        },
        // 重点骑手
        keyRiders() {
            this.instance.post('/regionalAnalysis/keyRiders', this.searchForm).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.keyRidersData = data.data.itemList;

                    this.keyRidersRemind.errorList = data.data.errorList;
                    this.handleContent(this.keyRidersRemind);
                }
                this.keyRidersRemind.loading = false;
            });
        },
        // 重点包商
        keyContractors() {
            this.instance.post('/regionalAnalysis/keyContractors', this.searchForm).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.keyContractorsData = data.data.itemList;

                    this.keyContractorsRemind.errorList = data.data.errorList;
                    this.handleContent(this.keyContractorsRemind);
                }
                this.keyContractorsRemind.loading = false;
            });
        },
        // 处理右侧内容
        handleContent(contentObj) {
            if (contentObj && contentObj.errorList && contentObj.errorList.length > 0) {
                contentObj.abnormal = [];
                contentObj.errorList.forEach((errorItem) => {
                    // 处理异常点
                    if (errorItem.errorMsg) {
                        contentObj.abnormal.push(errorItem.errorMsg.replace(/\n|\r\n/g, '<br>').replace(/ /g, ' &nbsp'));
                    }
                    if (errorItem.errorList && errorItem.errorList.length > 0) {
                        errorItem.errorList.forEach((item) => {
                            contentObj.abnormal.push(item);
                        });
                    }
                });
                this.handleClick(contentObj);
            }
        },
        // 处理分析 && 建议
        handleAnalysisVos(contentObj) {
            if (contentObj && contentObj.analysisVos && contentObj.analysisVos.length > 0) {
                contentObj.analysis = [];
                contentObj.advocate = [];
                contentObj.analysisVos.forEach((analysisItem) => {
                    if (analysisItem.isShow) {
                        analysisItem.infoList.forEach((item) => {
                            contentObj.analysis.push(analysisItem.itemName + '&nbsp;' + item.msg.replace(/\n|\r\n/g, '<br>').replace(/ /g, ' &nbsp'));
                            contentObj.advocate.push(analysisItem.itemName + '&nbsp;' + item.recommend.replace(/\n|\r\n/g, '<br>').replace(/ /g, ' &nbsp'));
                            if (item.listInfo.length > 0) {
                                contentObj.analysis.push('案件号：');
                                item.listInfo.forEach((items, indexs) => {
                                    contentObj.analysis.push({ caseNo: items, isNo: true });
                                    if (indexs == item.listInfo.length - 1) {
                                        contentObj.analysis.push('<div style="width:100%;border-bottom:1px dashed #b9b9b9;margin-bottom:10px;"></div>');
                                    }
                                });
                            }
                        });
                    }
                });
            }
        },
        // 右侧具体tab对应内容
        handleClick(dataObj) {
            switch (dataObj.activeName) {
                case 'second':
                    dataObj.contentList = dataObj.analysis;
                    break;
                case 'third':
                    dataObj.contentList = dataObj.advocate;
                    break;

                default:
                    dataObj.contentList = dataObj.abnormal;
                    break;
            }
        },
        tabsClick() {
            switch (this.tabActive) {
                case 'first':
                    setTimeout(() => {
                        this.chart1 && this.chart1.resize();
                        this.chart2 && this.chart2.resize();
                        this.chart3 && this.chart3.resize();
                        this.chart4 && this.chart4.resize();
                    }, 20);
                    break;
                case 'second':
                    setTimeout(() => {
                        this.chart5 && this.chart5.resize();
                        this.chart6 && this.chart6.resize();
                        this.chart7 && this.chart7.resize();
                        this.chart8 && this.chart8.resize();
                        this.chart9 && this.chart9.resize();
                    }, 20);
                    break;
                case 'third':
                    setTimeout(() => {
                        this.chart16 && this.chart16.resize();
                        this.chart10 && this.chart10.resize();
                        this.chart15 && this.chart15.resize();
                    }, 20);
                    break;
                case 'fourth':
                    break;

                default:
                    break;
            }
        },
        getNowFormatDate(date) {
            var date = date;
            var seperator1 = '-';
            var year = date.getFullYear();
            var month = date.getMonth() + 1;
            var strDate = date.getDate();
            if (month >= 1 && month <= 9) {
                month = '0' + month;
            }
            if (strDate >= 0 && strDate <= 9) {
                strDate = '0' + strDate;
            }
            var currentdate = year + seperator1 + month + seperator1 + strDate;
            return currentdate;
        },
        // 获取月份最后一天
        getLastDate(date) {
            let my_date = new Date(date);
            let last_date = new Date(my_date.getFullYear(), my_date.getMonth() + 1, 0);
            return last_date.getDate();
        }
    }
};
</script>
<style scoped>
@import '../../assets/css/table_public.css';
.task_page .search_box .el-form-item {
    margin-bottom: 0;
}
.regions_content {
    background: #fff;
    margin-top: 20px;
    padding: 10px;
    border-radius: 5px;
    /* box-shadow: 0px 0px 7px 2px rgba(223, 223, 223); */
}
.regions_content:first-child {
    margin-top: 0;
    padding-left: 10px;
}
.regions_content .regions_lay {
    padding-left: 40px;
    margin-top: 10px;
    box-shadow: none !important;
}
.regions_content .regions_lay .lay-row {
    margin-top: 10px;
    /* box-shadow: 0px 0px 7px 2px rgba(223, 223, 223); */
}
.regions_content h3 {
    padding-left: 20px;
}
.lay-row span {
    font-size: 18px;
    color: #409eff;
    margin: 0 10px;
}
.regions-box {
    width: 60%;
}
.regions-content_left {
    border-right: 1px solid #ececec;
}
.regions-content_right {
    width: 36%;
    /* border: 1px solid red; */
}
.abcd .regions-content_right {
    width: 44% !important;
    border-left: 1px solid #ececec;
    padding-left: 10px;
}
.chart_box {
    width: 100%;
    height: 300px;
}
.abnormal,
.analysis {
    width: 92%;
    /* height: 100px; */
    /* border: 1px solid red; */
}
.abnormal {
    margin-right: 20px;
}
.abnormal-txt,
.analysis-txt {
    width: 100%;
    overflow-wrap: break-word;
    max-height: 300px;
    overflow-y: auto;
    padding: 10px;
    margin-top: 10px;
    border-radius: 7px;
    box-shadow: 1px 1px 8px 0px rgba(223, 223, 223, 0.6);
}
.radio-box {
    margin-top: 20px;
}
.search-select .el-input {
    width: 100px !important;
}
.regions-left {
    width: 50%;
}
.content-excel {
    width: 100%;
    margin-top: 10px;
}
.content-excel .el-row {
    margin-top: 7px;
    display: flex;
    align-items: center;
}
.content-excel .el-row .el-col:last-child {
    text-align: right;
}
.content-tabs {
    margin-top: 20px;
}
.noneData {
    padding-top: 120px;
}
</style>