<template>
    <div :class="echartsType == '1' ? 'module_wrap box_border' : 'module_wrap module_wrap2 box_border'" v-loading="loading" element-loading-text="数据加载中" element-loading-spinner="el-icon-loading" element-loading-background="transparent">
        <div class="box_title box_title_radio">
            <span>事故省风险案件标签排行榜</span>
        </div>
        <div style="height: 100%" v-show="!loading">
            <div id="case_bar_tag"></div>
        </div>
    </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
    name: 'ProvinceRiskTag',
    props: {
        isCollapse: {
            type: Boolean,
            default: true
        },
        navForm: {
            type: Object,
            default: {}
        },
        echartsType: {
            type: String,
            default: ''
        }
    },
    watch: {
        isCollapse() {
            setTimeout(() => {
                this.myChart && this.myChart.resize();
            }, 600);
        },
        navForm: {
            deep: true,
            handler(val) {
                this.getData();
            }
        }
    },
    data() {
        return {
            topDays: [],
            newNumber: 0,
            myChart: null,
            yAxisData1: [],
            yAxisData2: [],
            sumSeriesData1: [],
            sumSeriesData2: [],
            numSeriesData1: [],
            numSeriesData2: [],
            option: '',
            radio: '数量',
            radio1: '前15',
            tmpCasesNumTopTen: null,
            loading: true
        };
    },
    mounted() {
        this.role = localStorage.getItem('role');
        window.addEventListener('resize', () => {
            this.myChart && this.myChart.resize();
        });
    },
    methods: {
        getData() {
            this.loading = true;
            let instancePath = null;
            switch (this.echartsType) {
                case '1':
                    instancePath = '/large/data/screen/getProvinceCasesLabelNumTopTen';
                    break;
                case '2':
                    instancePath = '/large/data/screen/getProvinceCasesLabelNumTopTen';
                    break;

                default:
                    break;
            }
            instancePath &&
                this.instance({
                    method: 'post',
                    url: instancePath,
                    data: this.navForm,
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8'
                    }
                }).then((res) => {
                    this.loading = false;
                    if (res.data.code == 1) {
                        let response = res.data.data;
                        this.tmpCasesNumTopTen = response;
                        this.loadCharts(response);
                    }
                });
        },
        //数量&金额change事件
        radioChange() {
            if (this.option) {
                if (this.radio == '数量') {
                    this.option.yAxis.data = this.yAxisData1;
                    this.option.series[0].data = this.numSeriesData1;
                    this.option.series[0].name = '风险案件数量';
                } else {
                    this.option.yAxis.data = this.yAxisData2;
                    this.option.series[0].data = this.sumSeriesData1;
                    this.option.series[0].name = '风险案件金额';
                }
                setTimeout(() => {
                    this.myChart.setOption(this.option);
                }, 100);
            }
        },
        radioChange1() {
            let tmpData1 = JSON.parse(JSON.stringify(this.numSeriesData1));
            let tmpData2 = JSON.parse(JSON.stringify(this.sumSeriesData1));
            let tmpData3 = JSON.parse(JSON.stringify(this.yAxisData1));
            let tmpData4 = JSON.parse(JSON.stringify(this.yAxisData2));
            if (this.radio1 == '前15') {
                if (this.radio == '数量') {
                    this.option.xAxis[0].data = tmpData3.slice(0, 15);
                    this.option.yAxis.data = tmpData3.slice(0, 15);
                    this.option.series[0].data = tmpData1.slice(0, 15);
                    this.option.series[0].name = '风险标签案件占比';
                } else {
                    this.option.xAxis[0].data = tmpData3.slice(0, 15);
                    this.option.yAxis.data = tmpData4.slice(0, 15);
                    this.option.series[0].data = tmpData2.slice(0, 15);
                    this.option.series[0].name = '风险案件金额';
                }
            } else {
                if (this.radio == '数量') {
                    this.option.xAxis[0].data = tmpData3;
                    this.option.yAxis.data = tmpData3;
                    this.option.series[0].data = tmpData1;
                    this.option.series[0].name = '风险标签案件占比';
                } else {
                    this.option.xAxis[0].data = tmpData3;
                    this.option.yAxis.data = tmpData4;
                    this.option.series[0].data = tmpData2;
                    this.option.series[0].name = '风险案件金额';
                }
            }
            setTimeout(() => {
                this.myChart.setOption(this.option);
            }, 100);
        },
        loadCharts(response) {
            let _this = this;
            var chartDom = document.getElementById('case_bar_tag');
            if (!chartDom) {
                return;
            }
            this.myChart = echarts.init(chartDom, null, { renderer: 'svg' });
            this.yAxisData1 = [];
            this.sumSeriesData1 = [];
            this.sumSeriesData2 = [];
            this.yAxisData2 = [];
            this.numSeriesData1 = [];
            this.numSeriesData2 = [];
            response &&
                response.caseRiskNum &&
                response.caseRiskNum.forEach((val, key) => {
                    this.yAxisData1.push(val.name);
                    this.numSeriesData1.push(val.caseNumRatio);
                });
            response &&
                response.caseRiskMoney &&
                response.caseRiskMoney.forEach((val, key) => {
                    this.yAxisData2.push(val.name);
                    this.sumSeriesData1.push(val.amountCaseFiled);
                    this.sumSeriesData2.push(val.totalPayAmount);
                });

            this.option = {
                tooltip: {
                    trigger: 'axis',
                    confine: true,
                    textStyle: {
                        align: 'left'
                    }
                },
                grid: {
                    left: '2%',
                    right: '4%',
                    top: '4%',
                    bottom: '4%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        axisTick: {
                            alignWithLabel: true
                        },
                        axisLabel: {
                            showMaxLabel: true,
                            rotate: 40,
                            textStyle: {
                                // fontSize: '0.12rem',
                                color: '#fff' //坐标值得具体的颜色
                            },
                            width: 60,
                            overflow: 'truncate',
                            ellipsis: '...'
                        },
                        data: this.yAxisData1
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        splitLine: { show: false },
                        axisLabel: {
                            showMaxLabel: true,
                            formatter: '{value} %',
                            textStyle: {
                                // fontSize: '0.12rem',
                                color: '#fff' //坐标值得具体的颜色
                            }
                        },
                        data: this.yAxisData1
                    }
                ],
                series: [
                    {
                        name: '风险标签案件占比',
                        type: 'bar',
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                                { offset: 0, color: '#f295ff' },
                                { offset: 0.5, color: '#cecbff' },
                                { offset: 1, color: '#32dadd' }
                            ])
                        },
                        tooltip: {
                            valueFormatter: function (value) {
                                return value + ' %';
                            }
                        },
                        stack: 'total',
                        barMaxWidth: '40%',
                        data: this.numSeriesData1
                    }
                ]
            };
            this.myChart.setOption(this.option);
            this.radioChange1();
        }
    },
    updated() {
        this.myChart && this.myChart.resize();
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.module_wrap {
    width: 100%;
    height: 3.7rem;
    padding: 0.15rem 0.3rem 0;
    margin-top: 21px;
}
.module_wrap2 {
    margin-top: 0.2rem;
}
#case_bar_tag {
    width: 100%;
    height: 90%;
    margin-top: 0.15rem;
}
</style>
