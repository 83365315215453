<template>
    <div class="module_wrap box_border">
        <div class="box_title">结案周期分布</div>
        <div id="case_cycle_pie"></div>
    </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
    name: 'CaseCycle',
    props: {
        isCollapse: {
            type: Boolean,
            default: true
        },
        navForm: {
            type: Object,
            default: {}
        }
    },
    watch: {
        isCollapse() {
            setTimeout(() => {
                this.myChart && this.myChart.resize();
            }, 600);
        },
        navForm: {
            // immediate: true,
            deep: true,
            handler(val) {
                this.province = val.accidentProvince;
                this.getData();
            }
        }
    },
    data() {
        return {
            topDays: [],
            newNumber: 0,
            myChart: ''
        };
    },
    mounted() {
        this.role = localStorage.getItem('role');
        this.getData();
        window.addEventListener('resize', () => {
            if (this.myChart) {
                setTimeout(() => {
                    this.myChart.resize();
                }, 500);
            }
        });
    },
    methods: {
        getData() {
            this.instance({
                method: 'post',
                url: '/large/data/screen/meituanCaseNumVo',
                data: this.navForm,
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8'
                }
            }).then((res) => {
                if (res.data.code == 1) {
                    let response = res.data.data;
                    if (response.length > 0) {
                        this.loadCharts(response);
                    }
                }
            });
        },
        loadCharts(response) {
            var chartDom = document.getElementById('case_cycle_pie');
            if (!chartDom) {
                return;
            }
            this.myChart = echarts.init(chartDom, null, { renderer: 'svg' });
            let seriesData = [];
            response.forEach((val, key) => {
                let obj = {
                    value: val.caseNum,
                    name: val.name,
                    itemStyle: { borderColor: '#E3A505', shadowColor: '#433f24' }
                };
                if (key == 1) {
                    obj.itemStyle = { borderColor: '#98fd7f', shadowColor: '#195c4b' };
                } else if (key == 2) {
                    obj.itemStyle = { borderColor: '#D1818B', shadowColor: '#632053' };
                } else if (key == 3) {
                    obj.itemStyle = { borderColor: '#2485af', shadowColor: '#082159' };
                }
                seriesData.push(obj);
            });
            var option = {
                tooltip: {
                    trigger: 'item',
                    formatter: '{b}: {c}件',
                    confine: true
                },
                legend: {
                    bottom: '5%',
                    itemWidth: 12,
                    itemHeight: 12,
                    textStyle: {
                        color: '#fff'
                        // fontSize:'0.12rem'
                    },
                    left: 'center'
                },
                series: [
                    {
                        type: 'pie',
                        // radius: ['25%', '50%'],
                        radius: '55%',
                        center: ['50%', '40%'],
                        avoidLabelOverlap: false,
                        label: {
                            // show: false,
                            position: 'inner',
                            fontSize: 10,
                            color: '#fff',
                            formatter: '{d}%'
                        },
                        labelLine: {
                            show: false
                        },
                        itemStyle: {
                            borderColor: '#e4393c',
                            borderWidth: 5,
                            color: '#0A1A2E',
                            shadowBlur: 5,
                            shadowColor: 'rgba(85, 255, 0, 1)'
                        },
                        selectedMode: 'single',
                        data: seriesData
                        // data: [
                        // 	{ value: 1048, name: '24小时以内',itemStyle:{borderColor:"#ffb602",shadowColor: "#433f24"} },
                        // 	{ value: 735, name: '1-7天',itemStyle:{borderColor:"#28fd7f",shadowColor: "#195c4b"} },
                        // 	{ value: 580, name: '7-30天',itemStyle:{borderColor:"#b1009a",shadowColor: "#632053"} },
                        // 	{ value: 484, name: '30天以上',itemStyle:{borderColor:"#2485af",shadowColor: "#082159"} }
                        // ]
                    }
                ]
            };
            this.myChart.setOption(option, true);
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.module_wrap {
    width: 100%;
    height: 3.5rem;
    padding: 0.15rem 0.3rem 0;
    margin-top: 0.25rem;
}
#case_cycle_pie {
    width: 100%;
    height: 2.9rem;
}
</style>
