<template>
    <div class="wrap">
        <div class="page_title cursor" v-if="0"><b>案件详情</b></div>
        <div class="public_box">
            <div class="box_content">
                <p class="box_title title_hot">案件基础条件</p>
                <el-row>
                    <el-col :span="7" :offset="1">
                        <span>案件号：{{ caseData.caseNo }}</span>
                    </el-col>
                    <el-col :span="6">
                        <span>保单号：{{ caseData.policyNo }}</span>
                    </el-col>
                    <el-col :span="10">
                        <span>保险公司：{{ caseData.insureCompany }}</span>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="7" :offset="1">
                        <span>报案时间：{{ caseData.reportTime }}</span>
                    </el-col>
                    <el-col :span="6">
                        <span>结案时间：{{ caseData.closeTime }}</span>
                    </el-col>
                    <el-col :span="10">
                        <span>险种：{{ caseData.insureType }}</span>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="7" :offset="1">
                        <span>案件时长：{{ caseData.caseTimeStr }}</span>
                    </el-col>
                    <el-col :span="6">
                        <span>实际赔付金额：{{ caseData.payAmount ? caseData.payAmount : '' }} 元</span>
                    </el-col>
                    <el-col :span="10">
                        <span>案件状态：{{ caseData.caseStatus }}</span>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="7" :offset="1">
                        <span>事故地点：{{ caseData.accidentAddress }}</span>
                    </el-col>
                </el-row>
            </div>
            <div class="box_content">
                <p class="box_title title_hot">质检情况反馈</p>
                <el-row>
                    <el-col :span="7" :offset="1">
                        <span>质检人：{{ caseData.username }}</span>
                    </el-col>
                    <el-col :span="6">
                        <span>时长：{{ caseData.consumeTimeStr }}</span>
                    </el-col>
                    <el-col :span="10">
                        <span>任务分配时间：{{ caseData.inletTime }}</span>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="7" :offset="1">
                        <span>开始时间：{{ caseData.handlerTime }}</span>
                    </el-col>
                    <el-col :span="6">
                        <span>结束时间：{{ caseData.submitTime }}</span>
                    </el-col>
                    <el-col :span="10">
                        <span>人工理算金额总和：{{ caseData.adjustAmount }}</span>
                    </el-col>
                </el-row>
                <el-form :model="navForm" v-if="caseData.submit" :rules="navFormRules" ref="ruleForm" class="demo-ruleForm">
                    <el-row>
                        <el-col :span="6" :offset="1">
                            <el-form-item class="label_title" label="车损人工理算金额：" prop="vehicleDamageAmount">
                                <el-row>
                                    <el-col :span="13">
                                        <el-input v-model="navForm.vehicleDamageAmount"></el-input>
                                    </el-col>
                                </el-row>
                            </el-form-item>
                        </el-col>
                        <el-col :span="10" :offset="1">
                            <el-form-item label="原因：" prop="vehicleDamageReason">
                                <el-col :span="10">
                                    <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="navForm.vehicleDamageReason" />
                                </el-col>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="6" :offset="1">
                            <el-form-item class="label_title" label="人伤人工理算金额：" prop="humanInjuryAmount">
                                <el-row>
                                    <el-col :span="13">
                                        <el-input v-model="navForm.humanInjuryAmount"></el-input>
                                    </el-col>
                                </el-row>
                            </el-form-item>
                        </el-col>
                        <el-col :span="10" :offset="1">
                            <el-form-item label="原因：" prop="humanInjuryReason">
                                <el-col :span="10">
                                    <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="navForm.humanInjuryReason" />
                                </el-col>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
                <el-row class="text_center" v-if="caseData.submit">
                    <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
                </el-row>
                <el-row v-if="!caseData.submit">
                    <el-col :span="6" :offset="1">
                        <span>车损人工理算金额：{{ caseData.vehicleDamageAmount }}</span>
                    </el-col>
                    <el-col :span="8" :offset="1">
                        <span>原因：{{ caseData.vehicleDamageReason }}</span>
                    </el-col>
                </el-row>
                <el-row v-if="!caseData.submit">
                    <el-col :span="6" :offset="1">
                        <span>人伤人工理算金额：{{ caseData.humanInjuryAmount }}</span>
                    </el-col>
                    <el-col :span="8" :offset="1">
                        <span>原因：{{ caseData.humanInjuryReason }}</span>
                    </el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'caseInfo',
    props: {
        caseData: {}
    },
    data() {
        return {
            navForm: {
                humanInjuryAmount: '',
                humanInjuryReason: '',
                vehicleDamageAmount: '',
                vehicleDamageReason: ''
            },
            navFormRules: {
                vehicleDamageAmount: [{ required: true, message: '请输入内容', trigger: 'blur' }],
                vehicleDamageReason: [{ required: true, message: '请输入内容', trigger: 'blur' }],
                humanInjuryAmount: [{ required: true, message: '请输入内容', trigger: 'blur' }],
                humanInjuryReason: [{ required: true, message: '请输入内容', trigger: 'blur' }]
            },
            inputValue: ''
        }
    },
    watch: {
        caseData(val) {
            if (val) {
                this.navForm = {
                    humanInjuryAmount: val.humanInjuryAmount,
                    humanInjuryReason: val.humanInjuryReason,
                    vehicleDamageAmount: val.vehicleDamageAmount,
                    vehicleDamageReason: val.vehicleDamageReason
                }
            }
        }
    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.instance.post(`/caseJobCheck/commitCaseJob/${this.caseData.caseNo}`, this.navForm).then((res) => {
                        let data = res.data
                        if (data.code == 1) {
                            data.data.rowIndex = this.caseData.rowIndex
                            this.caseData = data.data
                            this.caseData.submit = true
                            sessionStorage.setItem('caseData', JSON.stringify(this.caseData))
                            this.$message.success(res.data.message)
                            this.$emit('submitCase', false)
                        } else {
                            this.$message.error(res.data.message)
                        }
                    })
                }
            })
        }
    }
}
</script>
<style>
@import '../../assets/css/table_public.css';
.page_title {
    margin-bottom: 20px;
}
.box_content .box_title {
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}
.box_content .title_hot::before {
    display: inline-block;
    content: '';
    width: 4px;
    height: 14px;
    background: #051932;
    border-radius: 4px;
    margin-right: 6px;
}
.box_content .el-row {
    margin-bottom: 30px;
}
.box_content .el-row {
    position: relative;
}
.box_content .el-row .error_txt {
    color: red;
    position: absolute;
    left: 160px;
    top: 40px;
}
.box_content .el-form-item .el-form-item__error {
    bottom: -18px;
    top: auto;
    left: 62px;
}
.box_content .label_title .el-form-item__error {
    bottom: 10px;
    left: 145px;
}
</style>