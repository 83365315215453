<template>
    <div class="module_wrap box_border">
        <div class="box_title">报案时效统计</div>
        <div id="case_time_bar"></div>
    </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
    name: 'CaseTime',
    props: {
        isCollapse: {
            type: Boolean,
            default: true
        },
        navForm: {
            type: Object,
            default: {}
        }
    },
    watch: {
        isCollapse() {
            setTimeout(() => {
                this.myChart && this.myChart.resize();
            }, 600);
        },
        navForm: {
            // immediate: true,
            deep: true,
            handler(val) {
                this.province = val.accidentProvince;
                this.getData();
            }
        }
    },
    data() {
        return {
            topDays: [],
            newNumber: 0,
            myChart: ''
        };
    },
    mounted() {
        this.role = localStorage.getItem('role');
        this.getData();
        window.addEventListener('resize', () => {
            if (this.myChart) {
                setTimeout(() => {
                    this.myChart.resize();
                }, 500);
            }
        });
    },
    methods: {
        //获取数据
        getData() {
            this.instance({
                method: 'post',
                url: '/large/data/screen/meituanCaseReportingVo',
                data: this.navForm,
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8'
                }
            }).then((res) => {
                if (res.data.code == 1) {
                    let response = res.data.data;
                    if (response.length > 0) {
                        this.loadCharts(response);
                    }
                }
            });
        },
        loadCharts(response) {
            var chartDom = document.getElementById('case_time_bar');
            if (!chartDom) {
                return;
            }
            this.myChart = echarts.init(chartDom, null, { renderer: 'svg' });
            let xAxisData = [],
                seriesData = [];
            response.forEach((val, key) => {
                xAxisData.push(val.name + '天');
                seriesData.push(val.caseNumRatio);
            });
            var total = eval(seriesData.join('+'));
            var option = {
                tooltip: {
                    // formatter: '{b0}: {c0} 件',
                    formatter(params) {
                        let dataIndex = params.dataIndex;
                        return params.name + ': ' + response[dataIndex].caseNum + '件';
                    },
                    show: true
                },
                grid: {
                    top: 20,
                    left: '10',
                    right: '10',
                    bottom: '10',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    axisLabel: {
                        showMaxLabel: true,
                        textStyle: {
                            // fontSize: '0.12rem',
                            color: '#fff' //坐标值得具体的颜色
                        }
                    },
                    axisTick: {
                        alignWithLabel: true
                    },
                    data: xAxisData
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        showMaxLabel: true,
                        textStyle: {
                            // fontSize: '0.12rem',
                            color: '#fff' //坐标值得具体的颜色
                        }
                    },
                    splitLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    }
                },
                series: [
                    {
                        type: 'bar',
                        barWidth: '40%',
                        data: seriesData,
                        label: {
                            show: true,
                            position: 'top',
                            // fontSize: '0.12rem',
                            color: '#fff',
                            formatter: '{c} %'
                            // formatter(val){
                            // 	return (val.value/total*100).toFixed(2)+'%'
                            // },
                        },
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: '#e7a0a6' },
                                { offset: 0.5, color: '#dc7b86' },
                                { offset: 1, color: '#d7636e' }
                            ])
                        }
                    }
                ]
            };
            this.myChart.setOption(option);
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.module_wrap {
    width: 100%;
    height: 3.3rem;
    padding: 0.15rem 0.3rem 0;
    margin-top: 0.25rem;
}
#case_time_bar {
    width: 100%;
    height: 2.7rem;
}
</style>
