<template>
    <div class="module_wrap box_border" v-loading="loading" element-loading-text="数据加载中" element-loading-spinner="el-icon-loading" element-loading-background="transparent">
        <div class="box_title box_title_radio">
            <span>报案时效统计</span>
            <div class="radio_group" v-show="0">
                <el-radio-group v-model="radio" @change="radioChange" size="mini">
                    <el-radio-button label="骑手报案"></el-radio-button>
                    <el-radio-button label="保司报案"></el-radio-button>
                </el-radio-group>
            </div>
        </div>
        <div style="height: 100%" v-show="!loading">
            <div id="distribution_pie"></div>
        </div>
    </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
    name: 'ReportTime',
    props: {
        isCollapse: {
            type: Boolean,
            default: true
        },
        navForm: {
            type: Object,
            default: {}
        }
    },
    watch: {
        isCollapse() {
            setTimeout(() => {
                this.myChart && this.myChart.resize();
            }, 600);
        },
        navForm: {
            // immediate: true,
            deep: true,
            handler(val) {
                this.getData();
            }
        }
    },
    data() {
        return {
            topDays: [],
            newNumber: 0,
            radio: '骑手报案',
            xAxisData: [],
            seriesData1: [],
            seriesData2: [],
            option: '',
            myChart: '',
            loading: true
        };
    },
    mounted() {
        this.role = localStorage.getItem('role');
        window.addEventListener('resize', () => {
            if (this.myChart) {
                setTimeout(() => {
                    this.myChart.resize();
                }, 500);
            }
        });
    },
    methods: {
        getData() {
            this.loading = true;
            this.instance({
                method: 'post',
                url: '/large/data/screen/getReportRiskCase',
                data: this.navForm,
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8'
                }
            }).then((res) => {
                this.loading = false;
                if (res.data.code == 1) {
                    let response = res.data.data;
                    this.loadCharts(response);
                }
            });
        },
        //骑手报案&保司报案change事件
        radioChange() {
            if (this.option) {
                if (this.radio == '骑手报案') {
                    this.option.series[0].data = this.seriesData1;
                } else {
                    this.option.series[0].data = this.seriesData2;
                }
                setTimeout(() => {
                    this.myChart.setOption(this.option);
                }, 100);
            }
        },
        loadCharts(response) {
            var chartDom = document.getElementById('distribution_pie');
            if (!chartDom) {
                return;
            }
            this.myChart = echarts.init(chartDom, null, { renderer: 'svg' });
            this.xAxisData = [];
            this.seriesData1 = [];
            this.seriesData2 = [];
            response.caseRiskMoney.forEach((val, key) => {
                this.seriesData1.push(val.amountCaseFiled);
                this.seriesData2.push(val.caseNumRatio);
                this.seriesData1.push(val.amountOfFirstCaseFiled);
                this.seriesData2.push(val.totalPayAmount);
            });
            this.option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999'
                        }
                    }
                },
                grid: {
                    top: 30,
                    left: '10',
                    right: '10',
                    bottom: '15',
                    containLabel: true
                },
                color: ['#409EFF', '#67C23A'],
                legend: {
                    data: ['首次报案时间', '每次报案时间'],
                    itemWidth: 12,
                    itemHeight: 12,
                    textStyle: {
                        color: '#fff'
                        // fontSize: '0.12rem'
                    },
                    left: 'center'
                },
                xAxis: [
                    {
                        type: 'category',
                        data: ['24h报案率', '48h报案率'],
                        axisPointer: {
                            type: 'shadow'
                        },
                        axisLabel: {
                            showMaxLabel: true,
                            textStyle: {
                                // fontSize: '0.12rem',
                                color: '#fff' //坐标值得具体的颜色
                            },
                            width: 60,
                            overflow: 'truncate',
                            ellipsis: '...'
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        splitLine: { show: false },
                        axisLabel: {
                            showMaxLabel: true,
                            textStyle: {
                                // fontSize: '0.12rem',
                                color: '#fff' //坐标值得具体的颜色
                            }
                        }
                    },
                    {
                        type: 'value',
                        splitLine: { show: false }
                    }
                ],
                series: [
                    {
                        name: '首次报案时间',
                        type: 'bar',
                        tooltip: {
                            valueFormatter: function (value) {
                                return value + ' %';
                            }
                        },
                        data: this.seriesData1,
                        itemStyle: {
                            normal: {
                                color: '#409EFF'
                            }
                        }
                    },
                    {
                        name: '每次报案时间',
                        type: 'bar',
                        tooltip: {
                            valueFormatter: function (value) {
                                return value + ' %';
                            }
                        },
                        data: this.seriesData2,
                        itemStyle: {
                            normal: {
                                color: '#67C23A'
                            }
                        }
                    }
                ]
            };
            this.myChart.setOption(this.option);
        }
    },
    updated() {
        this.myChart && this.myChart.resize();
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.module_wrap {
    width: 100%;
    height: 3.4rem;
    padding: 0.15rem 0.3rem 0;
    margin-top: 0.2rem;
    overflow: hidden;
}
#distribution_pie {
    width: 100%;
    height: 90%;
}
</style>
