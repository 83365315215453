<template>
    <div class="module_wrap box_border">
        <div class="box_title box_title_radio">
            <span>结案时长分布</span>
        </div>
        <div id="lawsuit_times_pie"></div>
    </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
    name: 'LawsuitTimes',
    props: {
        isCollapse: {
            type: Boolean,
            default: true
        },
        navForm: {
            type: Object,
            default: {}
        }
    },
    watch: {
        isCollapse() {
            setTimeout(() => {
                this.myChart.resize();
            }, 600);
        },
        navForm: {
            // immediate: true,
            deep: true,
            handler(val) {
                this.getData();
            }
        }
    },
    data() {
        return {
            radio: '数量',
            topDays: [],
            newNumber: 0,
            myChart: '',
            option: ''
        };
    },
    mounted() {
        this.role = localStorage.getItem('role');
        window.addEventListener('resize', () => {
            if (this.myChart) {
                setTimeout(() => {
                    this.myChart.resize();
                }, 500);
            }
        });
    },
    methods: {
        getData() {
            this.instance({
                method: 'post',
                url: '/large/data/screen/getCloseCaseTime',
                data: this.navForm,
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8'
                }
            }).then((res) => {
                if (res.data.code == 1) {
                    let response = res.data.data;
                    this.loadCharts(response);
                }
            });
        },
        loadCharts(response) {
            var chartDom = document.getElementById('lawsuit_times_pie');
            if (!chartDom) {
                return;
            }
            this.myChart = echarts.init(chartDom, null, { renderer: 'svg' });
            let seriesData = [];
            response.forEach((val, key) => {
                let obj = {
                    value: val.caseNum,
                    name: val.name
                };
                seriesData.push(obj);
            });
            this.option = {
                tooltip: {
                    trigger: 'item',
                    formatter: '{b}: {c}件',
                    confine: true
                },
                legend: {
                    right: '5%',
                    top: 'middle',
                    orient: 'vertical',
                    itemWidth: 12,
                    itemHeight: 12,
                    textStyle: {
                        color: '#fff',
                        fontSize: '0.12rem'
                    }
                },
                series: [
                    {
                        type: 'pie',
                        // radius: ['25%', '50%'],
                        radius: '70%',
                        center: ['40%', '50%'],
                        avoidLabelOverlap: false,
                        label: {
                            // show: false,
                            position: 'inner',
                            fontSize: 10,
                            color: '#fff',
                            formatter: '{d}%'
                        },
                        labelLine: {
                            show: false
                        },
                        // data: seriesData
                        data: seriesData
                    }
                ]
            };
            this.myChart.setOption(this.option);
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.module_wrap {
    width: 100%;
    height: 3.05rem;
    padding: 0.15rem 0.3rem 0;
    /* margin-top: 0.25rem; */
}
#lawsuit_times_pie {
    width: 100%;
    height: 2.5rem;
}
.box_title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
</style>
