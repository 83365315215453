<template>
    <div class="wrap">
        <div class="page_title">
            <b>案件风险值查询</b>
            <span>（案件列表显示满足筛选规则的案件）</span>
        </div>
        <div class="search_box public_box">
            <!--检索部分-->
            <el-form :inline="true" :model="navForm" class="demo-form-inline">
                <div class="flex">
                    <div>
                        <el-form-item style="margin-right: 0.8rem">
                            <el-upload class="upload_box" accept=".xls,.xlsx" :action="baseURL + '/case/importCaseExcel'" :show-file-list="true" :headers="unloaderHeader" :limit="1" :on-remove="handleRemove" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                                <template>
                                    <el-button type="primary"><i class="el-icon-upload el-icon--left"></i>上传案件</el-button>
                                </template>
                            </el-upload>
                        </el-form-item>
                        <el-form-item>
                            <span class="hint_text">（若无批量案件上传，可按条件查询）</span>
                        </el-form-item>
                        <br />
                        <el-form-item label="事故省：">
                            <el-select v-model="navForm.accidentProvince" class="width_150" placeholder="请选择">
                                <el-option v-for="item in provinceOptions" :key="item" :label="item" :value="item"> </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="公估公司：">
                            <el-input class="width_150" v-model="navForm.lossAdjusterCompany" placeholder="请填写公估公司" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="被保险人：">
                            <el-input class="width_150" v-model="navForm.nameOfInsured" placeholder="请填写分包商" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="报案时间：" class="dateBox">
                            <el-row style="width: 370px">
                                <el-col :span="11">
                                    <el-date-picker placeholder="请选择日期" clearable v-model="navForm.reportStartTime" type="date" value-format="yyyy-MM-dd" style="width: 100%"> </el-date-picker>
                                </el-col>
                                <el-col class="line" style="text-align: center" :span="2">至</el-col>
                                <el-col :span="11">
                                    <el-date-picker placeholder="请选择日期" clearable v-model="navForm.reportEndTime" type="date" value-format="yyyy-MM-dd" style="width: 100%"> </el-date-picker>
                                </el-col>
                            </el-row>
                        </el-form-item>
                        <el-form-item label="结案时间：" class="dateBox">
                            <el-row style="width: 370px">
                                <el-col :span="11">
                                    <el-date-picker placeholder="请选择日期" clearable v-model="navForm.startTime" type="date" value-format="yyyy-MM-dd" style="width: 100%"> </el-date-picker>
                                </el-col>
                                <el-col class="line" style="text-align: center" :span="2">至</el-col>
                                <el-col :span="11">
                                    <el-date-picker placeholder="请选择日期" clearable v-model="navForm.endTime" type="date" value-format="yyyy-MM-dd" style="width: 100%"> </el-date-picker>
                                </el-col>
                            </el-row>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="searchTable">风险查询</el-button>
                        </el-form-item>
                    </div>
                </div>
            </el-form>
        </div>
        <div class="main">
            <div class="table_box public_box">
                <div class="table_title">
                    <b>案件详情</b>
                    <el-button @click="handleDownload" v-if="tableData.length > 0" class="download" type="text"><i class="el-icon-download el-icon--left"></i>下载表单</el-button>
                </div>
                <!--table表格部分-->
                <el-table ref="multipleTable" :data="tableData" v-loading="loading" style="width: 100%" :empty-text="caseExcelData.length > 0 || navForm.endTime ? '暂无案件' : '暂无案件,请上传案件清单或选择查询日期'">
                    <!-- @selection-change="handleSelectionChange" -->
                    <!-- <el-table-column type="selection" width="55"></el-table-column> -->
                    <el-table-column prop="meituanCaseNo" label="美团案件号"></el-table-column>
                    <el-table-column prop="accidentTime" label="出险时间"></el-table-column>
                    <el-table-column prop="reportTime" label="报案时间"></el-table-column>
                    <el-table-column prop="insureTypeName" label="保险险种"></el-table-column>
                    <el-table-column prop="currentStatusDesc" label="案件状态"></el-table-column>
                    <el-table-column prop="caseClosedTime" label="结案时间"></el-table-column>
                    <el-table-column prop="totalPayAmount" label="结案金额"></el-table-column>
                    <el-table-column prop="valueAtRisk" label="风险值"></el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <span class="a_color" @click="lookDetails(scope.row)">查看详情</span>
                        </template>
                    </el-table-column>
                </el-table>
                <!--分页部分-->
                <el-pagination style="margin-top: 0.2rem" @current-change="handleCurrentChange" @size-change="handleSizeChange" :current-page="currentPage" layout="total, sizes, prev, pager, next, jumper" :page-sizes="[10, 20]" :page-size="10" :total="totalPage"> </el-pagination>
            </div>
        </div>
        <el-dialog :visible.sync="dialogVisible" :modal-append-to-body="false" width="65%">
            <div class="dialog_box">
                <div class="dialog_header">
                    <div>案件号：{{ checkedRowDetail.caseNo }}</div>
                    <div>是否快赔：{{ checkedRowDetail.quickPayOrNot }}</div>
                </div>
                <div class="dialog_main">
                    <div class="dialog_row">
                        <div class="dialog_col">
                            <div class="detail_title">涉事方信息：</div>
                            <ul>
                                <li>出险人姓名：{{ checkedRowDetail.nameOfDeliveryMan }}</li>
                                <li>三者姓名：{{ checkedRowDetail.nameOfThirdParty }}</li>
                                <li>被保险人：{{ checkedRowDetail.nameOfInsured }}</li>
                                <li>投保人：{{ checkedRowDetail.insuranceApplicant }}</li>
                            </ul>
                        </div>
                        <div class="dialog_col">
                            <div class="detail_title">审核信息：</div>
                            <ul>
                                <li>公估公司：{{ checkedRowDetail.lossAdjusterCompany }}</li>
                                <li>初审人：{{ checkedRowDetail.firstCheckMan }}</li>
                                <li>复审人：{{ checkedRowDetail.reviewer }}</li>
                            </ul>
                        </div>
                    </div>
                    <div class="dialog_row">
                        <div class="dialog_col">
                            <div class="detail_title">事故省市：</div>
                            <ul>
                                <li>{{ checkedRowDetail.accidentProvincesAndCities }}</li>
                            </ul>
                        </div>
                        <!-- <div class="dialog_col">
							<div class="detail_title">责任比例：</div>
							<ul>
								<li>骑手20%{{checkedRowDetail.}}</li>
							</ul>
						</div> -->
                    </div>
                    <div class="dialog_row">
                        <div class="dialog_col" style="width: 100%">
                            <div class="detail_title">赔付信息：</div>
                            <ul>
                                <li>总赔款金额：{{ checkedRowDetail.totalPayAmount }}元</li>
                            </ul>
                            <div class="adjustment_detail">
                                <div>理算明细：</div>
                                <ul>
                                    <li>
                                        <span>车损：{{ checkedRowDetail.vehicleDamage }} 元</span>
                                        <span>门诊医疗费：{{ checkedRowDetail.outPatientMedicalExpenses }} 元</span>
                                    </li>
                                    <li>
                                        <span>伤残赔偿金：{{ checkedRowDetail.disabilityCompensation }} 元</span>
                                        <span>骑手住院津贴：{{ checkedRowDetail.hospitalizationAllowanceForDeliveryMan }} 元</span>
                                    </li>
                                    <li>
                                        <span>误工费：{{ checkedRowDetail.lostWages }} 元</span>
                                        <span>其他补助：{{ checkedRowDetail.otherAllowance }} 元</span>
                                    </li>
                                    <li>
                                        <span>物损金额：{{ checkedRowDetail.itemDamage }} 元</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
// @ is an alias to /src
// import MyHeader from '@/components/MyHeader.vue'
import { getToken } from '../../../utils/auth.js'
export default {
    name: 'caseRisk',
    components: {},
    data() {
        return {
            caseExcelData: [],
            dialogVisible: false,
            baseURL: '',
            unloaderHeader: { token: '' }, //上传的请求头
            fullscreenLoading: false,
            uploadFile: {}, //上传成功后的值
            fileName: '',
            activeName: 'first',
            loading: false,
            nowFilter: '',
            provinceOptions: [
                '辽宁省',
                '江苏省',
                '广西壮族自治区',
                '福建省',
                '四川省',
                '黑龙江省',
                '山东省',
                '江西省',
                '陕西省',
                '吉林省',
                '北京市',
                '安徽省',
                '河北省',
                '河南省',
                '重庆市',
                '天津市',
                '湖北省',
                '甘肃省',
                '上海市',
                '云南省',
                '贵州省',
                '内蒙古自治区',
                '山西省',
                '海南省',
                '浙江省',
                '宁夏回族自治区',
                '青海省',
                '广东省',
                '新疆维吾尔自治区',
                '湖南省',
                '西藏自治区',
                '香港特别行政区',
                '澳门特别行政区',
                '台湾省'
            ],
            navForm: {
                //储存搜索框的检索条件信息
                startTime: '',
                endTime: '',
                value: '',
                nameOfInsured: '',
                meituanCaseInfoList: []
            },
            checkedRowDetail: {}, //点击行的详情
            riskOptions: [], //我的规则 风险点组合名称
            customFilterOptionList: [], //自定义全局筛选  风险筛选项
            currentPage: 1, //当前页
            pageSize: 10, //一页多少条数据
            totalPage: 0, //总页数tableData:[],
            tableData: []
        }
    },
    methods: {
        //删除文件
        handleRemove(file, fileList) {
            this.caseExcelData = []
        },
        //获取已定义筛选规则
        getCommonFilterOptionList() {
            this.instance.post('/case/getCommonFilterOptionList', {}).then((res) => {
                if (res.data.code == 1) {
                    this.riskOptions = res.data.data
                }
            })
        },
        //点击查看详情
        lookDetails(row) {
            this.checkedRowDetail = row
            this.dialogVisible = true
        },
        //文件上传成功时的钩子
        handleAvatarSuccess(res, file) {
            this.fullscreenLoading.close()
            this.fileName = file.name
            this.uploadFile = res.data
            if (res.code == 1) {
                this.$message.success(res.message)
                this.caseExcelData = res.data
            } else {
                this.$message.error(res.message)
            }
        },
        //上传文件之前的钩子
        beforeAvatarUpload(file) {
            if (file.size / 1024 > 5000) {
                this.$message.error('抱歉，上传文件不得大于5M！')
                return false
            }
            this.unloaderHeader.token = getToken()
            this.fullscreenLoading = this.$loading({
                lock: true,
                text: '上传中，请稍后',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
        },
        //切换table选中事件
        handleSelectionChange(val) {
            this.multipleSelection = val
        },
        //点击查询
        searchTable() {
            if (this.caseExcelData.length > 0 || (this.navForm.startTime && this.navForm.endTime)) {
                this.currentPage = 1
                this.getTbodyData()
            } else {
                this.$message.error('请上传文件或者选择结案时间之后再查询！')
            }
        },
        //获取tbody里的数据
        getTbodyData: function () {
            this.loading = true
            this.tableData = [] //  清空table的值
            var json = JSON.parse(JSON.stringify(this.navForm))
            json.meituanCaseInfoList = this.caseExcelData
            //储存当前的检索条件，下载用
            this.nowFilter = JSON.parse(JSON.stringify(json))
            json.pageNum = this.currentPage
            json.pageSize = this.pageSize
            this.instance.post('/case/getCaseList', json).then((res) => {
                this.loading = false
                if (res.data.code == 1) {
                    this.tableData = res.data.data.list
                    this.totalPage = parseInt(res.data.data.total)
                } else {
                    this.totalPage = 0
                }
            })
        },
        //分页数据多少条change
        handleSizeChange(val) {
            this.pageSize = val
            this.getTbodyData()
        },
        //分页：当前页改变之后的函数
        handleCurrentChange: function (val) {
            this.currentPage = Number(val)
            this.getTbodyData()
        },
        //下载工单
        handleDownload() {
            var json = JSON.parse(JSON.stringify(this.nowFilter))
            this.fullscreenLoading = this.$loading({
                lock: true,
                text: '下载中，请稍后',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            this.instance({
                method: 'post',
                url: '/case/exportCaseExcel',
                data: json,
                responseType: 'blob',
                headers: {
                    // 'Content-Type':"application/x-www-form-urlencoded",
                }
            })
                .then((res) => {
                    // this.loading=false;
                    this.fullscreenLoading.close()
                    let url = window.URL.createObjectURL(new Blob([res.data]))
                    let link = document.createElement('a')
                    link.style.display = 'none'
                    link.href = url
                    link.setAttribute('download', '案件下载清单.csv') //指定下载后的文件名，防跳转
                    document.body.appendChild(link)
                    link.click()
                })
                .catch(function (error) {
                    console.log(error)
                })
        }
    },
    mounted() {
        this.getCommonFilterOptionList()
        this.baseURL = this.$store.getters.baseURL
    }
}
</script>
<style scoped>
@import '../../../assets/css/table_public.css';
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
}
::v-deep input[type='‘number’'] {
    -moz-appearance: textfield !important;
}
.condition_form /deep/.el-input__inner,
.condition_form /deep/.el-select-dropdown__item {
    font-size: 0.14rem;
}
.condition_form {
    margin-top: 0.15rem;
}
.condition_form .el-form-item--mini.el-form-item,
.condition_form .el-form-item--small.el-form-item {
    margin-bottom: 0;
}
.condition_form .el-select,
.condition_form .el-input {
    margin-right: 0.2rem;
}
.condition_form /deep/.el-form-item__label {
    padding: 0;
}
.condition_lebal {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.18rem;
}
.condition_lebal b {
    width: 0.2rem;
    height: 0.2rem;
    background: url(../../../../public/images/close_icon.png) no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
}
.condition_item {
    width: 17.6rem;
    min-height: 1.2rem;
    background: #f2f8fc;
    border-radius: 0.04rem;
    padding: 0.2rem 0.3rem;
}
.condition_box li {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: flex-end;
    min-height: 1.2rem;
    margin-bottom: 0.3rem;
}
.condition_box li:nth-child(2) .condition_item {
    width: 17.3rem;
}
.condition_box li:nth-child(3) .and_or {
    left: 0.3rem;
}
.condition_box li:nth-child(3) .condition_item {
    width: 17rem;
}
.condition_box li:nth-child(4) .and_or {
    left: 0.6rem;
}
.condition_box li:nth-child(4) .condition_item {
    width: 16.7rem;
}
.condition_box li:nth-child(5) .and_or {
    left: 0.9rem;
}
.condition_box li:nth-child(5) .condition_item {
    width: 16.4rem;
}
.condition_box li:nth-child(6) .and_or {
    left: 1.2rem;
}
.condition_box li:nth-child(6) .condition_item {
    width: 16.1rem;
}
.condition_box li:nth-child(7) .and_or {
    left: 1.5rem;
}
.condition_box li:nth-child(7) .condition_item {
    width: 15.8rem;
}
.condition_box li:nth-child(8) .and_or {
    left: 1.8rem;
}
.condition_box li:nth-child(8) .condition_item {
    width: 15.5rem;
}
.condition_box li:nth-child(9) .and_or {
    left: 2.1rem;
}
.condition_box li:nth-child(9) .condition_item {
    width: 15.2rem;
}
.condition_box li:nth-child(10) .and_or {
    left: 2.4rem;
}
.condition_box li:nth-child(10) .condition_item {
    width: 14.9rem;
}
.condition_box li:nth-child(11) .and_or {
    left: 2.7rem;
}
.condition_box li:nth-child(11) .condition_item {
    width: 14.6rem;
}
.condition_box li:nth-child(12) .and_or {
    left: 3rem;
}
.condition_box li:nth-child(12) .condition_item {
    width: 14.3rem;
}
.condition_box li:nth-child(13) .and_or {
    left: 3.3rem;
}
.condition_box li:nth-child(13) .condition_item {
    width: 14rem;
}
.condition_box li:nth-child(14) .and_or {
    left: 3.6rem;
}
.condition_box li:nth-child(14) .condition_item {
    width: 13.7rem;
}
.condition_box li:nth-child(15) .and_or {
    left: 3.9rem;
}
.condition_box li:nth-child(15) .condition_item {
    width: 13.4rem;
}
.condition_box li:nth-child(16) .and_or {
    left: 4.2rem;
}
.condition_box li:nth-child(16) .condition_item {
    width: 13.1rem;
}
.condition_box li:nth-child(17) .and_or {
    left: 4.5rem;
}
.condition_box li:nth-child(17) .condition_item {
    width: 12.8rem;
}
.condition_box li:nth-child(18) .and_or {
    left: 4.8rem;
}
.condition_box li:nth-child(18) .condition_item {
    width: 12.5rem;
}
.and_or {
    position: absolute;
    bottom: 0.4rem;
    left: 0;
    z-index: 20;
    width: 0.26rem;
    height: 1.7rem;
    padding-top: 0.5rem;
}
.and_or span {
    width: 1px;
    height: 1.7rem;
    background-color: #c1e0ff;
    position: absolute;
    top: 0;
    left: 0.12rem;
    z-index: -1;
}
.and_or b {
    display: inline-block;
    background: #ffffff;
    border: 1px solid #1989fa;
    font-size: 0.15rem;
    width: 0.26rem;
    height: 0.26rem;
    line-height: 0.24rem;
    text-align: center;
    color: #1989fa;
    margin-bottom: 0.1rem;
    cursor: pointer;
}
.and_or .active {
    color: #fff;
    background: #1989fa;
}
.custom_filtering {
    padding: 0 0 0.3rem;
    border-bottom: 1px solid #ebeef5;
}
.add_condition {
    display: flex;
    align-items: center;
    font-size: 0.18rem;
}
.add_condition div {
    width: 0.24rem;
    margin-right: 0.1rem;
    cursor: pointer;
}
.add_condition span {
    cursor: pointer;
}
.adjustment_detail {
    width: 100%;
    display: flex;
}
.dialog_col li,
.adjustment_detail > div {
    font-size: 0.18rem;
    color: #606266;
    margin-top: 0.2rem;
}
.adjustment_detail li span:first-child {
    display: inline-block;
    min-width: 3rem;
}
.dialog_row {
    margin-top: 0.4rem;
    display: flex;
}
.dialog_row > div {
    width: 50%;
}
.dialog_header {
    display: flex;
    height: 0.68rem;
    border-bottom: 1px solid #ebeef5;
    font-size: 0.22rem;
    font-weight: 600;
}
.dialog_header div:first-child {
    margin-right: 1.2rem;
}
/deep/.el-dialog__header {
    padding: 0;
}
/deep/.el-dialog__body {
    padding: 0.5rem;
    color: #333;
}
.table_title {
    display: flex;
    margin-bottom: 0.3rem;
    align-items: center;
}
.table_title b {
    font-size: 0.26rem;
    font-weight: 600;
}
.hint_text {
    color: #909399;
    font-size: 0.16rem;
}
/deep/.el-tabs__item {
    height: 0.4rem;
    font-size: 0.22rem;
    line-height: 1;
}
/deep/.el-tabs__header {
    margin-bottom: 0.38rem;
}
.warning_icon {
    width: 0.14rem;
    margin-right: 0.18rem;
}
.download {
    margin-left: 0.5rem;
    font-size: 0.18rem;
}
</style>
