<template>
    <div class="wrap">
        <div class="task_page" v-show="!caseInfo">
            <div class="page_title"><b>我的任务</b></div>
            <!--检索部分  -->
            <div class="search_box public_box">
                <el-form :inline="true" :model="navForm" class="demo-form-inline">
                    <el-form-item label="报案开始时间：" class="dateBox">
                        <el-date-picker v-model="navForm.reportStartTime" type="datetime" placeholder="选择日期时间" value-format="yyyy-MM-dd HH:mm:ss"> </el-date-picker>
                    </el-form-item>
                    <el-form-item label="报案结束时间：" class="dateBox">
                        <el-date-picker v-model="navForm.reportEndTime" type="datetime" placeholder="选择日期时间" value-format="yyyy-MM-dd HH:mm:ss"> </el-date-picker>
                    </el-form-item>
                    <el-form-item label="案件号：">
                        <el-input v-model="navForm.caseNo" type="text" placeholder="请输入案件号" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="保单号：">
                        <el-input v-model="navForm.policyNo" type="text" placeholder="请输入保单号" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="案件状态："
                        ><el-select v-model="navForm.commitStatus" placeholder="请选择">
                            <el-option label="未处理" value="1"> </el-option>
                            <el-option label="已处理" value="2"> </el-option>
                            <el-option label="全部" value="99"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="getTaskList(true)" style="margin-left: 0.2rem">查询</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="claimTask" style="margin-left: 0.2rem">认领任务</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div class="main">
                <div class="table_box public_box">
                    <!--table表格部分-->
                    <el-table ref="multipleTable" :data="tableData" v-loading="loading" style="width: 100%" height="470">
                        <el-table-column prop="caseNo" label="案件号">
                            <template slot-scope="scope">
                                <span v-if="scope.row.caseNo.length < 7">{{ scope.row.caseNo }}</span>
                                <el-tooltip v-else class="item" effect="dark" :content="scope.row.caseNo" placement="top-start">
                                    <span>{{ scope.row.caseNo.slice(0, 6) + '...' }}</span>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                        <el-table-column prop="policyNo" label="保单号">
                            <template slot-scope="scope">
                                <span v-if="scope.row.policyNo.length < 10">{{ scope.row.policyNo }}</span>
                                <el-tooltip v-else class="item" effect="dark" :content="scope.row.policyNo" placement="top-start">
                                    <span>{{ scope.row.policyNo.slice(0, 6) + '...' }}</span>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                        <el-table-column prop="insureCompany" label="保险公司">
                            <template slot-scope="scope">
                                <span v-if="scope.row.insureCompany.length < 8">{{ scope.row.insureCompany }}</span>
                                <el-tooltip v-else class="item" effect="dark" :content="scope.row.insureCompany" placement="top-start">
                                    <span>{{ scope.row.insureCompany.slice(0, 5) + '...' }}</span>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                        <el-table-column prop="accidentAddress" label="事故地点">
                            <template slot-scope="scope">
                                <span v-if="scope.row.accidentAddress.length < 8">{{ scope.row.accidentAddress }}</span>
                                <el-tooltip v-else class="item" effect="dark" :content="scope.row.accidentAddress" placement="top-start">
                                    <span>{{ scope.row.accidentAddress.slice(0, 4) + '...' }}</span>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                        <el-table-column prop="insureType" label="险种" width="110"></el-table-column>
                        <el-table-column prop="reportTime" label="报案时间" width="135"></el-table-column>
                        <el-table-column prop="closeTime" label="结案时间" width="135"></el-table-column>
                        <el-table-column prop="caseStatus" label="案件状态" width="70"></el-table-column>
                        <el-table-column prop="consumeTimeStr" label="案件总用时"></el-table-column>
                        <el-table-column prop="payAmount" label="实际赔付金额"></el-table-column>
                        <el-table-column prop="adjustAmount" label="人工理算金额"></el-table-column>
                        <el-table-column prop="diffAmount" label="差价" width="90"></el-table-column>
                        <el-table-column prop="username" label="处理人"></el-table-column>
                        <el-table-column label="操作">
                            <template slot-scope="scope">
                                <span class="a_color" @click="lookDetails(scope.row, true, scope.$index)">处理</span>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!--分页部分-->
                    <el-pagination @current-change="handleCurrentChange" @size-change="handleSizeChange" :current-page="navForm.page" layout="total, sizes, prev, pager, next, jumper" :page-sizes="[10, 20]" :page-size="navForm.size" :total="navForm.total"> </el-pagination>
                </div>
            </div>
        </div>
        <!-- 案件详情 -->
        <div class="case_page" v-if="caseInfo">
            <div class="page_title cursor" @click="lookDetails(null, false)"><b>< 返回</b></div>
            <case-info :case-data="caseRowData" @submitCase="submitCase"></case-info>
        </div>
    </div>
</template>
<script>
import CaseInfo from '@/components/staging/index'
export default {
    components: {
        CaseInfo
    },
    data() {
        return {
            navForm: {
                // startTime: '2023-05-27 17:11:44',
                // endTime: '2023-05-27 13:28:02',
                // caseNo: '6882020080703202305271000014'
                // policyNo: 'ACHDLQR37123FP0S9NZE',
                reportStartTime: '',
                reportEndTime: '',
                commitStatus: '1',
                caseNo: '',
                policyNo: '',
                size: 10,
                page: 1,
                total: 1
            },
            loading: false,
            caseInfo: false,
            tableData: [],
            caseRowData: {}
        }
    },
    mounted() {
        this.getTaskList()
    },
    methods: {
        searchTable() {},
        claimTask() {
            this.instance.post('/caseJobCheck/allocationCaseJob', {}).then((res) => {
                let data = res.data
                if (data.code == 1) {
                    this.getTaskList()
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },
        //点击查看详情
        lookDetails(row, showFlag, rowIndex) {
            showFlag &&
                this.instance.post(`/caseJobCheck/handleCaseJob/${row.caseNo}`, {}).then((res) => {
                    let data = res.data
                    if (data.code == 1) {
                        this.caseRowData = data.data
                        this.caseRowData.submit = true
                        this.caseRowData.rowIndex = rowIndex
                    }
                })
            if (!showFlag) {
                let tmpCaseData = sessionStorage.getItem('caseData') ? JSON.parse(sessionStorage.getItem('caseData')) : {}
                this.$set(this.tableData, tmpCaseData.rowIndex, tmpCaseData)
            }
            this.caseInfo = showFlag
        },
        getTaskList(btnFlag) {
            let queryForm = JSON.parse(JSON.stringify(this.navForm))
            queryForm.page = btnFlag ? 1 : queryForm.page
            queryForm.size = btnFlag ? 10 : queryForm.size
            this.instance.post('/caseJobCheck/myPageJob', queryForm).then((res) => {
                let data = res.data
                if (data.code == 1) {
                    this.tableData = data.data.list
                    this.navForm.size = data.data.size
                    this.navForm.page = data.data.current
                    this.navForm.total = data.data.total
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },
        handleSizeChange(val) {
            this.navForm.size = val
            this.getTaskList()
        },
        handleCurrentChange(val) {
            this.navForm.page = val
            this.getTaskList()
        },
        submitCase(val) {
            this.caseInfo = false
            this.getTaskList()
        }
    }
}
</script>
<style>
@import '../../assets/css/table_public.css';
.case_page {
}
</style>