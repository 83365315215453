<template>
    <div class="wrap">
        <el-carousel indicator-position="none" :interval="5000" :initial-index="0" arrow="always" :autoplay="false" @change="carouselChange">
            <!-- 第一屏 -->
            <el-carousel-item>
                <div class="wrap_carousel1">
                    <div class="wrap_carousel_top">
                        <div class="update_time1">
                            <el-select class="time_select" v-model="navForm1.type" placeholder="请选择" @change="timeSelectChange(1)">
                                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                            </el-select>
                            <el-date-picker v-model="reportTime1" type="daterange" @change="reportTime1Change" start-placeholder="开始日期" end-placeholder="结束日期" range-separator="至" value-format="yyyy-MM-dd" size="mini" :default-time="['00:00:00', '23:59:59']"> </el-date-picker>
                        </div>
                        <div class="home_title">作业质量监控</div>
                        <div class="home_title" style="visibility: hidden">业务质量监控</div>
                        <el-button class="download_btn" type="primary" :loading="downloadFlag1" @click="downloadData(1)">下 载</el-button>
                    </div>
                    <div class="wrap_parent wrap_content">
                        <div class="wrap_left">
                            <!-- 分包商风险案件排行榜 -->
                            <risk-case :isCollapse="isCollapse" :navForm="navForm1" echartsType="1"></risk-case>
                            <!-- 分包商风险案件 风险案件占比 -->
                            <risk-case :isCollapse="isCollapse" :navForm="navForm1" echartsType="2"></risk-case>
                            <!-- 分包商风险案件 风险标签 -->
                            <risk-case-tag :isCollapse="isCollapse" :navForm="navForm1" echartsType="2"></risk-case-tag>
                            <!-- 报案时效统计 -->
                            <report-time :isCollapse="isCollapse" :navForm="navForm1"></report-time>
                        </div>
                        <div class="wrap_center">
                            <div class="sum_num" v-show="0">
                                <span>总风险案件数：<countTo :startVal="riskCaseReduceAmount.riskCaseNum" :endVal="riskCaseReduceAmount.riskCaseNum1" :duration="1500"></countTo>件</span>
                                <span>总减损金额：<countTo :startVal="riskCaseReduceAmount.reduceAmountNum" :endVal="riskCaseReduceAmount.reduceAmountNum1" :duration="1500"></countTo>万元</span>
                            </div>
                            <!-- <china-map3 :isCollapse="isCollapse" :navForm="navForm1" v-show="0"></china-map3> -->
                            <!-- 事故省风险案件排行榜 -->
                            <province-risk :isCollapse="isCollapse" :navForm="navForm1" echartsType="1"></province-risk>
                            <!-- 事故省风险案件占比排行榜 top15  -->
                            <province-risk :isCollapse="isCollapse" :navForm="navForm1" echartsType="2"></province-risk>
                            <!-- 事故省风险案件占比排行榜 风险标签  -->
                            <province-risk-tag :isCollapse="isCollapse" :navForm="navForm1" echartsType="2"></province-risk-tag>
                            <!-- 估损偏差率统计 -->
                            <depletion-sum :isCollapse="isCollapse" :navForm="navForm1"></depletion-sum>
                        </div>
                        <div class="wrap_right wrap_right3">
                            <div class="update_time1" v-if="0">
                                <el-date-picker v-model="closingTime" type="daterange" @change="closingTimeChange" start-placeholder="开始日期" end-placeholder="结束日期" range-separator="至" value-format="yyyy-MM-dd" size="mini" :default-time="['00:00:00', '23:59:59']"> </el-date-picker>
                            </div>
                            <!-- 公估风险案件统计 -->
                            <case-number-ratio :isCollapse="isCollapse" :navForm="navForm1" echartsType="1"></case-number-ratio>
                            <!-- 公估风险案件占比统计 -->
                            <case-number-ratio :isCollapse="isCollapse" :navForm="navForm1" echartsType="2"></case-number-ratio>
                            <!-- 公估案件处理结案排行榜 -->
                            <processing-time :isCollapse="isCollapse" :navForm="navForm1"></processing-time>
                            <!-- 近7天报案结案量统计 -->
                            <case-7days-count :isCollapse="isCollapse" :navForm="navForm1"></case-7days-count>
                        </div>
                    </div>
                </div>
            </el-carousel-item>
            <!-- 第二屏 -->
            <el-carousel-item v-loading="showloading2" element-loading-text="加载中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.1)">
                <div class="wrap_carousel2">
                    <div class="wrap_carousel_top">
                        <div class="update_time1">
                            <el-select class="time_select" v-model="navForm2.type" placeholder="请选择" @change="timeSelectChange(2)">
                                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                            </el-select>
                            <el-date-picker v-model="reportTime2" type="daterange" @change="reportTime2Change" start-placeholder="开始日期" end-placeholder="结束日期" range-separator="至" value-format="yyyy-MM-dd" size="mini" :default-time="['00:00:00', '23:59:59']"> </el-date-picker>
                        </div>
                        <div class="home_title">业务质量监控</div>
                        <div class="update_time1" style="visibility: hidden">
                            <p>事故省：</p>
                            <el-select v-model="provinceValue" @change="provinceChange" style="width: 100px">
                                <el-option v-for="item in provinceOption" :key="item.provinceName" :label="item.provinceName" :value="item.provinceName"> </el-option>
                            </el-select>
                        </div>
                        <el-button class="download_btn" type="primary" :loading="downloadFlag2" @click="downloadData(2)">下 载</el-button>
                    </div>
                    <div class="wrap_parent" v-if="delayLoading2">
                        <div class="wrap_carousel_top">
                            <!-- 案均赔款金额 -->
                            <reparations-amount :navForm="navForm2"></reparations-amount>
                            <!-- 赔款金额分类（占比） -->
                            <reparations-amount-type :isCollapse="isCollapse" :navForm="navForm2"></reparations-amount-type>
                            <!-- 结案时长分布 -->
                            <!-- <lawsuit-times :isCollapse="isCollapse" :navForm="navForm2"></lawsuit-times> -->
                            <!-- 总赔款金额变换趋势 -->
                            <all-reparations-amount :isCollapse="isCollapse" :navForm="navForm2"></all-reparations-amount>
                        </div>
                        <div class="wrap_carousel_bottom">
                            <!-- 全国车物损案均金额统计 -->
                            <delay-report :isCollapse="isCollapse" :navForm="navForm2"></delay-report>
                            <!--  全国人伤损案均金额统计 -->
                            <passing-rate :isCollapse="isCollapse" :navForm="navForm2"></passing-rate>
                        </div>
                        <div class="wrap_carousel_bottom">
                            <!-- 全国伤残案均金额统计 -->
                            <assessment-deviation :isCollapse="isCollapse" :navForm="navForm2"></assessment-deviation>
                            <!-- 全国死亡案均金额统计 -->
                            <case-num-sum :isCollapse="isCollapse" :navForm="navForm2"></case-num-sum>
                        </div>
                    </div>
                </div>
            </el-carousel-item>
            <!-- 第三屏 -->
            <el-carousel-item class="carousel3" v-loading="showloading3" element-loading-text="加载中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.1)">
                <div class="wrap_carousel_top">
                    <div class="update_time1">
                        <el-select class="time_select" v-model="navForm3.type" placeholder="请选择" @change="timeSelectChange(3)">
                            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                        </el-select>
                        <el-date-picker v-model="reportTime3" type="daterange" @change="reportTime3Change" start-placeholder="开始日期" end-placeholder="结束日期" range-separator="至" value-format="yyyy-MM-dd" size="mini" :default-time="['00:00:00', '23:59:59']"> </el-date-picker>
                    </div>
                    <div class="home_title">综合分析</div>
                    <div class="update_time2" style="visibility: hidden">
                        <p>事故省：</p>
                        <el-select v-model="provinceValue" @change="provinceChange" style="width: 100px">
                            <el-option v-for="item in provinceOption" :key="item.provinceName" :label="item.provinceName" :value="item.provinceName"> </el-option>
                        </el-select>
                    </div>
                    <el-button class="download_btn" type="primary" :loading="downloadFlag3" @click="downloadData(3)">下 载</el-button>
                </div>
                <div class="wrap_carousel wrap_carousel3" v-if="delayLoading3">
                    <div class="wrap_left wrap_left_40">
                        <settle-lawsuit :navForm="navForm3"></settle-lawsuit>
                        <!-- 报案时效统计 -->
                        <!-- <case-time :isCollapse="isCollapse" :navForm="navForm3" v-if="0"></case-time> -->
                        <!-- 结案周期分布 -->
                        <!-- <case-cycle :isCollapse="isCollapse" :navForm="navForm3" v-if="0"></case-cycle> -->
                        <!-- 案件风险等级近七日变换趋势 -->
                        <risk-grade :isCollapse="isCollapse" :navForm="navForm3"></risk-grade>
                        <!-- 案件风险占比分布 -->
                        <risk-point :isCollapse="isCollapse" :navForm="navForm3"></risk-point>
                    </div>
                    <div class="wrap_center wrap_left_59">
                        <!-- <china-map :isCollapse="isCollapse" :navForm="navForm3" v-if="0"></china-map> -->
                        <!-- 公估公司接案量统计 -->
                        <case-rate :isCollapse="isCollapse" :navForm="navForm3"></case-rate>
                        <!-- 风险案件数量&金额统计 -->
                        <risk-number-amount :isCollapse="isCollapse" :navForm="navForm3"></risk-number-amount>
                    </div>
                    <div class="wrap_right" v-if="0"></div>
                </div>
            </el-carousel-item>
        </el-carousel>
    </div>
</template>
<script>
import SettleLawsuit from './components/SettleLawsuit.vue';
import CaseTime from './components/CaseTime.vue';
import CaseCycle from './components/CaseCycle.vue';
import RiskNumberAmount from './components/RiskNumberAmount.vue';
import RiskGrade from './components/RiskGrade.vue';
import RiskPoint from './components/RiskPoint.vue';
import ChinaMap from './components/ChinaMap.vue';
import ChinaMap3 from './components/ChinaMap3.vue';
import CaseRate from './components/CaseRate.vue';
import RiskCase from './components/RiskCase.vue';
import RiskCaseTag from './components/RiskCaseTag.vue';
import ProvinceRisk from './components/ProvinceRisk.vue';
import ProvinceRiskTag from './components/ProvinceRiskTag.vue';
import CaseNumberRatio from './components/CaseNumberRatio.vue';
import ReportTime from './components/ReportTime.vue';
import DepletionSum from './components/DepletionSum.vue';
import ProcessingTime from './components/ProcessingTime.vue';
import Case7daysCount from './components/CasedaysCount.vue';
import ReparationsAmount from './components/ReparationsAmount.vue';
import LawsuitTimes from './components/LawsuitTimes.vue';
import AllReparationsAmount from './components/AllReparationsAmount.vue';
import DelayReport from './components/DelayReport.vue';
import ReparationsAmountType from './components/ReparationsAmountType.vue';
import PassingRate from './components/PassingRate.vue';
import AssessmentDeviation from './components/AssessmentDeviation.vue';
import CaseNumSum from './components/CaseNumSum.vue';
import countTo from 'vue-count-to';
export default {
    name: 'bigScreen',
    components: {
        countTo,
        SettleLawsuit,
        CaseTime,
        CaseCycle,
        RiskNumberAmount,
        RiskGrade,
        RiskPoint,
        ChinaMap,
        CaseRate,
        LawsuitTimes,
        CaseNumSum,
        PassingRate,
        AssessmentDeviation,
        ReparationsAmountType,
        DelayReport,
        AllReparationsAmount,
        RiskCase,
        RiskCaseTag,
        ProvinceRisk,
        ProvinceRiskTag,
        DepletionSum,
        ProcessingTime,
        ReparationsAmount,
        CaseNumberRatio,
        ChinaMap3,
        ReportTime,
        Case7daysCount
    },
    props: {
        isCollapse: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            downloadFlag1: false,
            downloadFlag2: false,
            downloadFlag3: false,
            showloading2: true,
            showloading3: true,
            delayLoading2: false,
            delayLoading3: false,
            updateTime: '', //案件更新时间
            reportTime3: '', //第三屏的报案时间
            reportTime2: '', //第二屏的报案时间
            reportTime1: '', //第一屏的报案时间
            closingTime: '', //结案时间
            navForm3: {
                startTime: '',
                endTime: '',
                type: '1'
            },
            navForm2: {
                startTime: '',
                endTime: '',
                accidentProvince: '全国',
                type: '1'
            },
            navForm1: {
                startTime: '',
                endTime: '',
                closeCaseStartTime: '',
                closeCaseEndTime: '',
                type: '1'
            },
            riskCaseReduceAmount: {
                reduceAmountNum: 0,
                riskCaseNum: 0,
                reduceAmountNum1: 0,
                riskCaseNum1: 0
            },
            timer: null,
            provinceValue: '全国', //当前事故省
            provinceOption: [],
            options: [
                {
                    value: '1',
                    label: '报案时间'
                },
                {
                    value: '2',
                    label: '事故时间'
                },
                {
                    value: '3',
                    label: '结案时间'
                }
            ],
            carouselFlag: false
        };
    },
    mounted() {
        var date = new Date();
        // date.setDate(0);//获取上个月的最后一天
        let startDateUpdate = new Date(date.getTime() - 3600 * 1000 * 24 * 31);
        let startDate = new Date(date.getTime() - 3600 * 1000 * 24 * 365);
        let endDate = new Date(date.getTime() - 3600 * 1000 * 24 * 1);
        // this.updateTime=this.dateFormatting(startDateUpdate,'yyyy-MM-dd')+'~'+this.dateFormatting(endDate,'yyyy-MM-dd');
        this.reportTime1 = [this.dateFormatting(startDate, 'yyyy-MM-dd'), this.dateFormatting(endDate, 'yyyy-MM-dd')];
        this.reportTime2 = [this.dateFormatting(startDate, 'yyyy-MM-dd'), this.dateFormatting(endDate, 'yyyy-MM-dd')];
        this.reportTime3 = [this.dateFormatting(startDate, 'yyyy-MM-dd'), this.dateFormatting(endDate, 'yyyy-MM-dd')];
        // this.reportTime3=[this.getStartDate(),this.dateFormatting(date,'yyyy-MM-dd')];
        this.navForm3.startTime = this.reportTime3[0];
        this.navForm3.endTime = this.reportTime3[1];
        this.navForm2.startTime = this.reportTime2[0];
        this.navForm2.endTime = this.reportTime2[1];
        this.navForm1.startTime = this.reportTime1[0];
        this.navForm1.endTime = this.reportTime1[1];

        // 获取省份
        // this.getProvinceName();
        // this.getRiskCaseReduceAmount('one');
        // this.timer = setInterval(() => {
        //     this.getRiskCaseReduceAmount();
        // }, 60000);
        setTimeout(() => {
            if (this.showloading2) {
                this.showloading2 = false;
                this.delayLoading2 = true;
            }
        }, 10000);
        setTimeout(() => {
            if (this.showloading3) {
                this.showloading3 = false;
                this.delayLoading3 = true;
            }
        }, 60000);
    },
    methods: {
        downloadData(type) {
            let fileName = {
                1: '业务质量监控',
                2: '业务质量监控',
                3: '业务质量监控'
            };
            this[`downloadFlag${type}`] = true;
            this.instance({
                method: 'post',
                url: `/large/data/screen/downloadScreenData${type}`,
                data: this[`navForm${type}`],
                responseType: 'blob'
            }).then((res) => {
                this[`downloadFlag${type}`] = false;
                let url = window.URL.createObjectURL(new Blob([res.data]));
                let link = document.createElement('a');
                link.style.display = 'none';
                link.href = url;
                link.setAttribute('download', decodeURIComponent(res.headers.filename)); //指定下载后的文件名，防跳转
                document.body.appendChild(link);
                link.click();
            });
        },
        carouselChange(carouselIndex) {
            switch (carouselIndex) {
                case 1:
                    if (this.showloading2) {
                        this.showloading2 = false;
                        this.delayLoading2 = true;
                    }

                    break;
                case 2:
                    if (this.showloading3) {
                        this.showloading3 = false;
                        this.delayLoading3 = true;
                    }

                    break;

                default:
                    break;
            }
        },
        //获取事故省
        getProvinceName() {
            this.instance({
                method: 'post',
                url: '/large/data/screen/getProvinceName',
                data: this.navForm2,
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8'
                }
            }).then((res) => {
                if (res.data.code == 1) {
                    var obj = {
                        provinceName: '全国'
                    };
                    res.data.data.unshift(obj);
                    this.provinceOption = res.data.data;
                }
            });
        },
        //风险案件减损金额
        getRiskCaseReduceAmount(sign) {
            this.instance({
                method: 'post',
                url: '/large/data/screen/riskCaseReduceAmount',
                data: this.navForm1,
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8'
                }
            }).then((res) => {
                if (res.data.code == 1) {
                    var obj = res.data.data;
                    Object.keys(obj).forEach((key) => {
                        obj[key] = parseInt(obj[key]);
                    });
                    if (sign != 'one') {
                        this.riskCaseReduceAmount.reduceAmountNum = this.riskCaseReduceAmount.reduceAmountNum1 || 0;
                        this.riskCaseReduceAmount.riskCaseNum = this.riskCaseReduceAmount.riskCaseNum1 || 0;
                    }
                    this.riskCaseReduceAmount.reduceAmountNum1 = obj.reduceAmountNum || 0;
                    this.riskCaseReduceAmount.riskCaseNum1 = obj.riskCaseNum || 0;
                }
            });
        },
        //第一屏报案时间change事件
        reportTime1Change(val) {
            this.navForm1.startTime = this.reportTime1[0];
            this.navForm1.endTime = this.reportTime1[1];
            // this.getRiskCaseReduceAmount('one');
        },
        //第二屏报案时间change事件
        reportTime2Change(val) {
            let difference = new Date(this.reportTime2[1]).getTime() - new Date(this.reportTime2[0]).getTime();
            if (difference < 6 * 24 * 60 * 60 * 1000) {
                this.$message.error('抱歉，报案时间间隔需大于7天！');
            } else {
                this.navForm2.startTime = this.reportTime2[0];
                this.navForm2.endTime = this.reportTime2[1];
                this.navForm2.accidentProvince = '全国';
                this.provinceValue = '全国';
                this.getProvinceName();
            }
        },
        //第三屏报案时间change事件
        reportTime3Change(val) {
            let difference = new Date(this.reportTime3[1]).getTime() - new Date(this.reportTime3[0]).getTime();
            if (difference < 6 * 24 * 60 * 60 * 1000) {
                this.$message.error('抱歉，报案时间间隔需大于7天！');
            } else {
                this.navForm3.startTime = this.reportTime3[0];
                this.navForm3.endTime = this.reportTime3[1];
            }
        },
        timeSelectChange(selectNum) {
            this[`reportTime${selectNum}Change`]();
        },
        //结案时间change事件
        closingTimeChange(val) {
            this.navForm1.closeCaseStartTime = this.closingTime && this.closingTime[0] ? this.closingTime[0] : null;
            this.navForm1.closeCaseEndTime = this.closingTime && this.closingTime[1] ? this.closingTime[1] : null;
            // this.getRiskCaseReduceAmount('one');
        },
        //事故省change事件
        provinceChange(val) {
            this.navForm2.accidentProvince = val;
        },
        //获取上个月的第一天
        getStartDate() {
            var nowdays = new Date();
            var year = nowdays.getFullYear();
            var month = nowdays.getMonth();
            if (month == 0) {
                month = 12;
                year = year - 1;
            }
            if (month < 10) {
                month = '0' + month;
            }
            var myDate = new Date(year, month, 0);
            var startDate = year + '-' + month + '-01'; //上个月第一天
            return startDate;
        }
    },
    destroyed() {
        clearInterval(this.timer);
    },
    watch: {
        $route: {
            handler: function (route) {
                this.redirect = route.query && route.query.redirect;
            },
            immediate: true
        }
    }
};
</script>
<style scoped lang="less">
.wrap,
.wrap .el-carousel,
.wrap .el-carousel .el-carousel__container {
    height: 100% !important;
}
.wrap .el-carousel /deep/ .el-carousel__container {
    height: 100% !important;
    margin: 0 1px;
}
/deep/.el-carousel__arrow {
    font-size: 32px;
}
.update_time1 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    /* padding:0.08rem 0.2rem; */
    background-color: #143351;
    margin-bottom: 0.15rem;
    // position: relative;
    .time_select {
        width: 30%;
    }
}
.update_time1 > p {
    width: 70px;
}
.update_time1 /deep/.el-date-editor--daterange.el-input__inner,
.update_time1 /deep/.el-input__inner {
    width: 230px;
    background-color: transparent;
    border: none;
    color: #fff;
    /* padding:5px 0; */
}
.update_time1 /deep/.el-input__inner {
    width: auto;
}
/deep/.el-date-editor .el-range-separator {
    width: auto;
    color: #fff;
}
/deep/.el-date-editor .el-range-input {
    color: #fff;
    background-color: transparent;
}
.wrap {
    min-height: 100%;
    width: 100%;
    background-color: #0a1a2e;
    padding: 0.3rem 0.2rem;
    color: #fff;
}
.wrap_carousel {
    min-height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
}
.carousel3,
.wrap_carousel1,
.wrap_carousel2,
.wrap_carousel3 {
    .download_btn {
        height: 30px;
    }

    .wrap_carousel_top {
        .home_title {
            font-size: 20px;
        }
    }
}
.wrap_carousel3 {
    position: relative;
    height: 100%;
    overflow: scroll;
    .download_btn {
        position: absolute;
        right: 2px;
    }
    .wrap_left,
    .wrap_center {
        height: 105%;
    }
}
.carousel3 {
    .wrap_carousel_top {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .home_title {
            width: 40%;
            height: 34px;
            line-height: 30px;
            margin-bottom: 0;
        }
        .update_time1 {
            width: 27%;
            height: 34px;
            // margin-bottom: 0 !important;
        }
        .update_time2 {
            width: 27%;
            height: 34px;
            margin-bottom: 0;
        }
    }
}
.wrap_carousel1,
.wrap_carousel2 {
    min-height: 100%;
    width: 100%;
    position: relative;

    .wrap_carousel_top,
    .wrap_carousel_middle,
    .wrap_carousel_bottom {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    .wrap_carousel_top > div,
    .wrap_carousel_middle > div {
        width: 32%;
    }
    .wrap_carousel_bottom > div {
        width: 49%;
    }
    .wrap_carousel_top {
        .home_title {
            height: 32px;
            line-height: 32px;
        }
    }
    .wrap_parent {
        height: 87vh;
        overflow-y: scroll;
        padding-bottom: 15px;
    }
    .wrap_content {
        display: flex;
        justify-content: space-between;
    }
}
.wrap_carousel1 {
    .wrap_carousel_top {
        justify-content: space-between;
        .update_time1 {
            width: 27% !important;
        }
        .home_title {
            text-align: center;
            width: 40%;
            height: 32px;
            line-height: 32px;
            margin-bottom: 0 !important;
        }
        .home_title:nth-child(3) {
            width: 27%;
        }
    }
    .wrap_left,
    .wrap_center,
    .wrap_right {
        height: 138%;
    }
}
.wrap_left,
.wrap_right {
    width: 27%;
    text-align: center;
}
.wrap_left_40 {
    width: 40%;
}
.wrap_left_59 {
    width: 59% !important;
}
.wrap_right {
    padding-top: 0.55rem;
}
.wrap_right3 {
    padding-top: 0;
}
.wrap_center {
    width: 42%;
}
.update_time {
    padding: 0.08rem 0.6rem;
    background-color: #143351;
    display: inline-block;
    margin-bottom: 0.15rem;
    font-size: 0.18rem;
}
.sum_num {
    font-size: 0.22rem;
    height: 24px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 0.2rem;
    color: #62f9ff;
}
/* /deep/ .el-carousel__item {
    overflow: auto;
} */
</style>
