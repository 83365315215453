<template>
    <div :class="echartsType == '1' ? 'module_wrap box_border' : 'module_wrap module_wrap2 box_border'" v-loading="loading" element-loading-text="数据加载中" element-loading-spinner="el-icon-loading" element-loading-background="transparent">
        <div class="box_title box_title_radio">
            <span>分包商风险案件标签排行榜</span>
        </div>
        <div style="height: 100%" v-show="!loading">
            <div id="risk_case_bar_tag"></div>
        </div>
    </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
    name: 'RiskCaseTag',
    props: {
        isCollapse: {
            type: Boolean,
            default: true
        },
        navForm: {
            type: Object,
            default: {}
        },
        echartsType: {
            type: String,
            default: ''
        }
    },
    watch: {
        isCollapse() {
            setTimeout(() => {
                this.myChart && this.myChart.resize();
            }, 600);
        },
        navForm: {
            deep: true,
            handler(val) {
                this.getData();
            }
        }
    },
    data() {
        return {
            loading: true,
            topDays: [],
            radio: '数量',
            newNumber: 0,
            xAxisData1: [],
            xAxisData2: [],
            sumSeriesData1: [],
            sumSeriesData2: [],
            numSeriesData1: [],
            numSeriesData2: [],
            option: '',
            myChart: null
        };
    },
    mounted() {
        this.role = localStorage.getItem('role');
        window.addEventListener('resize', () => {
            this.myChart && this.myChart.resize();
        });
        window.onresize = () => {
            this.myChart && this.myChart.resize();
        };
    },
    methods: {
        getData() {
            if (this.echartsType) {
                this.loading = true;
                let instancePath = null;
                switch (this.echartsType) {
                    case '1':
                        instancePath = '/large/data/screen/getSubcontractorCasesLabelTopTen';
                        break;
                    case '2':
                        instancePath = '/large/data/screen/getSubcontractorCasesLabelTopTen';
                        break;

                    default:
                        break;
                }
                instancePath &&
                    this.instance({
                        method: 'post',
                        url: instancePath,
                        data: this.navForm,
                        headers: {
                            'Content-Type': 'application/json;charset=UTF-8'
                        }
                    }).then((res) => {
                        this.loading = false;
                        if (res.data.code == 1) {
                            let response = res.data.data;
                            this.loadCharts(response);
                        }
                    });
            }
        },
        //数量&金额change事件
        radioChange() {
            if (this.option) {
                if (this.radio == '数量') {
                    this.option.xAxis[0].data = this.xAxisData1;
                    this.option.series[0].data = this.numSeriesData1;
                    this.option.series[1].data = this.numSeriesData2;
                } else {
                    this.option.xAxis[0].data = this.xAxisData2;
                    this.option.series[0].data = this.sumSeriesData1;
                    this.option.series[1].data = this.sumSeriesData2;
                }
                setTimeout(() => {
                    this.myChart.setOption(this.option);
                }, 100);
            }
        },
        loadCharts(response) {
            var chartDom = document.getElementById('risk_case_bar_tag');
            if (!chartDom) {
                return;
            }
            this.myChart = echarts.init(chartDom, null, { renderer: 'svg' });
            this.xAxisData1 = [];
            this.sumSeriesData1 = [];
            this.sumSeriesData2 = [];
            this.xAxisData2 = [];
            this.numSeriesData1 = [];
            this.numSeriesData2 = [];
            response &&
                response.caseRiskNum.forEach((val, key) => {
                    this.xAxisData1.push(val.name);
                    this.numSeriesData1.push(val.caseNumRatio);
                    this.numSeriesData2.push(val.caseNum);
                });
            response &&
                response.caseRiskMoney &&
                response.caseRiskMoney.length > 0 &&
                response.caseRiskMoney.forEach((val, key) => {
                    this.xAxisData2.push(val.name);
                    this.sumSeriesData1.push(val.caseNum);
                    this.sumSeriesData2.push(val.caseNum);
                });

            this.option = {
                color: ['#96b4d8', '#09d5d6'],
                tooltip: {
                    trigger: 'axis',
                    confine: true,
                    textStyle: {
                        align: 'left'
                    }
                },
                grid: {
                    top: '5',
                    left: '10',
                    right: '10',
                    bottom: '15',
                    containLabel: true
                },
                legend: {
                    name: '',
                    data: ['案件数量', '赔款金额'],
                    itemWidth: 12,
                    itemHeight: 12,
                    textStyle: {
                        color: '#fff'
                        // fontSize: '0.12rem'
                    },
                    left: 'center'
                },
                xAxis: [
                    {
                        type: 'category',
                        data: this.xAxisData1,
                        axisPointer: {
                            type: 'shadow'
                        },
                        axisLabel: {
                            showMaxLabel: true,
                            rotate: 50,
                            textStyle: {
                                // fontSize: '0.12rem',
                                color: '#fff' //坐标值得具体的颜色
                            },
                            width: 60,
                            overflow: 'truncate',
                            ellipsis: '...'
                        },
                        axisTick: {
                            alignWithLabel: true
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        axisLabel: {
                            showMaxLabel: true,
                            formatter: '{value} %',
                            textStyle: {
                                // fontSize: '0.12rem',
                                color: '#fff' //坐标值得具体的颜色
                            }
                        },
                        splitLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        }
                    },
                    {
                        type: 'value',
                        axisLabel: {
                            showMaxLabel: true,
                            formatter: '{value} 万',
                            textStyle: {
                                // fontSize: '0.12rem',
                                color: '#fff' //坐标值得具体的颜色
                            }
                        },

                        splitLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        }
                    }
                ],
                series: [
                    {
                        name: '风险标签案件占比',
                        type: 'bar',
                        tooltip: {
                            valueFormatter: function (value) {
                                return value + ' %';
                            }
                        },
                        data: this.numSeriesData1
                    }
                ]
            };
            this.myChart.setOption(this.option);
        }
    },
    updated() {
        this.myChart && this.myChart.resize();
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.module_wrap {
    width: 100%;
    height: 3.7rem;
    padding: 0.15rem 0.3rem 0;
    margin-top: 0.1rem;
}
.module_wrap2 {
    margin-top: 0.2rem;
}
#risk_case_bar_tag {
    width: 100%;
    height: 90%;
    margin-top: 0.1rem;
}
</style>
