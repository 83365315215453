<template>
    <div class="module_wrap box_border">
        <div class="box_border ratio_box">
            <div>
                <b>结案率：</b>
                <span>{{ casesData.closingRate }}%</span>
            </div>
            <div>
                <b>24小时报案率：</b>
                <span>{{ casesData.durationOfFirstReportingDelay }}%</span>
            </div>
        </div>
        <ul class="ratio_ul">
            <li class="box_border">
                <b>已决案件</b>
                <div>{{ casesData.settledCaseNo }} (笔)</div>
            </li>
            <li class="box_border">
                <b>已决总赔款</b>
                <div>{{ casesData.totalCompensationAmount }} (万元)</div>
            </li>
            <li class="box_border">
                <b>未决案件</b>
                <div>{{ casesData.unsettledCaseNo }} (笔)</div>
            </li>
            <li class="box_border">
                <b>未决总估损</b>
                <div>{{ casesData.predictCompensationAmount }} (万元)</div>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'SettleLawsuit',
    props: {
        navForm: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },
    watch: {
        navForm: {
            // immediate: true,
            deep: true,
            handler(val) {
                this.getData();
            }
        }
    },
    data() {
        return {
            casesData: {},
            newNumber: 0
        };
    },
    mounted() {
        this.role = localStorage.getItem('role');
        this.getData();
    },
    methods: {
        getData() {
            this.instance({
                method: 'post',
                url: '/large/data/screen/meituanCaseData',
                data: this.navForm,
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8'
                }
            }).then((res) => {
                if (res.data.code == 1) {
                    this.casesData = res.data.data;
                }
            });
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.module_wrap {
    width: 100%;
    /* height: 3.9rem; */
    /* padding: 0.15rem 0.3rem; */
    padding: 15px 10px;
}
.ratio_box,
.ratio_ul li {
    /* height: 0.8rem; */
    height: 50px;
    text-align: center;
    /* font-size: 0.18rem; */
    font-size: 18px;
}
.ratio_box {
    /* line-height: 0.8rem; */
    line-height: 50px;
    display: flex;
    justify-content: space-around;
}
.ratio_box b,
.ratio_ul b {
    color: #62f9ff;
}
.ratio_ul {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.ratio_ul li {
    width: 46%;
    /* font-size: 0.16rem; */
    font-size: 14px;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    align-content: center;
    /* margin-top: 0.15rem; */
    margin-top: 15px;
}
.ratio_ul li div,
.ratio_ul li b {
    width: 100%;
    line-height: 1.5;
}
</style>
<style>
.wrap {
    padding: 0.2rem !important;
}
</style>
