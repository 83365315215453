<template>
    <div class="module_wrap box_border">
        <div class="box_border ratio_box">
            <div>
                <b>案均赔款金额：</b>
                <span>{{ casesData.averageLossAmount }}元</span>
            </div>
        </div>
        <ul class="ratio_ul">
            <li class="box_border">
                <b>车损案均</b>
                <div>{{ casesData.averageAmountOfVehicleDamageCases }} (元)</div>
            </li>
            <li class="box_border">
                <b>物损案均</b>
                <div>{{ casesData.averageAmountOfMaterialDamageCases }} (元)</div>
            </li>
            <li class="box_border">
                <b>门诊案均</b>
                <div>{{ casesData.averageAmountOfHumanInjuryCases }} (元)</div>
            </li>
            <li class="box_border">
                <b>死亡案均</b>
                <div>{{ casesData.averageAmountOfDeathCases }} (万元)</div>
            </li>
            <li class="box_border">
                <b>伤残案均</b>
                <div>{{ casesData.averageAmountOfDisabilityCases }} (元)</div>
            </li>
            <li class="box_border">
                <b>住院案均</b>
                <div>{{ casesData.averageAmountOfHospitalizationCases }} (元)</div>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'ReparationsAmount',
    props: {
        navForm: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            casesData: {},
            newNumber: 0
        };
    },
    watch: {
        navForm: {
            immediate: true,
            deep: true,
            handler(val) {
                this.getData();
            }
        }
    },
    mounted() {
        this.role = localStorage.getItem('role');
    },
    methods: {
        getData() {
            this.instance({
                method: 'post',
                url: '/large/data/screen/getBusinessQualityData',
                data: this.navForm,
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8'
                }
            }).then((res) => {
                if (res.data.code == 1) {
                    let data = res.data.data || {};
                    data.averageLossAmount = data.averageLossAmount && Math.round(data.averageLossAmount);
                    data.averageAmountOfVehicleDamageCases = data.averageAmountOfVehicleDamageCases && Math.round(data.averageAmountOfVehicleDamageCases);
                    data.averageAmountOfMaterialDamageCases = data.averageAmountOfMaterialDamageCases && Math.round(data.averageAmountOfMaterialDamageCases);
                    data.averageAmountOfHumanInjuryCases = data.averageAmountOfHumanInjuryCases && Math.round(data.averageAmountOfHumanInjuryCases);
                    data.averageAmountOfDeathCases = data.averageAmountOfDeathCases && Math.round(data.averageAmountOfDeathCases);
                    data.averageAmountOfHospitalizationCases = data.averageAmountOfHospitalizationCases && Math.round(data.averageAmountOfHospitalizationCases);
                    data.averageAmountOfDisabilityCases = data.averageAmountOfDisabilityCases && Math.round(data.averageAmountOfDisabilityCases);
                    this.casesData = data;
                }
            });
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.module_wrap {
    width: 100%;
    /* height: 3.3rem; */
    height: 4.43rem;
    /* padding: 0.15rem 0.3rem; */
    padding: 15px 10px;
}
.ratio_box,
.ratio_ul li {
    /* height: 0.63rem; */
    height: 40px;
    text-align: center;
    /* font-size: 0.18rem; */
    font-size: 18px;
}
.ratio_box {
    /* line-height: 0.56rem; */
    line-height: 40px;
    display: flex;
    justify-content: space-around;
}
.ratio_box b,
.ratio_ul b {
    color: #62f9ff;
}
.ratio_ul {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.ratio_ul li {
    width: 48%;
    /* font-size: 0.16rem; */
    font-size: 15px;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    align-content: center;
    /* margin-top: 0.15rem; */
    margin-top: 15px;
}
.ratio_ul li div,
.ratio_ul li b {
    /* width: 100%; */
    line-height: 1.5;
}
.ratio_ul li b {
    /* margin-right: 0.1rem; */
    margin-right: 5px;
}
.ratio_ul .box_border {
    justify-content: center;
}
</style>
