<template>
    <div class="wrap">
        <div class="page_title">
            <b>人伤险种赔付设置</b>
        </div>
        <div class="search_box public_box">
            <el-form :inline="true" :model="navForm" class="demo-form-inline">
                <el-form-item class="noneMarginBottom" label="险种分类：">
                    <el-select v-model="navForm.insureTypeId" filterable clearable class="width_150" placeholder="请选择分类">
                        <el-option v-for="(item, index) in insureTypeList" :key="index" :label="item.metaData" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item class="noneMarginBottom" label="险种名称：">
                    <el-input class="width_150" v-model="navForm.insureName" placeholder="请填写名称" clearable></el-input>
                </el-form-item>
                <el-form-item class="noneMarginBottom">
                    <el-button type="primary" @click="searchTable(true)">查询</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="table_box public_box">
            <p style="text-align: right; margin-bottom: 10px">
                <el-button type="primary" @click="dialogVisibleVerify = true">新增+</el-button>
            </p>
            <!--table表格部分-->
            <el-table ref="multipleTable" :data="tableData" style="width: 100%">
                <el-table-column prop="insureType" label="险种分类"></el-table-column>
                <el-table-column prop="insureName" label="险种名称">
                    <template slot-scope="scope">
                        <el-tooltip v-if="scope.row.insureName && scope.row.insureName.length > 13" effect="dark" :content="scope.row.insureName" placement="top-start">
                            <span>{{ scope.row.insureName.slice(0, 13) + '...' }}</span>
                        </el-tooltip>
                        <span v-else>{{ scope.row.insureName }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="thirdAmount" label="三者赔付最高限额（万元）" width="170"></el-table-column>
                <el-table-column prop="deliveryAmount" label="骑手医疗赔付责任限额（元）" width="180"></el-table-column>
                <el-table-column prop="rabidDogAmount" label="狂犬疫苗赔偿限额（元）" width="170"></el-table-column>
                <el-table-column prop="deductibleAmount" label="骑手医疗每次扣除免赔（元）" width="180"></el-table-column>
                <el-table-column prop="createUser" label="创建人"></el-table-column>
                <el-table-column prop="createTime" label="创建时间" width="140"></el-table-column>
            </el-table>
            <!--分页部分-->
            <el-pagination style="margin-top: 0.2rem" @current-change="handleCurrentChange" @size-change="handleSizeChange" :current-page="navForm.page" layout="total, sizes, prev, pager, next, jumper" :page-sizes="[10, 20]" :page-size="navForm.size" :total="navForm.totalPage"> </el-pagination>
        </div>
        <!-- 新增 弹窗 -->
        <el-dialog :visible.sync="dialogVisibleVerify" title="新增人伤险种赔付条款" :append-to-body="true" center width="50%" @close="saveAddForm(false)">
            <el-form class="unit-dialog_form" :inline="true" :model="addForm" :rules="addFormRules" ref="addFormRef" label-position="right" label-width="170px">
                <el-form-item class="form_select" label="险种分类：" prop="insureTypeId">
                    <el-select v-model="addForm.insureTypeId" placeholder="请选择" filterable>
                        <el-option v-for="(item, index) in insureTypeList" :key="index" :label="item.metaData" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="险种名称：" prop="insureName">
                    <el-input v-model="addForm.insureName" placeholder="请输入内容"></el-input>
                </el-form-item>
                <el-form-item label="三者赔付最高限额：" prop="thirdAmount"> <el-input v-model.number="addForm.thirdAmount" type="number" oninput="if(value.length > 13){value = value.slice(0,13)}" placeholder="请输入内容"></el-input>万元 </el-form-item>
                <el-form-item label="骑手医疗赔付责任限额：" prop="deliveryAmount"> <el-input v-model.number="addForm.deliveryAmount" type="number" oninput="if(value.length > 13){value = value.slice(0,13)}" placeholder="请输入内容"></el-input>元 </el-form-item>
                <el-form-item label="狂犬疫苗医疗赔偿限额：" prop="rabidDogAmount"> <el-input v-model.number="addForm.rabidDogAmount" type="number" oninput="if(value.length > 13){value = value.slice(0,13)}" placeholder="请输入内容"></el-input>元 </el-form-item>
                <el-form-item label="骑手医疗每次扣除免赔：" prop="deductibleAmount"> <el-input v-model.number="addForm.deductibleAmount" type="number" oninput="if(value.length > 13){value = value.slice(0,13)}" placeholder="请输入内容"></el-input>元 </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="saveAddForm(false)">取 消</el-button>
                <el-button type="primary" @click="saveAddForm(true)">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
export default {
    name: 'onlineTesting',
    components: {},
    data() {
        return {
            navForm: {
                insureTypeId: '',
                insureName: '',
                page: 1,
                size: 10,
                totalPage: 0
            },
            insureTypeList: [],
            options: [],
            tableData: [],
            dialogVisibleVerify: false,
            addForm: {
                insureTypeId: '',
                insureName: '',
                thirdAmount: '',
                deliveryAmount: '',
                rabidDogAmount: '',
                deductibleAmount: ''
            },
            addFormRules: {
                insureTypeId: [{ required: true, message: '请选择分类', trigger: 'change' }],
                insureName: [{ required: true, message: '请输入名称', trigger: 'blur' }]
            }
        }
    },
    mounted() {
        this.getInsureType()
        this.searchTable()
    },
    methods: {
        searchTable(searchFlag) {
            this.navForm.page = searchFlag ? 1 : this.navForm.page
            this.instance.post('/medicalFileLimit/searchPage', this.navForm).then((res) => {
                var data = res.data
                if (data.code == 1) {
                    this.tableData = data.data.list
                    this.navForm.totalPage = data.data.total
                }
            })
        },
        handleCurrentChange(val) {
            this.navForm.page = val
            this.searchTable()
        },
        handleSizeChange(val) {
            this.navForm.size = val
            this.searchTable()
        },
        saveAddForm(submitFlag) {
            if (submitFlag) {
                this.$refs.addFormRef.validate((valid) => {
                    if (valid) {
                        let tmpIndex = this.insureTypeList.findIndex((item) => {
                            return item.id == this.addForm.insureTypeId
                        })
                        this.addForm.insureType = tmpIndex != -1 ? this.insureTypeList[tmpIndex].metaData : ''

                        this.instance.post('/medicalFileLimit', this.addForm).then((res) => {
                            let data = res.data
                            if (data.code == 1) {
                                this.searchTable()
                                this.$message.success('新增人伤险种赔付条款成功')
                                this.dialogVisibleVerify = false
                                this.$refs.addFormRef.resetFields()
                            } else {
                                this.$message.error(data.message)
                            }
                        })
                    }
                })
            } else {
                this.dialogVisibleVerify = false
                this.$refs.addFormRef.resetFields()
            }
        },
        getInsureType() {
            this.instance.post('/backstage/getDict', { dictType: 'MEDICAL_LIMIT_TYPE' }).then((res) => {
                var data = res.data
                if (data.code == 1) {
                    this.insureTypeList = data.data
                }
            })
        }
    }
}
</script>
<style scope>
@import '../../../assets/css/table_public.css';
.unit-dialog_form {
    text-align: center;
}
.unit-dialog_form .el-form-item__content {
    width: 230px;
    text-align: left;
    /* border: 1px solid red; */
}
.unit-dialog_form .el-form-item__content .el-input {
    width: 180px;
    margin-right: 10px;
}
/* .form_select .el-input {
    width: 81.5% !important;
} */
</style>