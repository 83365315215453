<template>
    <div class="module_wrap box_border" v-loading="loading" element-loading-text="数据加载中" element-loading-spinner="el-icon-loading" element-loading-background="transparent">
        <div class="box_title">赔款金额分类（占比）</div>
        <div style="height: 100%" v-show="!loading">
            <div id="reparations_amount_type_pie"></div>
        </div>
    </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
    name: 'ReparationsAmountType',
    props: {
        isCollapse: {
            type: Boolean,
            default: true
        },
        navForm: {
            type: Object,
            default: {}
        }
    },
    watch: {
        isCollapse() {
            setTimeout(() => {
                this.myChart && this.myChart.resize();
            }, 600);
        },
        navForm: {
            immediate: true,
            deep: true,
            handler(val) {
                this.getData();
            }
        }
    },
    data() {
        return {
            topDays: [],
            newNumber: 0,
            myChart: '',
            loading: true
        };
    },
    mounted() {
        this.role = localStorage.getItem('role');
        window.addEventListener('resize', () => {
            if (this.myChart) {
                setTimeout(() => {
                    this.myChart.resize();
                }, 500);
            }
        });
    },
    methods: {
        getData() {
            this.loading = true;
            this.instance({
                method: 'post',
                url: '/large/data/screen/getClaimAmountClassification',
                data: this.navForm,
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8'
                }
            }).then((res) => {
                this.loading = false;
                if (res.data.code == 1) {
                    let response = res.data.data;
                    if (response.length > 0) {
                        this.loadCharts(response);
                    }
                }
            });
        },
        loadCharts(response) {
            var chartDom = document.getElementById('reparations_amount_type_pie');
            if (!chartDom) {
                return;
            }
            this.myChart = echarts.init(chartDom, null, { renderer: 'svg' });
            let seriesData = [];
            response.forEach((val, key) => {
                let obj = {
                    value: val.caseNumRatio,
                    name: val.name,
                    itemStyle: { borderColor: '#E3A505', shadowColor: '#433f24' }
                };
                if (key == 1) {
                    obj.itemStyle = { borderColor: '#28fd7f', shadowColor: '#195c4b' };
                } else if (key == 2) {
                    obj.itemStyle = { borderColor: '#b1009a', shadowColor: '#632053' };
                } else if (key == 3) {
                    obj.itemStyle = { borderColor: '#2485af', shadowColor: '#082159' };
                } else if (key == 4) {
                    obj.itemStyle = { borderColor: '#e485af', shadowColor: '#382159' };
                } else if (key == 5) {
                    obj.itemStyle = { borderColor: '#f4893f', shadowColor: '#082359' };
                }
                seriesData.push(obj);
            });
            var option = {
                tooltip: {
                    trigger: 'item',
                    formatter: '{b}: {c}元',
                    confine: true
                },
                legend: {
                    itemWidth: 12,
                    itemHeight: 12,
                    textStyle: {
                        color: '#fff'
                        // fontSize: '0.12rem'
                    },
                    right: '5%',
                    top: 'middle',
                    orient: 'vertical'
                },
                series: [
                    {
                        type: 'pie',
                        // radius: ['25%', '50%'],
                        radius: '60%',
                        center: ['40%', '50%'],
                        avoidLabelOverlap: false,
                        label: {
                            // show: false,
                            position: 'inner',
                            fontSize: 10,
                            color: '#fff',
                            formatter: '{d}%'
                        },
                        labelLine: {
                            show: false
                        },
                        itemStyle: {
                            borderColor: '#e4393c',
                            borderWidth: 5,
                            color: '#0A1A2E',
                            shadowBlur: 5,
                            shadowColor: 'rgba(85, 255, 0, 1)'
                        },
                        selectedMode: 'single',
                        // data: seriesData
                        data: seriesData
                    }
                ]
            };
            this.myChart.setOption(option, true);
        }
    },
    updated() {
        this.myChart && this.myChart.resize();
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.module_wrap {
    width: 100%;
    height: 4.43rem;
    padding: 0.15rem 0.3rem 0;
    /* margin-top: 0.25rem; */
}
#reparations_amount_type_pie {
    width: 100%;
    /* height: 2.7rem; */
    height: 90%;
}
</style>
