<template>
    <div class="content">
        <div class="home_title">案件基础数据监控</div>
        <el-radio-group class="map_tab" v-model="radio" @change="tabsChange" style="margin-bottom: 30px">
            <el-radio-button label="1">已决案件分布</el-radio-button>
            <el-radio-button label="2">未决案件分布</el-radio-button>
            <!-- <el-radio-button label="3">分包商分布</el-radio-button> -->
        </el-radio-group>
        <div id="container" tabindex="0"></div>
        <!-- 轮播块 -->
        <!-- <div class="slide_box">
	 	<p class="slide_title">报案实时播报</p>
		<div class="hint_text" v-show="realTimeData.length==0">
			<p>暂无数据</p>
			<p class="active">暂无数据</p>
		</div>
	 	<el-carousel v-show="realTimeData.length>0" class="slide_carousel" direction="vertical" indicator-position="none">
	 	    <el-carousel-item v-for="item in realTimeData" :key="item.id">
	 	      <ul class="route_list">
	 			  <li @click="linkVehicleMap(item[0])">
	 				  <span>{{item[0].plateno}}</span>
	 				  <b>{{item[0].startAndEnd}}</b>
	 			  </li>
	 			  <li class="active" @click="linkVehicleMap(item[1])">
	 				 <span>{{item[1].plateno}}</span>
	 				 <b>{{item[1].startAndEnd}}</b>
	 			  </li>
	 		  </ul>
	 	    </el-carousel-item>
	 	  </el-carousel>
		</div> -->
    </div>
</template>
<script>
var _this = ''
// import axios from 'axios'
import * as echarts from 'echarts'
import { cityToPinyin, geoCoordMap, cityMap } from '@/assets/js/mapJson.js'
export default {
    name: 'ChinaMap',
    props: {
        isCollapse: {
            type: Boolean,
            default: true
        },
        navForm: {
            type: Object,
            default: {}
        }
    },
    watch: {
        isCollapse() {
            setTimeout(() => {
                this.listenResize()
            }, 600)
        },
        navForm: {
            // immediate: true,
            deep: true,
            handler(val) {
                this.getData()
            }
        }
    },
    data() {
        return {
            map: '',
            loca: '',
            chart: null,
            origin: '',
            checkedPoint: [], //点击选中的poi
            mapData: {},
            radio: '1',
            tabsActive: 1,
            myChartMap: '',
            provinceCount: [],
            realTimeData: [], //实时行程
            cityCount: [
                // {name: '鄂尔多斯市', value: 12,	level: 1,parent:"内蒙古"},
                // {name: '济南市', value: 12,	level: 1,parent:"山东"},
                // {name: '菏泽市', value: 12,	level: 1,parent:"山东"},
                // {name: '济宁市', value: 12,	level: 1,parent:"山东"},
                // {name: '临沂市', value: 12,	level: 1,parent:"山东"},
                // {name: '青岛市', value: 12,	level: 1,parent:"山东"},
            ],
            nowVehicleData: {}
        }
    },
    mounted() {
        _this = this
        this.origin = window.location.origin
        this.role = localStorage.getItem('role')
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
        this.getData(1)
        window.addEventListener('resize', this.listenResize)
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.listenResize)
    },
    methods: {
        listenResize() {
            if (this.myChartMap) {
                setTimeout(() => {
                    this.myChartMap.resize()
                }, 600)
            }
        },
        //跳转
        linkVehicleMap(row) {
            if (this.userInfo.batchId || this.role == 0) {
                return false
            }
            let routeUrl = this.$router.resolve({
                path: '/VehicleMap',
                query: {
                    deviceNumber: row.deviceNumber,
                    startTime: row.tripStartTime,
                    endTime: row.tripEndTime
                }
            })
            window.open(routeUrl.href, '_blank')
        },
        //全国最近的30条行程
        getLatestTrip() {
            this.realTimeData = []
            this.instance
                .get('/homepage/getLatestTrip', {
                    params: { companyId: this.userInfo.company }
                })
                .then((res) => {
                    if (res.data.code == 1) {
                        this.initRouteData(res.data.data)
                    }
                })
        },
        //所选省份最近的30条行程
        getLatestTripByProvince(province) {
            this.realTimeData = []
            this.instance
                .get('/homepage/getLatestTripByProvince', {
                    params: { companyId: this.userInfo.company, provinceName: province }
                })
                .then((res) => {
                    if (res.data.code == 1) {
                        this.initRouteData(res.data.data)
                    }
                })
        },
        //整理车辆实时行程
        initRouteData(response) {
            var realTimeData = []
            var len = response.length
            if (len > 0) {
                for (var i = 0; i < len; i++) {
                    var startTime = response[i].tripStartTime
                    var endTime = response[i].tripEndTime
                    startTime = startTime.split(' ')[1].slice(0, -3)
                    response[i].startTime = startTime
                    endTime = endTime.split(' ')[1].slice(0, -3)
                    response[i].endTime = endTime
                    response[i].startAndEnd = startTime + '-' + endTime
                }
                this.realTimeData = this.split_array(response, 2)
            } else {
                this.realTimeData = []
            }
        },
        //切分数组
        split_array(arr, len) {
            var a_len = arr.length
            var result = []
            for (var i = 0; i < a_len; i += len) {
                result.push(arr.slice(i, i + len))
            }
            return result
        },
        //点击案件分布tabs
        tabsChange(val) {
            this.tabsActive = val
            this.getData(val)
        },
        //获取地图数据
        getData(tabNum) {
            this.cityCount = []
            this.provinceCount = []
            let json = JSON.parse(JSON.stringify(this.navForm))
            json.tabNum = this.radio
            this.instance({
                method: 'post',
                url: '/large/data/screen/mapDisplay',
                data: json,
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8'
                }
            }).then((res) => {
                this.mapData = res.data.data
                var cityCount = [],
                    provinceCount = []
                if (this.mapData.cityDatas.length > 0) {
                    this.mapData.cityDatas.forEach((val, key) => {
                        var obj = {
                            name: val.cityName,
                            parent: val.provinceName,
                            value: val.casesNo,
                            level: 1,
                            avgRiskValue: val.avgRiskValue,
                            insuredNo: val.insuredNo,
                            riskPointsNo: val.riskPointsNo
                        }
                        cityCount.push(obj)
                    })
                    this.cityCount = cityCount
                }
                if (this.mapData.provinceDatas.length > 0) {
                    this.mapData.provinceDatas.forEach((val, key) => {
                        var obj = {
                            name: val.cityName,
                            parent: val.provinceName,
                            value: val.casesNo,
                            level: 1,
                            avgRiskValue: val.avgRiskValue,
                            insuredNo: val.insuredNo,
                            riskPointsNo: val.riskPointsNo
                        }
                        provinceCount.push(obj)
                    })
                    this.provinceCount = provinceCount
                }
                setTimeout(() => {
                    this.createMap()
                }, 300)
            })
        },
        //创建地图
        createMap() {
            echarts.extendsMap = function (id, opt) {
                // 实例
                if (_this.chart) {
                    _this.chart.dispose()
                }
                _this.chart = echarts.init(document.getElementById(id), null, { renderer: 'svg' })
                // _this.chart.dispose();
                var provinceData = [] //省份渠道数据
                var curGeoJson = {} //城市数据
                var levelColorMap = {
                    1: 'rgba(241, 109, 115, .8)',
                    2: 'rgba(255, 235, 59, .7)',
                    3: 'rgba(147, 235, 248, 1)'
                }

                var defaultOpt = {
                    mapName: 'china', // 地图展示
                    goDown: false, // 是否下钻
                    bgColor: '#404a59', // 画布背景色
                    activeArea: [], // 区域高亮,同echarts配置项
                    data: [], //城市名称：设备数量
                    // 下钻回调(点击的地图名、实例对象option、实例对象)
                    callback: function (name, option, instance) {}
                }
                if (opt) {
                    opt = echarts.util.extend(defaultOpt, opt)
                }

                // 层级索引
                var name = [opt.mapName]
                var idx = 0

                //中国的位置
                var pos = {
                    leftPlus: 115,
                    leftCur: 150,
                    left: 198,
                    top: 240
                }

                var line = [
                    [0, 0],
                    [8, 11],
                    [0, 22]
                ]
                // style
                var style = {
                    font: '18px "Microsoft YaHei", sans-serif',
                    textColor: '#30FBFE',
                    lineColor: '#30FBFE'
                    // lineColor: 'rgba(147, 235, 248, .8)'
                }

                var handleEvents = {
                    /**
                     * i 实例对象
                     * o option
                     * n 地图名
                     **/
                    resetOption: function (i, o, n) {
                        if (n === 'china') {
                            // myVue.searchVisible=false;
                            // option.series[1].data=handleEvents.initSeriesData(_this.cityCount).slice(1,5)
                            // option.series[2].data=handleEvents.initSeriesData(_this.cityCount).slice(0,1)
                        }
                        var breadcrumb = this.createBreadcrumb(n)
                        var j = name.indexOf(n)
                        var l = o.graphic.length
                        // debugger
                        if (j < 0) {
                            o.graphic.push(breadcrumb)
                            o.graphic[0].children[0].shape.x2 = 145
                            o.graphic[0].children[1].shape.x2 = 145
                            if (o.graphic.length > 2) {
                                var cityData = []
                                var cityJson

                                for (var x = 0; x < opt.data.length; x++) {
                                    if (n === opt.data[x].parent) {
                                        var optData = [opt.data[x]]
                                        cityJson = {
                                            name: optData[0].name,
                                            value: optData[0].value,
                                            avgRiskValue: optData[0].avgRiskValue,
                                            insuredNo: optData[0].insuredNo,
                                            riskPointsNo: optData[0].riskPointsNo,
                                            parent: n
                                        }
                                        cityData.push(cityJson)
                                    }
                                }
                                if (cityData != null) {
                                    o.series[0].data = handleEvents.initSeriesData(cityData)
                                } else {
                                    o.series[0].data = []
                                }
                            }

                            name.push(n)
                            idx++
                        } else {
                            o.graphic.splice(j + 2, l)
                            if (o.graphic.length <= 2) {
                                o.graphic[0].children[0].shape.x2 = 60
                                o.graphic[0].children[1].shape.x2 = 60
                                o.series[0].data = handleEvents.initSeriesData(opt.data)
                            }
                            name.splice(j + 1, l)
                            idx = j
                            pos.leftCur -= pos.leftPlus * (l - j - 1)
                        }
                        o.geo.map = n
                        o.geo.zoom = 0.4
                        i.clear()
                        i.setOption(o)
                        this.zoomAnimation(n)
                        opt.callback(n, o, i)
                    },
                    /**
                     * name 地图名
                     **/
                    createBreadcrumb: function (name) {
                        var breadcrumb = {
                            type: 'group',
                            id: name,
                            // show:false,
                            left: '49%',
                            z: 999,
                            top: pos.top + 3,
                            children: [
                                {
                                    type: 'polyline',
                                    left: -90,
                                    top: -5,
                                    z: 999,
                                    shape: {
                                        points: line
                                    },
                                    style: {
                                        stroke: '#30FBFE',
                                        key: name
                                        // lineWidth: 2,
                                    },
                                    onclick: function () {
                                        var name = this.style.key
                                        handleEvents.resetOption(_this.chart, option, name)
                                    }
                                },
                                {
                                    type: 'text',
                                    left: -68,
                                    z: 999,
                                    top: 'middle',
                                    style: {
                                        text: name,
                                        textAlign: 'center',
                                        fill: style.textColor,
                                        font: style.font
                                    },
                                    onclick: function () {
                                        var name = this.style.text
                                        handleEvents.resetOption(_this.chart, option, name)
                                    }
                                },
                                {
                                    type: 'text',
                                    left: -68,
                                    z: 999,
                                    top: 10,
                                    style: {
                                        name: name,
                                        text: cityToPinyin[name] ? cityToPinyin[name].toUpperCase() : '',
                                        textAlign: 'center',
                                        fill: style.textColor,
                                        font: '12px "Microsoft YaHei", sans-serif'
                                    },
                                    onclick: function () {
                                        var name = this.style.name
                                        handleEvents.resetOption(_this.chart, option, name)
                                    }
                                }
                            ]
                        }
                        pos.leftCur += pos.leftPlus
                    },
                    // 设置effectscatter
                    initSeriesData: function (data) {
                        var temp = []
                        for (var i = 0; i < data.length; i++) {
                            var geoCoord = geoCoordMap[data[i].name]
                            if (geoCoord) {
                                temp.push({
                                    name: data[i].name,
                                    value: geoCoord.concat(data[i].value),
                                    num: data[i].value,
                                    avgRiskValue: data[i].avgRiskValue,
                                    insuredNo: data[i].insuredNo,
                                    riskPointsNo: data[i].riskPointsNo,
                                    region: data[i].region
                                })
                            }
                        }
                        return temp
                    },
                    zoomAnimation: function (n) {
                        var count = null
                        var _zoom = n == '中国' ? 1 : 0.8
                        var zoom = function (per) {
                            if (!count) count = per
                            count = count + per
                            _this.chart.setOption({
                                geo: {
                                    zoom: count
                                }
                            })

                            if (count < _zoom)
                                window.requestAnimationFrame(function () {
                                    zoom(0.2)
                                })
                        }
                        window.requestAnimationFrame(function () {
                            zoom(0.2)
                        })
                    }
                }
                var option = {
                    backgroundColor: opt.bgColor,
                    //提示框组件
                    tooltip: {
                        show: true
                    },
                    //原生图形元素组件
                    graphic: [
                        {
                            type: 'group',
                            left: '45%',
                            top: pos.top - 4,
                            children: [
                                {
                                    type: 'line',
                                    left: 0,
                                    z: 999,
                                    top: -20,
                                    shape: {
                                        x1: 0,
                                        y1: 0,
                                        x2: 60,
                                        y2: 0
                                    },
                                    style: {
                                        // stroke: style.lineColor
                                        stroke: 'transparent'
                                    }
                                },
                                {
                                    type: 'line',
                                    z: 999,
                                    left: 0,
                                    top: 20,
                                    shape: {
                                        x1: 0,
                                        y1: 0,
                                        x2: 60,
                                        y2: 0
                                    },
                                    style: {
                                        stroke: 'transparent'
                                        // stroke: style.lineColor
                                    }
                                }
                            ]
                        },
                        {
                            id: name[idx],
                            type: 'group',
                            left: '46%',
                            z: 999,
                            top: pos.top,
                            children: [
                                {
                                    type: 'polyline',
                                    left: 90,
                                    top: -12,
                                    z: 999,
                                    shape: {
                                        points: line
                                    },
                                    style: {
                                        stroke: 'transparent',
                                        key: name[0]
                                    },
                                    onclick: function () {
                                        var name = this.style.key
                                        handleEvents.resetOption(_this.chart, option, name)
                                    }
                                }
                                //               {
                                //                   type: 'text',
                                //                   left: 0,
                                //                   top: 'middle',
                                // z:999,
                                //                   style: {
                                //                       text: '中国',
                                //                       textAlign: 'center',
                                //                       fill: style.textColor,
                                //                       font: style.font
                                //                   },
                                //                   onclick: function() {
                                //                       handleEvents.resetOption(_this.chart, option, '中国');
                                //                   }
                                //               },
                                //               {
                                //                   type: 'text',
                                //                   left: 0,
                                //                   top: 10,
                                // z:999,
                                //                   style: {
                                //                       text: 'China',
                                //                       textAlign: 'center',
                                //                       fill: style.textColor,
                                //                       font: '12px "Microsoft YaHei", sans-serif'
                                //                   },
                                //                   onclick: function() {
                                //                       handleEvents.resetOption(_this.chart, option, '中国');
                                //                   }
                                //               }
                            ]
                        }
                    ],
                    // visualMap: {
                    //     min: 0,
                    //     max: 150,
                    //     left: 'left',
                    //     top: 'bottom',
                    //     text: ['High','Low'],
                    //     seriesIndex: [1],
                    //     inRange: {
                    //         color: [ '#0ff000']
                    //     },
                    //     calculable : true
                    // },
                    //地理坐标系组件
                    geo: {
                        map: opt.mapName,
                        roam: true,
                        zoom: 1, //当前视角的缩放比例
                        top: '10%', //地图的位置
                        tooltip: {
                            show: true,
                            trigger: 'item',
                            alwaysShowContent: false,
                            backgroundColor: 'rgba(8, 15, 61, 0.8)',
                            borderColor: '#00E0FF',
                            borderWidth: 1,
                            textStyle: {
                                color: '#fff'
                            },
                            hideDelay: 100,
                            // triggerOn:'mousemove',
                            enterable: true,
                            // position:['60%','70%'],
                            formatter: function (params, ticket, callback) {
                                if (params.componentType == 'geo') {
                                    var provinceCount = _this.provinceCount
                                    var provinceKey = provinceCount.findIndex((value) => {
                                        return value.name == params.name
                                    })
                                    if (provinceKey != -1) {
                                        let toolText = ''
                                        if (_this.tabsActive == 3) {
                                            toolText = '<br/>' + '分包商数量：' + provinceCount[provinceKey].insuredNo + '个'
                                        }
                                        return '城市：' + provinceCount[provinceKey].name + '<br/>' + '案件数：' + provinceCount[provinceKey].value + '件' + '<br/>' + '风险点个数：' + provinceCount[provinceKey].riskPointsNo + '个' + toolText
                                    } else {
                                        return params.name
                                    }
                                } else {
                                    let toolText = ''
                                    if (_this.tabsActive == 3) {
                                        toolText = '<br/>' + '分包商数量：' + params.data.insuredNo + '个'
                                    }
                                    return '城市：' + params.data.name + '<br/>' + '案件数：' + params.data.num + '件' + '<br/>' + '风险点个数：' + params.data.riskPointsNo + '个' + toolText
                                }
                            }
                        },
                        label: {
                            show: true,
                            color: '#fff'
                        },
                        itemStyle: {
                            borderColor: '#2ff7fb',
                            borderWidth: 1,
                            opacity: 1,
                            areaColor: {
                                type: 'radial',
                                x: 0.5,
                                y: 0.5,
                                r: 0.8,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: 'RGBA(0, 36, 109, 0.5)' // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: 'RGBA(0, 36, 109, 0.7)' // 100% 处的颜色
                                    }
                                ],
                                globalCoord: false // 缺省为 false
                            }
                        },
                        emphasis: {
                            itemStyle: {
                                areaColor: 'rgba(0, 69, 109, 0.8)',
                                borderWidth: 0
                            },
                            label: {
                                color: '#fff'
                            }
                        }
                        // regions: opt.activeArea.map(function(item) {
                        //     if (typeof item !== 'string') {
                        //         return {
                        //             name: item.name,
                        //             itemStyle: {
                        //                 normal: {
                        //                     areaColor: item.areaColor || '#389BB7'
                        //                 }
                        //             },
                        //             label: {
                        //                 normal: {
                        //                     show: item.showLabel,
                        //                     textStyle: {
                        //                         color: '#fff'
                        //                     }
                        //                 }
                        //             }
                        //         }
                        //     } else {
                        //         return {
                        //             name: item,
                        //             itemStyle: {
                        //                 normal: {
                        //                     borderColor: '#91e6ff',
                        //                     areaColor: '#389BB7'
                        //                 }
                        //             }
                        //         }
                        //     }
                        // })
                    },
                    series: [
                        {
                            type: 'effectScatter',
                            coordinateSystem: 'geo',
                            showEffectOn: 'emphasis', //涟漪触发方式 render=始终显示 emphasis=hover时显示
                            //symbol:'image://http://xxx.xxx.xxx/a/b.png', //标记的图形
                            //symbolKeepAspect:true,//是否在缩放时保持该图形的长宽比
                            rippleEffect: {
                                //涟漪特效
                                period: 5,
                                scale: 2.5,
                                brushType: 'fill'
                            },
                            // hoverAnimation: true,
                            itemStyle: {
                                opacity: 0.9,
                                color: '#E29550',
                                shadowBlur: 10,
                                shadowColor: '#333'
                            },
                            // symbol:"image://data:image/gif;base64,R0lGODlhEAAQAMQAAORHHOVSKudfOulrSOp3WOyDZu6QdvCchPGolfO0o/XBs/fNwfjZ0frl3/zy7////wAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAkAABAALAAAAAAQABAAAAVVICSOZGlCQAosJ6mu7fiyZeKqNKToQGDsM8hBADgUXoGAiqhSvp5QAnQKGIgUhwFUYLCVDFCrKUE1lBavAViFIDlTImbKC5Gm2hB0SlBCBMQiB0UjIQA7",
                            symbolSize(value, params) {
                                var _val = value[2]
                                var maxVal = opt.data[0].value
                                var _size = 6
                                if (_val < maxVal * 0.4) {
                                    _size = 6
                                } else if (_val < maxVal * 0.7) {
                                    _size = 8
                                } else {
                                    _size = 10
                                }
                                return _size
                            }, //图标大小
                            zlevel: 1,
                            // symbolSize: function (val) {   #e44a15
                            //     val=val[2] / 2>15?val[2] / 2:15;
                            //     val=val<=25?val:25;
                            //     return val;
                            // },          //图标大小
                            // data: handleEvents.initSeriesData(opt.data).slice(5)   //数据
                            data: handleEvents.initSeriesData(opt.data) //数据
                        }
                        //          {
                        //              type: 'effectScatter',
                        //              coordinateSystem: 'geo',
                        //              showEffectOn: 'emphasis',  //涟漪触发方式 render=始终显示 emphasis=hover时显示
                        //              rippleEffect: {     //涟漪特效
                        //                  period:4,
                        //                  scale: 3,
                        //                  brushType: 'stroke'
                        //              },
                        //              // hoverAnimation: true,
                        //              itemStyle: {
                        //                  color:'#cce198',
                        //                  shadowBlur: 10,
                        //                  shadowColor: '#333'
                        //              },
                        //              zlevel: 1,
                        //              symbolSize: 16,          //图标大小
                        //              data: handleEvents.initSeriesData(opt.data).slice(1,5)   //数据
                        //          },
                        // {
                        //     type: 'effectScatter',
                        //     coordinateSystem: 'geo',
                        //     showEffectOn: 'emphasis',  //涟漪触发方式 render=始终显示 emphasis=hover时显示
                        //     rippleEffect: {     //涟漪特效
                        //         period:4,
                        //         scale: 3,
                        //         brushType: 'stroke'
                        //     },
                        //     // hoverAnimation: true,
                        //     itemStyle: {
                        //         color:'#ffaf23',
                        //         shadowBlur: 10,
                        //         shadowColor: '#333'
                        //     },
                        //     zlevel: 1,
                        //  z:5,
                        //     symbolSize: 16,          //图标大小
                        //     data: handleEvents.initSeriesData(opt.data).slice(0,1)   //数据
                        // }
                    ]
                }
                _this.chart.setOption(option)
                // 添加事件
                _this.chart.on('click', function (params) {
                    // if(params.data){
                    //     window.location.href='areamac.html?cityId='+params.data.cityId+'&city='+encodeURI(params.data.name);
                    // }else{
                    // }
                    // debugger
                    var _name = name[idx] == 'china' ? '中国' : name[idx]
                    var _self = this
                    if (opt.goDown && params.name !== _name) {
                        if (cityMap[params.name]) {
                            var url = cityMap[params.name]
                            // _this.getLatestTripByProvince(params.name);
                            _this.$axios.get(_this.origin + '/' + url).then((res) => {
                                curGeoJson = res.data
                                // option.series[1].data=[];
                                // option.series[2].data=[];
                                echarts.registerMap(params.name, curGeoJson)
                                handleEvents.resetOption(_self, option, params.name)
                            })
                        }
                    }
                    if (_name != '中国') {
                        // _this.getLatestTrip();
                        handleEvents.resetOption(_this.chart, option, 'china')
                    }
                })

                _this.chart.setMap = function (mapName) {
                    var _self = this
                    if (mapName.indexOf('市') < 0) mapName = mapName + '市'
                    var citySource = cityMap[mapName]
                    if (citySource) {
                        var url = citySource
                        _this.$axios.get(url).then((res) => {
                            curGeoJson = res.data
                            echarts.registerMap(mapName, curGeoJson)
                            handleEvents.resetOption(_self, option, mapName)
                        })
                    }
                }
                return _this.chart
            }
            function initChartsMap() {
                _this.$axios.get('/map/data-china.json').then((res) => {
                    echarts.registerMap('china', res.data)
                    _this.myChartMap = echarts.extendsMap('container', {
                        bgColor: 'transparent', // 画布背景色
                        mapName: 'china', // 地图名
                        goDown: true, // 是否下钻
                        // 下钻回调
                        callback: function (name, option, instance) {},

                        data: _this.cityCount
                        // data:[
                        //     {name: '鄂尔多斯市', value: 12,	level: 1,parent:"内蒙古"},
                        //     {name: '济南市', value: 12,	level: 1,parent:"山东"},
                        //     {name: '菏泽市', value: 12,	level: 1,parent:"山东"},
                        //     {name: '济宁市', value: 12,	level: 1,parent:"山东"},
                        //     {name: '临沂市', value: 12,	level: 1,parent:"山东"},
                        //     {name: '青岛市', value: 12,	level: 1,parent:"山东"},
                        // ]
                    })
                })
            }
            initChartsMap()
        },
        //添加script标签
        appendScript(url) {
            var jsapi = document.createElement('script')
            jsapi.charset = 'utf-8'
            jsapi.src = url
            document.head.appendChild(jsapi)
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#container {
    width: 100%;
    height: 6.45rem;
    border: none !important;
    outline: none;
    margin-top: 0.2rem;
}
.content {
    position: relative;
    width: 100%;
    height: 7.17rem;
    text-align: center;
}
.map_tab {
    margin: 0 !important;
}
/deep/.el-radio-button__inner {
    background-color: transparent;
    color: #fff;
    padding: 0.1rem 0.2rem;
    font-size: 0.18rem;
}
/deep/.el-radio-button__orig-radio:checked + .el-radio-button__inner {
    /* background-color: #006ec4; */
    /* border-color: #006ec4; */
}
.slide_box {
    width: 2.6rem;
    min-width: 180px;
    height: 1.46rem;
    min-height: 70px;
    position: absolute;
    bottom: 0.3rem;
    left: 0.2rem;
    z-index: 99;
    /* background:url(../../public/images/msi/slide_box.png) no-repeat; */
    /* background-size: 100% 100%; */
    padding: 0.16rem 0.1rem 0;
}
/* .device_pixel_ratio .slide_box{
		width:3.1rem;
	}
	.device_pixel_ratio .route_list li,.device_pixel_ratio .hint_text p{
		width: 2.88rem;
	} */
.slide_title {
    font-size: 0.18rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
}
.slide_carousel {
    width: 100%;
    height: 0.88rem;
    margin-top: 0.12rem;
}
.route_list li,
.hint_text p {
    width: 2.38rem;
    min-width: 170px;
    height: 0.38rem;
    line-height: 0.38rem;
    background: rgba(54, 153, 255, 0.2);
    text-align: center;
    font-size: 0.16rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #30fbfe;
    margin-bottom: 0.12rem;
    cursor: pointer;
    padding: 0 0.15rem;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
}
.route_list span {
    /* width: 1.1rem;
		overflow:hidden;
		text-overflow:ellipsis;
		-o-text-overflow:ellipsis;
		white-space:nowrap; */
}
.route_list b {
    /* width: 1.28rem;
		overflow:hidden;
		text-overflow:ellipsis;
		-o-text-overflow:ellipsis;
		white-space:nowrap; */
}
.hint_text p {
    margin-top: 0.12rem;
    margin-bottom: 0;
    justify-content: center;
}
.route_list li.active {
    margin-bottom: 0;
}
/* @media screen and (max-height: 660px) {
		.slide_box{
			width: 3rem;
		}
		.route_list li,.hint_text p{
			width: 2.78rem;
		}
	} */
</style>
