<template>
    <div class="module_wrap box_border" v-loading="loading" element-loading-text="数据加载中" element-loading-spinner="el-icon-loading" element-loading-background="transparent">
        <div class="box_title">{{ navForm.accidentProvince == '全国' ? '全国死亡案均金额排行榜' : '死亡案均金额统计' }}</div>
        <div style="height: 55%" v-show="!loading">
            <div id="case_num_sum_bar" v-show="province == '全国'"></div>
            <div id="case_num_sum_bar2" v-show="province != '全国'"></div>
            <div class="tables_box" v-if="tableData.length > 0">
                <el-table :data="[tableData[0]]" max-height="200">
                    <el-table-column v-for="(item, index) in tableData" :label="item.accItem" :key="index" :width="String(item.accAmount).length > 5 ? '100' : ''">
                        <template slot-scope="scope">
                            <span v-if="0">{{ scope.row }}</span>
                            {{ item.accAmount ? item.accAmount : '--' }} 万元
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
    name: 'CaseNumSum',
    props: {
        isCollapse: {
            type: Boolean,
            default: true
        },
        navForm: {
            type: Object,
            default: {}
        }
    },
    watch: {
        isCollapse() {
            setTimeout(() => {
                this.myChart && this.myChart.resize();
            }, 600);
        },
        navForm: {
            immediate: true,
            deep: true,
            handler(val) {
                this.province = val.accidentProvince;
                this.getData();
            }
        }
    },
    data() {
        return {
            topDays: [],
            newNumber: 0,
            myChart: '',
            myChart2: '',
            option: '',
            province: '',
            tableData: [],
            loading: true
        };
    },
    mounted() {
        this.role = localStorage.getItem('role');
        window.addEventListener('resize', () => {
            if (this.myChart) {
                setTimeout(() => {
                    this.myChart.resize();
                }, 500);
            }
        });
    },
    methods: {
        getData() {
            this.loading = true;
            this.instance({
                method: 'post',
                url: '/large/data/screen/deathRanking',
                data: this.navForm,
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8'
                }
            }).then((res) => {
                this.loading = false;
                if (res.data.code == 1) {
                    let response = res.data.data;
                    if (response.length > 0) {
                        if (this.province == '全国') {
                            response = response.splice(0, 20);
                            this.loadCharts(response);
                        } else {
                            this.loadCharts2(response);
                        }
                    }
                }
            });
        },
        //加载省份的图表
        loadCharts2(response) {
            var chartDom = document.getElementById('case_num_sum_bar2');
            if (!chartDom) {
                return;
            }
            this.myChart2 = echarts.init(chartDom, null, { renderer: 'svg' });
            let xAxisData = [],
                seriesData1 = [],
                seriesData2 = [];
            response.forEach((val, key) => {
                xAxisData.push(val.accItem);
                seriesData1.push(val.prvAmount);
                seriesData2.push(val.accAmount);
            });
            this.tableData = response;
            let option = {
                tooltip: {
                    // trigger: 'axis',
                    confine: true,
                    formatter: '{a}<br/>{b}: {c}元',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend: {
                    data: [this.province, '全国'],
                    itemWidth: 12,
                    itemHeight: 12,
                    itemGap: 20,
                    textStyle: {
                        color: '#fff'
                        // fontSize: '0.12rem'
                    },
                    left: 'center'
                },
                grid: {
                    top: 25,
                    left: '10',
                    right: '10',
                    bottom: '10',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    splitLine: {
                        show: false
                    },
                    axisTick: {
                        show: true,
                        alignWithLabel: true
                    },
                    axisLabel: {
                        showMaxLabel: true,
                        rotate: 30,
                        // fontSize: '0.12rem',
                        color: '#fff', //坐标值得具体的颜色
                        width: 70,
                        overflow: 'truncate',
                        ellipsis: '...'
                    },
                    data: xAxisData
                    // boundaryGap: [0, 0.01]
                },
                yAxis: {
                    // type: 'category',
                    splitLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        color: '#fff',
                        formatter: '{value} 元'
                        // fontSize: '0.12rem'
                    }
                },
                series: [
                    {
                        name: this.province,
                        type: 'bar',
                        itemStyle: {
                            color: '#5283BD'
                        },
                        stack: 'total',
                        emphasis: {
                            focus: 'series'
                        },
                        barMaxWidth: '40%',
                        data: seriesData1
                    },
                    {
                        name: '全国',
                        type: 'bar',
                        itemStyle: {
                            color: '#B6CBE4'
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        stack: 'total',
                        barMaxWidth: '35%',
                        data: seriesData2
                    }
                ]
            };
            this.myChart2.setOption(option);
        },
        //加载全国的图表
        loadCharts(response) {
            var chartDom = document.getElementById('case_num_sum_bar');
            if (!chartDom) {
                return;
            }
            this.myChart = echarts.init(chartDom, null, { renderer: 'svg' });
            let xAxisData = [],
                seriesData = [];
            response.forEach((val, key) => {
                xAxisData.push(val.accItem);
                seriesData.push(val.accAmount);
            });
            this.tableData = response;
            var option = {
                tooltip: {
                    trigger: 'axis',
                    formatter: '{b0} :  {c0}元'
                },
                xAxis: {
                    data: xAxisData,
                    axisTick: { show: false },
                    // axisLine: { show: false },
                    axisLabel: {
                        showMaxLabel: true,
                        rotate: 40,
                        // fontSize: '0.12rem',
                        color: '#fff', //坐标值得具体的颜色
                        width: 60,
                        overflow: 'truncate',
                        ellipsis: '...'
                    }
                },
                yAxis: {
                    splitLine: { show: false },
                    axisTick: { show: false },
                    // axisLine: { show: false },
                    axisLabel: { show: false }
                },
                grid: {
                    top: '20',
                    bottom: '5',
                    left: '10',
                    right: '10',
                    containLabel: true
                },
                color: ['#88B3BD'],
                series: [
                    {
                        name: 'hill',
                        type: 'pictorialBar',
                        barCategoryGap: '-40%',
                        // symbol: 'path://M0,10 L10,10 L5,0 L0,10 z',
                        symbol: 'path://M0,10 L10,10 C5.5,10 5.5,5 5,0 C4.5,5 4.5,10 0,10 z',
                        barWidth: '80%',
                        label: {
                            // show: true,
                            position: 'top',
                            // fontSize: '0.12rem',
                            color: '#fff',
                            formatter: function (value) {
                                return Math.round(value.value / 10000) + ' 万';
                            }
                        },
                        emphasis: {
                            itemStyle: {
                                opacity: 1
                            }
                        },
                        data: seriesData,
                        z: 10
                    }
                ]
            };
            this.myChart.setOption(option);
        }
    },
    updated() {
        this.myChart && this.myChart.resize();
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.module_wrap {
    width: 100%;
    min-height: 5.8rem;
    padding: 0.15rem 0.3rem 0;
    padding-bottom: 0.3rem;
    margin-top: 0.25rem;
    .tables_box {
        width: 100%;
        height: 100px;
        margin-top: -30px;
        padding: 0.3rem;
        padding-bottom: 0;
        // padding-top: 0.1rem;
        div {
            background: none;
        }
        /deep/ tr,
        /deep/.el-table__cell {
            background: none;
            color: #fff;
            // border-color: #006ec4;
        }
        /deep/.el-table__row::hover {
            background: none;
        }
        /deep/ tr:hover > td {
            background: none;
        }
        /deep/ td.el-table__cell {
            border: 0px;
        }
        /deep/ .el-table--border {
            border: none;
            border: 0px;
        }
    }
}
#case_num_sum_bar,
#case_num_sum_bar2 {
    width: 100%;
    height: 3rem;
    margin-top: 0.1rem;
}
</style>
