<template>
    <div class="modal" v-if="modalVisible" @click.self="handleReturn">
        <div class="modal_box">
            <template v-if="pageType == 'success'">
                <div class="modal_img">
                    <img src="../../../../../public/images/car-damage/success_icon.png" />
                </div>
                <div class="modal_success">
                    <el-button type="primary" size="default" plain @click="handleReturn">提交成功</el-button>
                </div>
            </template>
            <template v-else>
                <div class="modal_img">
                    <img src="../../../../../public/images/car-damage/wraning_icon.png" />
                </div>
                <div class="modal_text">
                    <span>共有 </span>
                    <b>{{ tipsNum }}项 </b>
                    <span>报价异常</span>
                </div>
                <div class="modal_btn_group">
                    <el-button size="default" @click="handleSubmit">仍要提交</el-button>
                    <el-button size="default" @click="handleReturn">返回修改</el-button>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    //
    name: 'ResultModal',
    props: {
        pageType: {
            type: String,
            default: 'success'
        },
        tipsNum: {
            type: Number,
            default: 0
        },
        modalVisible: {
            type: Boolean,
            default: false
        }
    },
    components: {},
    data() {
        return {};
    },
    methods: {
        handleReturn() {
            this.$emit('handleReturn');
        },
        handleSubmit() {
            this.$emit('addWorkOrders');
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
}
.modal_box {
    margin: auto;
    min-width: 5rem;
    min-height: 4rem;
    background: #ffffff;
    box-shadow: 0px 2px 14px 0px rgba(222, 222, 222, 0.5);
    border-radius: 6px;
    padding: 0.6rem 0.8rem 0.5rem;
}
.modal_img {
    width: 104px;
    margin: 0 auto;
}
.modal_text {
    margin: 0.19rem 0 0.3rem;
    text-align: center;
}
.modal_text b {
    color: #cc4f4f;
}
.el-button {
    font-size: 13px;
    height: auto;
    padding: 0.12rem 0.3rem;
}
.modal_btn_group {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}
.modal_success {
    text-align: center;
    margin-top: 0.7rem;
}
</style>
