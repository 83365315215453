<template>
    <div class="module_wrap box_border" v-loading="loading" element-loading-text="数据加载中" element-loading-spinner="el-icon-loading" element-loading-background="transparent">
        <div class="box_title box_title_radio">
            <span>总赔款金额变换趋势</span>
            <div class="radio_group">
                <el-radio-group v-model="radio" @change="radioChange" size="mini">
                    <el-radio-button label="同比"></el-radio-button>
                    <el-radio-button label="环比"></el-radio-button>
                </el-radio-group>
            </div>
        </div>
        <div style="height: 100%" v-show="!loading">
            <div id="all_reparations_amount_bar"></div>
        </div>
    </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
    name: 'AllReparationsAmount',
    props: {
        isCollapse: {
            type: Boolean,
            default: true
        },
        navForm: {
            type: Object,
            default: {}
        }
    },
    watch: {
        isCollapse() {
            setTimeout(() => {
                this.myChart && this.myChart.resize();
            }, 600);
        },
        navForm: {
            immediate: true,
            deep: true,
            handler(val) {
                this.getData();
            }
        }
    },
    data() {
        return {
            topDays: [],
            radio: '同比',
            newNumber: 0,
            xAxisData1: [],
            xAxisData2: [],
            sumSeriesData1: [],
            sumSeriesData2: [],
            ratioSeriesData1: [],
            ratioSeriesData2: [],
            option: '',
            myChart: '',
            loading: true
        };
    },
    mounted() {
        this.role = localStorage.getItem('role');
        window.addEventListener('resize', () => {
            if (this.myChart) {
                setTimeout(() => {
                    this.myChart.resize();
                }, 500);
            }
        });
    },
    methods: {
        getData() {
            this.loading = true;
            this.instance({
                method: 'post',
                url: '/large/data/screen/getTotalCaseRatio',
                data: this.navForm,
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8'
                }
            }).then((res) => {
                this.loading = false;
                if (res.data.code == 1) {
                    let response = res.data.data;
                    this.loadCharts(response);
                }
            });
        },
        //同比&金额change事件
        radioChange() {
            if (this.radio == '同比') {
                this.option.legend.data = ['总赔款金额', '同比增长率'];
                this.option.xAxis.data = this.xAxisData1;
                this.option.series[0].data = this.ratioSeriesData1;
                this.option.series[1].data = this.ratioSeriesData2;
                // this.option.series[0].name='总赔款金额';
                this.option.series[1].name = '同比增长率';
            } else {
                this.option.legend.data = ['总赔款金额', '环比增长率'];
                this.option.xAxis.data = this.xAxisData2;
                this.option.series[0].data = this.sumSeriesData1;
                this.option.series[1].data = this.sumSeriesData2;
                // this.option.series[0].name='总赔款金额';
                this.option.series[1].name = '环比增长率';
            }
            setTimeout(() => {
                this.myChart.setOption(this.option);
            }, 100);
        },
        loadCharts(response) {
            var chartDom = document.getElementById('all_reparations_amount_bar');
            if (!chartDom) {
                return;
            }
            this.myChart = echarts.init(chartDom, null, { renderer: 'svg' });
            // let xAxisData=[],seriesData1=[],seriesData2=[];
            this.xAxisData1 = [];
            this.sumSeriesData1 = [];
            this.sumSeriesData2 = [];
            this.xAxisData2 = [];
            this.ratioSeriesData1 = [];
            this.ratioSeriesData2 = [];
            response.caseRiskMoney.forEach((val, key) => {
                this.xAxisData1.push(val.name);
                this.sumSeriesData1.push((val.totalPayAmount / 10000).toFixed(2));
                this.sumSeriesData2.push(val.caseNumRatio);
            });
            response.caseRiskNum.forEach((val, key) => {
                this.xAxisData2.push(val.name);
                this.ratioSeriesData1.push((val.totalPayAmount / 10000).toFixed(2));
                this.ratioSeriesData2.push(val.caseNumRatio);
            });
            this.option = {
                color: ['#96b4d8', '#09d5d6'],
                tooltip: {
                    trigger: 'axis'
                    // axisPointer: {
                    //   type: 'cross',
                    //   crossStyle: {
                    // 	color: '#999'
                    //   }
                    // }
                },
                grid: {
                    top: 30,
                    left: '10',
                    right: '4%',
                    bottom: '15',
                    containLabel: true
                },
                legend: {
                    data: ['总赔款金额', '同比增长率'],
                    itemWidth: 12,
                    itemHeight: 12,
                    textStyle: {
                        color: '#fff'
                        // fontSize: '0.12rem'
                    },
                    left: 'center'
                },
                xAxis: [
                    {
                        type: 'category',
                        data: this.xAxisData1,
                        axisPointer: {
                            type: 'shadow'
                        },
                        axisLabel: {
                            show: false,
                            showMaxLabel: true,
                            rotate: 20,
                            textStyle: {
                                // fontSize: '0.12rem',
                                color: '#fff' //坐标值得具体的颜色
                            }
                        },
                        axisTick: {
                            show: false
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        axisLabel: {
                            showMaxLabel: true,
                            formatter: '{value} 万',
                            textStyle: {
                                // fontSize: '0.12rem',
                                color: '#fff' //坐标值得具体的颜色
                            }
                        },
                        splitLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        }
                    },
                    {
                        type: 'value',
                        axisLabel: {
                            showMaxLabel: true,
                            formatter: '{value} %',
                            textStyle: {
                                // fontSize: '0.12rem',
                                color: '#fff' //坐标值得具体的颜色
                            }
                        },

                        splitLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        }
                    }
                ],
                series: [
                    {
                        name: '总赔款金额',
                        type: 'bar',
                        barWidth: '40%',
                        tooltip: {
                            valueFormatter: function (value) {
                                return value + ' 万元';
                            }
                        },
                        data: this.sumSeriesData1
                    },
                    {
                        name: '同比增长率',
                        type: 'line',
                        yAxisIndex: 1,
                        tooltip: {
                            valueFormatter: function (value) {
                                return (value ? value.toFixed(0) : 0) + ' %';
                            }
                        },
                        smooth: true,
                        showSymbol: false,
                        data: this.sumSeriesData2
                    }
                ]
            };
            this.myChart.setOption(this.option);
            this.radioChange();
        }
    },
    updated() {
        this.myChart && this.myChart.resize();
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.module_wrap {
    width: 100%;
    height: 4.43rem;
    padding: 0.15rem 0.3rem 0;
}
#all_reparations_amount_bar {
    width: 100%;
    /* height: 2.5rem; */
    height: 85%;
    margin-top: 0.1rem;
}
</style>
