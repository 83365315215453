<template>
    <div class="module_wrap box_border" v-loading="loading" element-loading-text="数据加载中" element-loading-spinner="el-icon-loading" element-loading-background="transparent">
        <div class="box_title">{{ navForm.accidentProvince == '全国' ? '全国人伤损案均金额排行榜' : '人伤损案均金额统计' }}</div>
        <div style="height: 55%" v-show="!loading">
            <div id="passing_rate_bar" v-show="province == '全国'"></div>
            <div id="passing_rate_bar2" v-show="province != '全国'"></div>
            <div class="tables_box" v-if="tableData.length > 0">
                <el-table :data="[tableData[0]]" max-height="200">
                    <el-table-column v-for="(item, index) in tableData" :label="item.accItem" :key="index" :width="String(item.accAmount).length > 5 ? '100' : ''">
                        <template slot-scope="scope">
                            <span v-if="0">{{ scope.row }}</span>
                            {{ item.accAmount ? item.accAmount : '--' }} 元
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
    name: 'PassingRate',
    props: {
        isCollapse: {
            type: Boolean,
            default: true
        },
        navForm: {
            type: Object,
            default: {}
        }
    },
    watch: {
        isCollapse() {
            setTimeout(() => {
                this.myChart && this.myChart.resize();
            }, 600);
        },
        navForm: {
            immediate: true,
            deep: true,
            handler(val) {
                this.province = val.accidentProvince;
                this.getData();
            }
        }
    },
    data() {
        return {
            topDays: [],
            newNumber: 0,
            myChart: '',
            myChart2: '',
            option: '',
            province: '',
            tableData: [],
            loading: true
        };
    },
    mounted() {
        this.role = localStorage.getItem('role');
        window.addEventListener('resize', () => {
            if (this.myChart) {
                setTimeout(() => {
                    this.myChart.resize();
                }, 500);
            }
        });
    },
    methods: {
        getData() {
            this.loading = true;
            this.instance({
                method: 'post',
                url: '/large/data/screen/humanInjuryRanking',
                data: this.navForm,
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8'
                }
            }).then((res) => {
                this.loading = false;
                if (res.data.code == 1) {
                    let response = res.data.data;
                    if (response.length > 0) {
                        if (this.province == '全国') {
                            response = response.splice(0, 20);
                            this.loadCharts(response);
                        } else {
                            this.loadCharts2(response);
                        }
                    }
                }
            });
        },
        //加载省份的图表
        loadCharts2(response) {
            var chartDom = document.getElementById('passing_rate_bar2');
            if (!chartDom) {
                return;
            }
            this.myChart2 = echarts.init(chartDom, null, { renderer: 'svg' });
            let xAxisData = [],
                seriesData1 = [],
                seriesData2 = [];
            response.forEach((val, key) => {
                xAxisData.push(val.accItem);
                seriesData1.push(val.prvAmount);
                seriesData2.push(val.accAmount);
            });
            this.tableData = response;
            let option = {
                tooltip: {
                    trigger: 'axis'
                    // formatter: '{a}<br/>{b}: {c} 元',
                },
                legend: {
                    textStyle: {
                        color: '#fff',
                        fontSize: 12
                    },
                    itemGap: 20
                },
                grid: {
                    top: 25,
                    left: '10',
                    right: '10',
                    bottom: '10',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: false,
                        axisLine: {
                            //坐标轴轴线相关设置。数学上的x轴
                            show: true,
                            lineStyle: {
                                color: '#233653'
                            }
                        },
                        axisLabel: {
                            showMaxLabel: true,
                            rotate: 30,
                            // fontSize: '0.12rem',
                            color: '#fff', //坐标值得具体的颜色
                            width: 70,
                            overflow: 'truncate',
                            ellipsis: '...'
                        },
                        splitLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        },
                        data: xAxisData
                    }
                ],
                yAxis: [
                    {
                        nameTextStyle: {
                            color: '#7ec7ff',
                            fontSize: 12,
                            padding: 10
                        },
                        min: 0,
                        splitLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        },
                        axisLabel: {
                            color: '#fff',
                            formatter: '{value} 元'
                            // fontSize: '0.12rem'
                        }
                    }
                ],
                series: [
                    {
                        name: this.province,
                        type: 'line',
                        symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
                        showAllSymbol: true,
                        symbolSize: 0,
                        smooth: true,
                        lineStyle: {
                            normal: {
                                width: 2,
                                color: '#519B37' // 线条颜色
                            }
                        },
                        itemStyle: {
                            color: '#519B37'
                        },
                        tooltip: {
                            valueFormatter: function (value) {
                                return value + ' 元';
                            }
                        },
                        areaStyle: {
                            //区域填充样式
                            normal: {
                                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                                color: new echarts.graphic.LinearGradient(
                                    0,
                                    0,
                                    0,
                                    1,
                                    [
                                        {
                                            offset: 0,
                                            color: 'rgba(77,192,36,.3)'
                                        },
                                        {
                                            offset: 1,
                                            color: 'rgba(77,192,36, 0)'
                                        }
                                    ],
                                    false
                                ),
                                shadowColor: 'rgba(10,219,250, 0.5)', //阴影颜色
                                shadowBlur: 20 //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
                            }
                        },
                        data: seriesData1
                    },
                    {
                        name: '全国',
                        type: 'line',
                        symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
                        showAllSymbol: true,
                        symbolSize: 0,
                        smooth: true,
                        lineStyle: {
                            normal: {
                                width: 2,
                                color: '#BFCD3D' // 线条颜色
                            }
                        },
                        itemStyle: {
                            color: '#BFCD3D'
                        },
                        tooltip: {
                            valueFormatter: function (value) {
                                return value + ' 元';
                            }
                        },
                        areaStyle: {
                            //区域填充样式
                            normal: {
                                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                                color: new echarts.graphic.LinearGradient(
                                    0,
                                    0,
                                    0,
                                    1,
                                    [
                                        {
                                            offset: 0,
                                            color: 'rgba(248,172,6,.3)'
                                        },
                                        {
                                            offset: 1,
                                            color: 'rgba(248,172,6, 0)'
                                        }
                                    ],
                                    false
                                ),
                                shadowColor: 'rgba(10,219,250, 0.5)', //阴影颜色
                                shadowBlur: 20 //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
                            }
                        },
                        data: seriesData2
                    }
                ]
            };
            this.myChart2.setOption(option);
        },
        //加载全国的图表
        loadCharts(response) {
            var chartDom = document.getElementById('passing_rate_bar');
            if (!chartDom) {
                return;
            }
            this.myChart = echarts.init(chartDom, null, { renderer: 'svg' });
            let xAxisData = [],
                seriesData = [];
            response.forEach((val, key) => {
                xAxisData.push(val.accItem);
                seriesData.push(val.accAmount);
            });
            this.tableData = response;
            const offsetX = 8;
            const offsetY = 4;
            // 绘制左侧面
            const CubeLeft = echarts.graphic.extendShape({
                shape: {
                    x: 0,
                    y: 0
                },
                buildPath: function (ctx, shape) {
                    // 会canvas的应该都能看得懂，shape是从custom传入的
                    const xAxisPoint = shape.xAxisPoint;
                    const c0 = [shape.x, shape.y];
                    const c1 = [shape.x - offsetX, shape.y - offsetY];
                    const c2 = [xAxisPoint[0] - offsetX, xAxisPoint[1] - offsetY];
                    const c3 = [xAxisPoint[0], xAxisPoint[1]];
                    ctx.moveTo(c0[0], c0[1]).lineTo(c1[0], c1[1]).lineTo(c2[0], c2[1]).lineTo(c3[0], c3[1]).closePath();
                }
            });
            // 绘制右侧面
            const CubeRight = echarts.graphic.extendShape({
                shape: {
                    x: 0,
                    y: 0
                },
                buildPath: function (ctx, shape) {
                    const xAxisPoint = shape.xAxisPoint;
                    const c1 = [shape.x, shape.y];
                    const c2 = [xAxisPoint[0], xAxisPoint[1]];
                    const c3 = [xAxisPoint[0] + offsetX, xAxisPoint[1] - offsetY];
                    const c4 = [shape.x + offsetX, shape.y - offsetY];
                    ctx.moveTo(c1[0], c1[1]).lineTo(c2[0], c2[1]).lineTo(c3[0], c3[1]).lineTo(c4[0], c4[1]).closePath();
                }
            });
            // 绘制顶面
            const CubeTop = echarts.graphic.extendShape({
                shape: {
                    x: 0,
                    y: 0
                },
                buildPath: function (ctx, shape) {
                    const c1 = [shape.x, shape.y];
                    const c2 = [shape.x + offsetX, shape.y - offsetY]; //右点
                    const c3 = [shape.x, shape.y - offsetX];
                    const c4 = [shape.x - offsetX, shape.y - offsetY];
                    ctx.moveTo(c1[0], c1[1]).lineTo(c2[0], c2[1]).lineTo(c3[0], c3[1]).lineTo(c4[0], c4[1]).closePath();
                }
            });
            // 注册三个面图形
            echarts.graphic.registerShape('CubeLeft', CubeLeft);
            echarts.graphic.registerShape('CubeRight', CubeRight);
            echarts.graphic.registerShape('CubeTop', CubeTop);
            let option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    },
                    formatter: '{b0} :  {c0}元'
                },
                grid: {
                    top: 20,
                    left: '10',
                    right: '10',
                    bottom: '10',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    data: xAxisData,
                    axisLabel: {
                        showMaxLabel: true,
                        rotate: 40,
                        // fontSize: '0.12rem',
                        color: '#fff', //坐标值得具体的颜色
                        width: 60,
                        overflow: 'truncate',
                        ellipsis: '...'
                    },
                    axisTick: {
                        alignWithLabel: true
                    }
                },
                yAxis: {
                    type: 'value',
                    splitLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        color: '#fff',
                        formatter: '{value} 元'
                        // fontSize: '0.12rem'
                    }
                    // boundaryGap: ['20%', '20%'],
                },
                series: [
                    {
                        type: 'custom',
                        renderItem: (params, api) => {
                            const location = api.coord([api.value(0), api.value(1)]);
                            return {
                                type: 'group',
                                children: [
                                    {
                                        type: 'CubeLeft',
                                        shape: {
                                            api,
                                            xValue: api.value(0),
                                            yValue: api.value(1),
                                            x: location[0],
                                            y: location[1],
                                            xAxisPoint: api.coord([api.value(0), 0])
                                        },
                                        style: {
                                            fill: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                                {
                                                    offset: 0,
                                                    color: '#C3AB79'
                                                },
                                                {
                                                    offset: 1,
                                                    color: '#B8892F'
                                                }
                                            ])
                                        }
                                    },
                                    {
                                        type: 'CubeRight',
                                        shape: {
                                            api,
                                            xValue: api.value(0),
                                            yValue: api.value(1),
                                            x: location[0],
                                            y: location[1],
                                            xAxisPoint: api.coord([api.value(0), 0])
                                        },
                                        style: {
                                            fill: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                                {
                                                    offset: 0,
                                                    color: '#C3AB79'
                                                },
                                                {
                                                    offset: 1,
                                                    color: '#B8892F'
                                                }
                                            ])
                                        }
                                    },
                                    {
                                        type: 'CubeTop',
                                        shape: {
                                            api,
                                            xValue: api.value(0),
                                            yValue: api.value(1),
                                            x: location[0],
                                            y: location[1],
                                            xAxisPoint: api.coord([api.value(0), 0])
                                        },
                                        style: {
                                            fill: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                                {
                                                    offset: 0,
                                                    color: '#C4B289'
                                                },
                                                {
                                                    offset: 1,
                                                    color: '#C4B289'
                                                }
                                            ])
                                        }
                                    }
                                ]
                            };
                        },
                        data: seriesData
                    },
                    {
                        type: 'bar',
                        itemStyle: {
                            color: 'transparent'
                        },
                        tooltip: {},
                        data: seriesData
                    }
                ]
            };

            this.myChart.setOption(option, true);
        }
    },
    updated() {
        this.myChart && this.myChart.resize();
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.module_wrap {
    width: 100%;
    min-height: 5.8rem;
    padding: 0.15rem 0.3rem 0;
    padding-bottom: 0.3rem;
    margin-top: 0.25rem;
    .tables_box {
        width: 100%;
        height: 100px;
        margin-top: -30px;
        padding: 0.3rem;
        padding-bottom: 0;
        // padding-top: 0.3rem;
        div {
            background: none;
        }
        /deep/ tr,
        /deep/.el-table__cell {
            background: none;
            color: #fff;
            // border-color: #006ec4;
        }
        /deep/.el-table__row::hover {
            background: none;
        }
        /deep/ tr:hover > td {
            background: none;
        }
        /deep/ td.el-table__cell {
            border: 0px;
        }
        /deep/ .el-table--border {
            border: none;
            border: 0px;
        }
    }
}
#passing_rate_bar,
#passing_rate_bar2 {
    width: 100%;
    height: 2.8rem;
    margin-top: 0.1rem;
}
</style>
